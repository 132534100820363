"use client";

import { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";

import { gradient } from "~/components/Gradient";
import ContactForm from "./contact/contact_form";

export default function Landing() {
  useEffect(() => {
    gradient.initGradient("#gradient-canvas");
  }, []);

  return (
    <AnimatePresence>
      <div className="font-inter relative flex min-h-[100vh] w-screen flex-col overflow-hidden bg-[#F2F3F5] sm:min-h-screen">
        <svg
          style={{ filter: "contrast(125%) brightness(110%)" }}
          className="fixed z-[1] h-full w-full opacity-[35%]"
        >
          <filter id="noise">
            <feTurbulence
              type="fractalNoise"
              baseFrequency=".7"
              numOctaves="3"
              stitchTiles="stitch"
            ></feTurbulence>
            <feColorMatrix type="saturate" values="0"></feColorMatrix>
          </filter>
          <rect width="100%" height="100%" filter="url(#noise)"></rect>
        </svg>
        <motion.img
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            delay: 0.15,
            duration: 0.95,
            ease: [0.165, 0.84, 0.44, 1],
          }}
          className="absolute z-[120] mx-4 w-[30vw] pt-[30px] md:mx-20 md:w-[200px]"
          alt="venudex logo"
          src="/icon-transparent.png"
        />
        <main className="static z-[100] m-auto flex h-[90%] w-screen grid-rows-[1fr_repeat(3,auto)_1fr] flex-col justify-center overflow-hidden px-4 py-[100px] md:px-20">
          <motion.h1
            initial={{ opacity: 0, y: 40 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              delay: 0.15,
              duration: 0.95,
              ease: [0.165, 0.84, 0.44, 1],
            }}
            className="font-inter relative text-[14vw] font-extrabold leading-[0.9] tracking-[-2px] text-[#1E2B3A] md:mb-[37px] md:ml-[-10px] md:text-[80px] lg:text-[100px]"
          >
            Competitor price
            <br />
            analysis on <span className="text-[#407BBF]">autopilot.</span>
          </motion.h1>
          <motion.div
            initial={{ opacity: 0, y: 40 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              delay: 0.15,
              duration: 0.95,
              ease: [0.165, 0.84, 0.44, 1],
            }}
            className="z-20 mx-0 mb-0 mt-8 flex max-w-3xl flex-row justify-center text-[4vw] md:mb-[35px] md:ml-[-10px] md:mt-0 md:space-x-8 md:text-[24px]"
          >
            <div className="space-y-4">
              <p className="font-normal text-[#1a2b3b]">
                Venudex enables businesses to effectively compare and analyze
                menu data, ensuring optimal profitability and customer
                satisfaction.
              </p>
              <div>
                <p className="font-normal text-[#1a2b3b]">
                  Looking for your first <b>free</b> analysis?
                </p>
                <p className="font-normal text-[#1a2b3b]">
                  <b>
                    Join the waitlist for
                    <span className="text-[#407BBF]"> Venudex.</span>
                  </b>
                </p>
              </div>
            </div>
          </motion.div>
          <div className="mt-8 flex gap-[15px] md:mt-0">
            <motion.div
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                delay: 0.55,
                duration: 0.55,
                ease: [0.075, 0.82, 0.965, 1],
              }}
            >
              <ContactForm />
            </motion.div>
          </div>
        </main>

        <div
          className="fixed right-0 top-0 h-screen w-[80%] bg-[#1F2B3A]/20 md:w-1/2"
          style={{
            clipPath:
              "polygon(100px 0,100% 0,calc(100% + 225px) 100%, 480px 100%)",
          }}
        ></div>

        <motion.canvas
          initial={{
            filter: "blur(20px)",
          }}
          animate={{
            filter: "blur(0px)",
          }}
          transition={{
            duration: 1,
            ease: [0.075, 0.82, 0.965, 1],
          }}
          style={{
            clipPath:
              "polygon(100px 0,100% 0,calc(100% + 225px) 100%, 480px 100%)",
          }}
          id="gradient-canvas"
          data-transition-in
          className="fixed right-[-2px] top-0 z-50 h-screen w-[80%] bg-[#c3e4ff] md:w-1/2"
        ></motion.canvas>
      </div>
    </AnimatePresence>
  );
}
