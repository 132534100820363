import React from "react";
import type { NextPage } from "next";
import Head from "next/head";
import Script from "next/script";

import Landing from "~/components/landing";
import Layout from "~/components/layout";

const Home: NextPage = () => {
  return (
    <Layout>
      <div className="h-[100vh] w-[100vw]">
        <Head>
          <title>venudex</title>
          <meta
            name="description"
            content="Uncover the ultimate happy hour experience near you with venudex! Browse through an array of bars and restaurants offering irresistible happy hour specials. From craft cocktails to delectable bites, our comprehensive database has it all. Discover hidden gems and popular hotspots, each with their unique ambiance and drink selections. Elevate your evenings with venudex and make the most of your happy hour adventures. Find your perfect spot and savor the joy of great deals and good times."
          />
        </Head>
        {process.env.NODE_ENV === "production" && (
          <>
            <Script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-GLH5CSLSX2"
              strategy="afterInteractive"
            />
            <Script
              id="gtag"
              strategy="afterInteractive"
            >{`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-GLH5CSLSX2');`}</Script>
          </>
        )}
        <Landing />
      </div>
    </Layout>
  );
};

export default Home;
