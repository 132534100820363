import Head from "next/head";
import Script from "next/script";

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Head>
        <meta name="theme-color" content="#8AB4F8" />
        <link rel="icon" href="/favicon.ico" />
        {process.env.NODE_ENV === "production" && (
          <>
            <Script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-GLH5CSLSX2"
              strategy="afterInteractive"
            />
            <Script
              id="gtag"
              strategy="afterInteractive"
            >{`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-GLH5CSLSX2');`}</Script>
          </>
        )}
      </Head>
      <main>{children}</main>
    </>
  );
}
