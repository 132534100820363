import React from "react";
import { useDebouncedCallback } from "use-debounce";

import { contactSchema } from "@venudex/db/prisma/generated/zod";

import { api, type RouterInputs } from "~/utils/api";

type NewContact = RouterInputs["contact"]["contactUs"];

const initialValues = {
  name: "",
  email: "",
  locationName: "",
} as const;

export default function ContactForm() {
  // TODO handle error
  const { mutate, isLoading } = api.contact.contactUs.useMutation();

  const [contactForm, setContactForm] = React.useState(initialValues);
  const [error, setError] = React.useState(false);

  const onChange = (key: keyof NewContact, value: string) => {
    setContactForm({ ...contactForm, [key]: value });
    setError(false);
  };

  const onSubmit = useDebouncedCallback(() => {
    contactSchema
      .pick({ name: true, email: true, locationName: true })
      .parse(contactForm);
    if (!contactForm.name || !contactForm.email) {
      setError(true);
      return;
    }
    mutate({ ...contactForm, message: null });
    setContactForm(initialValues);
  });

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
      className="grid grid-cols-6 grid-rows-2 gap-2"
    >
      <input
        type="text"
        placeholder="Full Name"
        className={`grid-item input-bordered input input-sm sm:input-md md:input-lg col-span-3 row-span-1 bg-blue-300 bg-opacity-20${
          error ? " input-error" : ""
        }`}
        value={contactForm.name}
        onChange={(event) => onChange("name", event.target.value)}
      />
      <input
        type="text"
        placeholder="Venue"
        className={`grid-item input-bordered input input-sm sm:input-md md:input-lg col-span-3 row-span-1 bg-blue-300 bg-opacity-20${
          error ? " input-error" : ""
        }`}
        value={contactForm.locationName}
        onChange={(event) => onChange("locationName", event.target.value)}
      />
      <input
        type="text"
        placeholder="Email"
        className={`grid-item input-bordered input input-sm sm:input-md md:input-lg col-span-4 row-span-1 bg-blue-300 bg-opacity-20${
          error ? " input-error" : ""
        }`}
        value={contactForm.email}
        onChange={(event) => onChange("email", event.target.value)}
      />
      <button
        className="grid-item btn btn-sm sm:btn-md md:btn-lg btn-neutral col-span-2 row-span-1"
        disabled={isLoading}
      >
        Submit
      </button>
    </form>
  );
}
