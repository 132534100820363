import { z } from 'zod';
import type { Prisma } from '@prisma/client';

/////////////////////////////////////////
// HELPER FUNCTIONS
/////////////////////////////////////////


/////////////////////////////////////////
// ENUMS
/////////////////////////////////////////

export const TransactionIsolationLevelSchema = z.enum(['ReadUncommitted','ReadCommitted','RepeatableRead','Serializable']);

export const LocationsScalarFieldEnumSchema = z.enum(['id','timestamp','meandu_id','meandu_id_1','meandu_id_2','name','address','latitude','longitude','date_created','google_name','google_place_id','currency','locale','meandu_menu_url','last_updated','google_operational','validityScore','hidden']);

export const Drink_categoriesScalarFieldEnumSchema = z.enum(['id','timestamp','name','meandu_id','locationsId']);

export const DrinksScalarFieldEnumSchema = z.enum(['id','timestamp','name','locationsId','meandu_id']);

export const Drinks_to_drink_categoriesScalarFieldEnumSchema = z.enum(['drink_id','drink_category_id']);

export const PricesScalarFieldEnumSchema = z.enum(['id','timestamp','menusId','locationsId','price','drink_id','drink_variant_name']);

export const MenusScalarFieldEnumSchema = z.enum(['id','timestamp','name','isDiscounted','locationsId','daysOfWeek','timeStart','timeEnd','meandu_id']);

export const AccountScalarFieldEnumSchema = z.enum(['id','userId','type','provider','providerAccountId','refresh_token','access_token','expires_at','token_type','scope','id_token','session_state']);

export const SessionScalarFieldEnumSchema = z.enum(['id','sessionToken','userId','expires']);

export const UserScalarFieldEnumSchema = z.enum(['id','name','email','emailVerified','image','apiKey']);

export const VerificationTokenScalarFieldEnumSchema = z.enum(['identifier','token','expires']);

export const ContactScalarFieldEnumSchema = z.enum(['id','createdAt','name','email','message','locationsId','x','linkedin','instagram','facebook','website','phone']);

export const SortOrderSchema = z.enum(['asc','desc']);

export const NullsOrderSchema = z.enum(['first','last']);

export const locationsOrderByRelevanceFieldEnumSchema = z.enum(['id','meandu_id','meandu_id_1','meandu_id_2','name','address','google_name','google_place_id','currency','locale','meandu_menu_url','google_operational']);

export const drink_categoriesOrderByRelevanceFieldEnumSchema = z.enum(['id','name','meandu_id','locationsId']);

export const drinksOrderByRelevanceFieldEnumSchema = z.enum(['id','name','locationsId','meandu_id']);

export const drinks_to_drink_categoriesOrderByRelevanceFieldEnumSchema = z.enum(['drink_id','drink_category_id']);

export const pricesOrderByRelevanceFieldEnumSchema = z.enum(['id','menusId','locationsId','drink_id','drink_variant_name']);

export const menusOrderByRelevanceFieldEnumSchema = z.enum(['id','name','locationsId','meandu_id']);

export const AccountOrderByRelevanceFieldEnumSchema = z.enum(['id','userId','type','provider','providerAccountId','refresh_token','access_token','token_type','scope','id_token','session_state']);

export const SessionOrderByRelevanceFieldEnumSchema = z.enum(['id','sessionToken','userId']);

export const UserOrderByRelevanceFieldEnumSchema = z.enum(['id','name','email','image','apiKey']);

export const VerificationTokenOrderByRelevanceFieldEnumSchema = z.enum(['identifier','token']);

export const contactOrderByRelevanceFieldEnumSchema = z.enum(['id','name','email','message','locationsId','x','linkedin','instagram','facebook','website','phone']);
/////////////////////////////////////////
// MODELS
/////////////////////////////////////////

/////////////////////////////////////////
// LOCATIONS SCHEMA
/////////////////////////////////////////

export const locationsSchema = z.object({
  id: z.string().uuid(),
  timestamp: z.coerce.date(),
  meandu_id: z.string().nullable(),
  meandu_id_1: z.string().nullable(),
  meandu_id_2: z.string().nullable(),
  name: z.string().nullable(),
  address: z.string().nullable(),
  latitude: z.number().nullable(),
  longitude: z.number().nullable(),
  date_created: z.coerce.date().nullable(),
  google_name: z.string().nullable(),
  google_place_id: z.string().nullable(),
  currency: z.string().nullable(),
  locale: z.string().nullable(),
  meandu_menu_url: z.string().nullable(),
  last_updated: z.coerce.date().nullable(),
  google_operational: z.string().nullable(),
  validityScore: z.number().int().nullable(),
  hidden: z.boolean().nullable(),
})

export type locations = z.infer<typeof locationsSchema>

/////////////////////////////////////////
// DRINK CATEGORIES SCHEMA
/////////////////////////////////////////

export const drink_categoriesSchema = z.object({
  id: z.string().uuid(),
  timestamp: z.coerce.date(),
  name: z.string(),
  meandu_id: z.string().nullable(),
  locationsId: z.string(),
})

export type drink_categories = z.infer<typeof drink_categoriesSchema>

/////////////////////////////////////////
// DRINKS SCHEMA
/////////////////////////////////////////

export const drinksSchema = z.object({
  id: z.string().uuid(),
  timestamp: z.coerce.date(),
  name: z.string(),
  locationsId: z.string(),
  meandu_id: z.string().nullable(),
})

export type drinks = z.infer<typeof drinksSchema>

/////////////////////////////////////////
// DRINKS TO DRINK CATEGORIES SCHEMA
/////////////////////////////////////////

export const drinks_to_drink_categoriesSchema = z.object({
  drink_id: z.string(),
  drink_category_id: z.string(),
})

export type drinks_to_drink_categories = z.infer<typeof drinks_to_drink_categoriesSchema>

/////////////////////////////////////////
// PRICES SCHEMA
/////////////////////////////////////////

export const pricesSchema = z.object({
  id: z.string().uuid(),
  timestamp: z.coerce.date(),
  menusId: z.string().nullable(),
  locationsId: z.string(),
  price: z.number().nullable(),
  drink_id: z.string(),
  drink_variant_name: z.string().nullable(),
})

export type prices = z.infer<typeof pricesSchema>

/////////////////////////////////////////
// MENUS SCHEMA
/////////////////////////////////////////

export const menusSchema = z.object({
  id: z.string().uuid(),
  timestamp: z.coerce.date(),
  name: z.string(),
  isDiscounted: z.boolean().nullable(),
  locationsId: z.string(),
  daysOfWeek: z.number().int().nullable(),
  timeStart: z.number().int().nullable(),
  timeEnd: z.number().int().nullable(),
  meandu_id: z.string().nullable(),
})

export type menus = z.infer<typeof menusSchema>

/////////////////////////////////////////
// ACCOUNT SCHEMA
/////////////////////////////////////////

export const AccountSchema = z.object({
  id: z.string().cuid(),
  userId: z.string(),
  type: z.string(),
  provider: z.string(),
  providerAccountId: z.string(),
  refresh_token: z.string().nullable(),
  access_token: z.string().nullable(),
  expires_at: z.number().int().nullable(),
  token_type: z.string().nullable(),
  scope: z.string().nullable(),
  id_token: z.string().nullable(),
  session_state: z.string().nullable(),
})

export type Account = z.infer<typeof AccountSchema>

/////////////////////////////////////////
// SESSION SCHEMA
/////////////////////////////////////////

export const SessionSchema = z.object({
  id: z.string().cuid(),
  sessionToken: z.string(),
  userId: z.string(),
  expires: z.coerce.date(),
})

export type Session = z.infer<typeof SessionSchema>

/////////////////////////////////////////
// USER SCHEMA
/////////////////////////////////////////

export const UserSchema = z.object({
  id: z.string().cuid(),
  name: z.string().nullable(),
  email: z.string().nullable(),
  emailVerified: z.coerce.date().nullable(),
  image: z.string().nullable(),
  apiKey: z.string().uuid().nullable(),
})

export type User = z.infer<typeof UserSchema>

/////////////////////////////////////////
// VERIFICATION TOKEN SCHEMA
/////////////////////////////////////////

export const VerificationTokenSchema = z.object({
  identifier: z.string(),
  token: z.string(),
  expires: z.coerce.date(),
})

export type VerificationToken = z.infer<typeof VerificationTokenSchema>

/////////////////////////////////////////
// CONTACT SCHEMA
/////////////////////////////////////////

export const contactSchema = z.object({
  id: z.string().uuid(),
  createdAt: z.coerce.date(),
  name: z.string(),
  email: z.string().nullable(),
  message: z.string().nullable(),
  locationsId: z.string().nullable(),
  x: z.string().nullable(),
  linkedin: z.string().nullable(),
  instagram: z.string().nullable(),
  facebook: z.string().nullable(),
  website: z.string().nullable(),
  phone: z.string().nullable(),
})

export type contact = z.infer<typeof contactSchema>

/////////////////////////////////////////
// SELECT & INCLUDE
/////////////////////////////////////////

// LOCATIONS
//------------------------------------------------------

export const locationsIncludeSchema: z.ZodType<Prisma.locationsInclude> = z.object({
  menus: z.union([z.boolean(),z.lazy(() => menusFindManyArgsSchema)]).optional(),
  prices: z.union([z.boolean(),z.lazy(() => pricesFindManyArgsSchema)]).optional(),
  drinks: z.union([z.boolean(),z.lazy(() => drinksFindManyArgsSchema)]).optional(),
  drink_categories: z.union([z.boolean(),z.lazy(() => drink_categoriesFindManyArgsSchema)]).optional(),
  contact: z.union([z.boolean(),z.lazy(() => contactFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => LocationsCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const locationsArgsSchema: z.ZodType<Prisma.locationsArgs> = z.object({
  select: z.lazy(() => locationsSelectSchema).optional(),
  include: z.lazy(() => locationsIncludeSchema).optional(),
}).strict();

export const locationsCountOutputTypeArgsSchema: z.ZodType<Prisma.locationsCountOutputTypeArgs> = z.object({
  select: z.lazy(() => locationsCountOutputTypeSelectSchema).nullish(),
}).strict();

export const locationsCountOutputTypeSelectSchema: z.ZodType<Prisma.locationsCountOutputTypeSelect> = z.object({
  menus: z.boolean().optional(),
  prices: z.boolean().optional(),
  drinks: z.boolean().optional(),
  drink_categories: z.boolean().optional(),
  contact: z.boolean().optional(),
}).strict();

export const locationsSelectSchema: z.ZodType<Prisma.locationsSelect> = z.object({
  id: z.boolean().optional(),
  timestamp: z.boolean().optional(),
  meandu_id: z.boolean().optional(),
  meandu_id_1: z.boolean().optional(),
  meandu_id_2: z.boolean().optional(),
  name: z.boolean().optional(),
  address: z.boolean().optional(),
  latitude: z.boolean().optional(),
  longitude: z.boolean().optional(),
  date_created: z.boolean().optional(),
  google_name: z.boolean().optional(),
  google_place_id: z.boolean().optional(),
  currency: z.boolean().optional(),
  locale: z.boolean().optional(),
  meandu_menu_url: z.boolean().optional(),
  last_updated: z.boolean().optional(),
  google_operational: z.boolean().optional(),
  validityScore: z.boolean().optional(),
  hidden: z.boolean().optional(),
  menus: z.union([z.boolean(),z.lazy(() => menusFindManyArgsSchema)]).optional(),
  prices: z.union([z.boolean(),z.lazy(() => pricesFindManyArgsSchema)]).optional(),
  drinks: z.union([z.boolean(),z.lazy(() => drinksFindManyArgsSchema)]).optional(),
  drink_categories: z.union([z.boolean(),z.lazy(() => drink_categoriesFindManyArgsSchema)]).optional(),
  contact: z.union([z.boolean(),z.lazy(() => contactFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => LocationsCountOutputTypeArgsSchema)]).optional(),
}).strict()

// DRINK CATEGORIES
//------------------------------------------------------

export const drink_categoriesIncludeSchema: z.ZodType<Prisma.drink_categoriesInclude> = z.object({
  locations: z.union([z.boolean(),z.lazy(() => locationsArgsSchema)]).optional(),
  drinks: z.union([z.boolean(),z.lazy(() => drinks_to_drink_categoriesFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => Drink_categoriesCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const drink_categoriesArgsSchema: z.ZodType<Prisma.drink_categoriesArgs> = z.object({
  select: z.lazy(() => drink_categoriesSelectSchema).optional(),
  include: z.lazy(() => drink_categoriesIncludeSchema).optional(),
}).strict();

export const drink_categoriesCountOutputTypeArgsSchema: z.ZodType<Prisma.drink_categoriesCountOutputTypeArgs> = z.object({
  select: z.lazy(() => drink_categoriesCountOutputTypeSelectSchema).nullish(),
}).strict();

export const drink_categoriesCountOutputTypeSelectSchema: z.ZodType<Prisma.drink_categoriesCountOutputTypeSelect> = z.object({
  drinks: z.boolean().optional(),
}).strict();

export const drink_categoriesSelectSchema: z.ZodType<Prisma.drink_categoriesSelect> = z.object({
  id: z.boolean().optional(),
  timestamp: z.boolean().optional(),
  name: z.boolean().optional(),
  meandu_id: z.boolean().optional(),
  locationsId: z.boolean().optional(),
  locations: z.union([z.boolean(),z.lazy(() => locationsArgsSchema)]).optional(),
  drinks: z.union([z.boolean(),z.lazy(() => drinks_to_drink_categoriesFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => Drink_categoriesCountOutputTypeArgsSchema)]).optional(),
}).strict()

// DRINKS
//------------------------------------------------------

export const drinksIncludeSchema: z.ZodType<Prisma.drinksInclude> = z.object({
  prices: z.union([z.boolean(),z.lazy(() => pricesFindManyArgsSchema)]).optional(),
  locations: z.union([z.boolean(),z.lazy(() => locationsArgsSchema)]).optional(),
  drink_categories: z.union([z.boolean(),z.lazy(() => drinks_to_drink_categoriesFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => DrinksCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const drinksArgsSchema: z.ZodType<Prisma.drinksArgs> = z.object({
  select: z.lazy(() => drinksSelectSchema).optional(),
  include: z.lazy(() => drinksIncludeSchema).optional(),
}).strict();

export const drinksCountOutputTypeArgsSchema: z.ZodType<Prisma.drinksCountOutputTypeArgs> = z.object({
  select: z.lazy(() => drinksCountOutputTypeSelectSchema).nullish(),
}).strict();

export const drinksCountOutputTypeSelectSchema: z.ZodType<Prisma.drinksCountOutputTypeSelect> = z.object({
  prices: z.boolean().optional(),
  drink_categories: z.boolean().optional(),
}).strict();

export const drinksSelectSchema: z.ZodType<Prisma.drinksSelect> = z.object({
  id: z.boolean().optional(),
  timestamp: z.boolean().optional(),
  name: z.boolean().optional(),
  locationsId: z.boolean().optional(),
  meandu_id: z.boolean().optional(),
  prices: z.union([z.boolean(),z.lazy(() => pricesFindManyArgsSchema)]).optional(),
  locations: z.union([z.boolean(),z.lazy(() => locationsArgsSchema)]).optional(),
  drink_categories: z.union([z.boolean(),z.lazy(() => drinks_to_drink_categoriesFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => DrinksCountOutputTypeArgsSchema)]).optional(),
}).strict()

// DRINKS TO DRINK CATEGORIES
//------------------------------------------------------

export const drinks_to_drink_categoriesIncludeSchema: z.ZodType<Prisma.drinks_to_drink_categoriesInclude> = z.object({
  drinks: z.union([z.boolean(),z.lazy(() => drinksArgsSchema)]).optional(),
  drink_categories: z.union([z.boolean(),z.lazy(() => drink_categoriesArgsSchema)]).optional(),
}).strict()

export const drinks_to_drink_categoriesArgsSchema: z.ZodType<Prisma.drinks_to_drink_categoriesArgs> = z.object({
  select: z.lazy(() => drinks_to_drink_categoriesSelectSchema).optional(),
  include: z.lazy(() => drinks_to_drink_categoriesIncludeSchema).optional(),
}).strict();

export const drinks_to_drink_categoriesSelectSchema: z.ZodType<Prisma.drinks_to_drink_categoriesSelect> = z.object({
  drink_id: z.boolean().optional(),
  drink_category_id: z.boolean().optional(),
  drinks: z.union([z.boolean(),z.lazy(() => drinksArgsSchema)]).optional(),
  drink_categories: z.union([z.boolean(),z.lazy(() => drink_categoriesArgsSchema)]).optional(),
}).strict()

// PRICES
//------------------------------------------------------

export const pricesIncludeSchema: z.ZodType<Prisma.pricesInclude> = z.object({
  menus: z.union([z.boolean(),z.lazy(() => menusArgsSchema)]).optional(),
  locations: z.union([z.boolean(),z.lazy(() => locationsArgsSchema)]).optional(),
  drinks: z.union([z.boolean(),z.lazy(() => drinksArgsSchema)]).optional(),
}).strict()

export const pricesArgsSchema: z.ZodType<Prisma.pricesArgs> = z.object({
  select: z.lazy(() => pricesSelectSchema).optional(),
  include: z.lazy(() => pricesIncludeSchema).optional(),
}).strict();

export const pricesSelectSchema: z.ZodType<Prisma.pricesSelect> = z.object({
  id: z.boolean().optional(),
  timestamp: z.boolean().optional(),
  menusId: z.boolean().optional(),
  locationsId: z.boolean().optional(),
  price: z.boolean().optional(),
  drink_id: z.boolean().optional(),
  drink_variant_name: z.boolean().optional(),
  menus: z.union([z.boolean(),z.lazy(() => menusArgsSchema)]).optional(),
  locations: z.union([z.boolean(),z.lazy(() => locationsArgsSchema)]).optional(),
  drinks: z.union([z.boolean(),z.lazy(() => drinksArgsSchema)]).optional(),
}).strict()

// MENUS
//------------------------------------------------------

export const menusIncludeSchema: z.ZodType<Prisma.menusInclude> = z.object({
  locations: z.union([z.boolean(),z.lazy(() => locationsArgsSchema)]).optional(),
  prices: z.union([z.boolean(),z.lazy(() => pricesFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => MenusCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const menusArgsSchema: z.ZodType<Prisma.menusArgs> = z.object({
  select: z.lazy(() => menusSelectSchema).optional(),
  include: z.lazy(() => menusIncludeSchema).optional(),
}).strict();

export const menusCountOutputTypeArgsSchema: z.ZodType<Prisma.menusCountOutputTypeArgs> = z.object({
  select: z.lazy(() => menusCountOutputTypeSelectSchema).nullish(),
}).strict();

export const menusCountOutputTypeSelectSchema: z.ZodType<Prisma.menusCountOutputTypeSelect> = z.object({
  prices: z.boolean().optional(),
}).strict();

export const menusSelectSchema: z.ZodType<Prisma.menusSelect> = z.object({
  id: z.boolean().optional(),
  timestamp: z.boolean().optional(),
  name: z.boolean().optional(),
  isDiscounted: z.boolean().optional(),
  locationsId: z.boolean().optional(),
  daysOfWeek: z.boolean().optional(),
  timeStart: z.boolean().optional(),
  timeEnd: z.boolean().optional(),
  meandu_id: z.boolean().optional(),
  locations: z.union([z.boolean(),z.lazy(() => locationsArgsSchema)]).optional(),
  prices: z.union([z.boolean(),z.lazy(() => pricesFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => MenusCountOutputTypeArgsSchema)]).optional(),
}).strict()

// ACCOUNT
//------------------------------------------------------

export const AccountIncludeSchema: z.ZodType<Prisma.AccountInclude> = z.object({
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
}).strict()

export const AccountArgsSchema: z.ZodType<Prisma.AccountArgs> = z.object({
  select: z.lazy(() => AccountSelectSchema).optional(),
  include: z.lazy(() => AccountIncludeSchema).optional(),
}).strict();

export const AccountSelectSchema: z.ZodType<Prisma.AccountSelect> = z.object({
  id: z.boolean().optional(),
  userId: z.boolean().optional(),
  type: z.boolean().optional(),
  provider: z.boolean().optional(),
  providerAccountId: z.boolean().optional(),
  refresh_token: z.boolean().optional(),
  access_token: z.boolean().optional(),
  expires_at: z.boolean().optional(),
  token_type: z.boolean().optional(),
  scope: z.boolean().optional(),
  id_token: z.boolean().optional(),
  session_state: z.boolean().optional(),
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
}).strict()

// SESSION
//------------------------------------------------------

export const SessionIncludeSchema: z.ZodType<Prisma.SessionInclude> = z.object({
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
}).strict()

export const SessionArgsSchema: z.ZodType<Prisma.SessionArgs> = z.object({
  select: z.lazy(() => SessionSelectSchema).optional(),
  include: z.lazy(() => SessionIncludeSchema).optional(),
}).strict();

export const SessionSelectSchema: z.ZodType<Prisma.SessionSelect> = z.object({
  id: z.boolean().optional(),
  sessionToken: z.boolean().optional(),
  userId: z.boolean().optional(),
  expires: z.boolean().optional(),
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
}).strict()

// USER
//------------------------------------------------------

export const UserIncludeSchema: z.ZodType<Prisma.UserInclude> = z.object({
  accounts: z.union([z.boolean(),z.lazy(() => AccountFindManyArgsSchema)]).optional(),
  sessions: z.union([z.boolean(),z.lazy(() => SessionFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => UserCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const UserArgsSchema: z.ZodType<Prisma.UserArgs> = z.object({
  select: z.lazy(() => UserSelectSchema).optional(),
  include: z.lazy(() => UserIncludeSchema).optional(),
}).strict();

export const UserCountOutputTypeArgsSchema: z.ZodType<Prisma.UserCountOutputTypeArgs> = z.object({
  select: z.lazy(() => UserCountOutputTypeSelectSchema).nullish(),
}).strict();

export const UserCountOutputTypeSelectSchema: z.ZodType<Prisma.UserCountOutputTypeSelect> = z.object({
  accounts: z.boolean().optional(),
  sessions: z.boolean().optional(),
}).strict();

export const UserSelectSchema: z.ZodType<Prisma.UserSelect> = z.object({
  id: z.boolean().optional(),
  name: z.boolean().optional(),
  email: z.boolean().optional(),
  emailVerified: z.boolean().optional(),
  image: z.boolean().optional(),
  apiKey: z.boolean().optional(),
  accounts: z.union([z.boolean(),z.lazy(() => AccountFindManyArgsSchema)]).optional(),
  sessions: z.union([z.boolean(),z.lazy(() => SessionFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => UserCountOutputTypeArgsSchema)]).optional(),
}).strict()

// VERIFICATION TOKEN
//------------------------------------------------------

export const VerificationTokenSelectSchema: z.ZodType<Prisma.VerificationTokenSelect> = z.object({
  identifier: z.boolean().optional(),
  token: z.boolean().optional(),
  expires: z.boolean().optional(),
}).strict()

// CONTACT
//------------------------------------------------------

export const contactIncludeSchema: z.ZodType<Prisma.contactInclude> = z.object({
  locations: z.union([z.boolean(),z.lazy(() => locationsArgsSchema)]).optional(),
}).strict()

export const contactArgsSchema: z.ZodType<Prisma.contactArgs> = z.object({
  select: z.lazy(() => contactSelectSchema).optional(),
  include: z.lazy(() => contactIncludeSchema).optional(),
}).strict();

export const contactSelectSchema: z.ZodType<Prisma.contactSelect> = z.object({
  id: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  name: z.boolean().optional(),
  email: z.boolean().optional(),
  message: z.boolean().optional(),
  locationsId: z.boolean().optional(),
  x: z.boolean().optional(),
  linkedin: z.boolean().optional(),
  instagram: z.boolean().optional(),
  facebook: z.boolean().optional(),
  website: z.boolean().optional(),
  phone: z.boolean().optional(),
  locations: z.union([z.boolean(),z.lazy(() => locationsArgsSchema)]).optional(),
}).strict()


/////////////////////////////////////////
// INPUT TYPES
/////////////////////////////////////////

export const locationsWhereInputSchema: z.ZodType<Prisma.locationsWhereInput> = z.object({
  AND: z.union([ z.lazy(() => locationsWhereInputSchema),z.lazy(() => locationsWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => locationsWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => locationsWhereInputSchema),z.lazy(() => locationsWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  timestamp: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  meandu_id: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  meandu_id_1: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  meandu_id_2: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  name: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  address: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  latitude: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  longitude: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  date_created: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  google_name: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  google_place_id: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  currency: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  locale: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  meandu_menu_url: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  last_updated: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  google_operational: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  validityScore: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  hidden: z.union([ z.lazy(() => BoolNullableFilterSchema),z.boolean() ]).optional().nullable(),
  menus: z.lazy(() => MenusListRelationFilterSchema).optional(),
  prices: z.lazy(() => PricesListRelationFilterSchema).optional(),
  drinks: z.lazy(() => DrinksListRelationFilterSchema).optional(),
  drink_categories: z.lazy(() => Drink_categoriesListRelationFilterSchema).optional(),
  contact: z.lazy(() => ContactListRelationFilterSchema).optional()
}).strict();

export const locationsOrderByWithRelationAndSearchRelevanceInputSchema: z.ZodType<Prisma.locationsOrderByWithRelationAndSearchRelevanceInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  meandu_id: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  meandu_id_1: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  meandu_id_2: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  name: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  address: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  latitude: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  longitude: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  date_created: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  google_name: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  google_place_id: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  currency: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  locale: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  meandu_menu_url: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  last_updated: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  google_operational: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  validityScore: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  hidden: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  menus: z.lazy(() => menusOrderByRelationAggregateInputSchema).optional(),
  prices: z.lazy(() => pricesOrderByRelationAggregateInputSchema).optional(),
  drinks: z.lazy(() => drinksOrderByRelationAggregateInputSchema).optional(),
  drink_categories: z.lazy(() => drink_categoriesOrderByRelationAggregateInputSchema).optional(),
  contact: z.lazy(() => contactOrderByRelationAggregateInputSchema).optional(),
  _relevance: z.lazy(() => locationsOrderByRelevanceInputSchema).optional()
}).strict();

export const locationsWhereUniqueInputSchema: z.ZodType<Prisma.locationsWhereUniqueInput> = z.object({
  id: z.string().uuid().optional()
}).strict();

export const locationsOrderByWithAggregationInputSchema: z.ZodType<Prisma.locationsOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  meandu_id: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  meandu_id_1: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  meandu_id_2: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  name: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  address: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  latitude: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  longitude: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  date_created: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  google_name: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  google_place_id: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  currency: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  locale: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  meandu_menu_url: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  last_updated: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  google_operational: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  validityScore: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  hidden: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => locationsCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => locationsAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => locationsMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => locationsMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => locationsSumOrderByAggregateInputSchema).optional()
}).strict();

export const locationsScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.locationsScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => locationsScalarWhereWithAggregatesInputSchema),z.lazy(() => locationsScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => locationsScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => locationsScalarWhereWithAggregatesInputSchema),z.lazy(() => locationsScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  timestamp: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  meandu_id: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  meandu_id_1: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  meandu_id_2: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  name: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  address: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  latitude: z.union([ z.lazy(() => FloatNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  longitude: z.union([ z.lazy(() => FloatNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  date_created: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  google_name: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  google_place_id: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  currency: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  locale: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  meandu_menu_url: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  last_updated: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  google_operational: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  validityScore: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  hidden: z.union([ z.lazy(() => BoolNullableWithAggregatesFilterSchema),z.boolean() ]).optional().nullable(),
}).strict();

export const drink_categoriesWhereInputSchema: z.ZodType<Prisma.drink_categoriesWhereInput> = z.object({
  AND: z.union([ z.lazy(() => drink_categoriesWhereInputSchema),z.lazy(() => drink_categoriesWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => drink_categoriesWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => drink_categoriesWhereInputSchema),z.lazy(() => drink_categoriesWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  timestamp: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  meandu_id: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  locationsId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  locations: z.union([ z.lazy(() => LocationsRelationFilterSchema),z.lazy(() => locationsWhereInputSchema) ]).optional(),
  drinks: z.lazy(() => Drinks_to_drink_categoriesListRelationFilterSchema).optional()
}).strict();

export const drink_categoriesOrderByWithRelationAndSearchRelevanceInputSchema: z.ZodType<Prisma.drink_categoriesOrderByWithRelationAndSearchRelevanceInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  meandu_id: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  locationsId: z.lazy(() => SortOrderSchema).optional(),
  locations: z.lazy(() => locationsOrderByWithRelationAndSearchRelevanceInputSchema).optional(),
  drinks: z.lazy(() => drinks_to_drink_categoriesOrderByRelationAggregateInputSchema).optional(),
  _relevance: z.lazy(() => drink_categoriesOrderByRelevanceInputSchema).optional()
}).strict();

export const drink_categoriesWhereUniqueInputSchema: z.ZodType<Prisma.drink_categoriesWhereUniqueInput> = z.object({
  id: z.string().uuid().optional(),
  locationsId_meandu_id: z.lazy(() => drink_categoriesLocationsIdMeandu_idCompoundUniqueInputSchema).optional()
}).strict();

export const drink_categoriesOrderByWithAggregationInputSchema: z.ZodType<Prisma.drink_categoriesOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  meandu_id: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  locationsId: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => drink_categoriesCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => drink_categoriesMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => drink_categoriesMinOrderByAggregateInputSchema).optional()
}).strict();

export const drink_categoriesScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.drink_categoriesScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => drink_categoriesScalarWhereWithAggregatesInputSchema),z.lazy(() => drink_categoriesScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => drink_categoriesScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => drink_categoriesScalarWhereWithAggregatesInputSchema),z.lazy(() => drink_categoriesScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  timestamp: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  name: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  meandu_id: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  locationsId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
}).strict();

export const drinksWhereInputSchema: z.ZodType<Prisma.drinksWhereInput> = z.object({
  AND: z.union([ z.lazy(() => drinksWhereInputSchema),z.lazy(() => drinksWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => drinksWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => drinksWhereInputSchema),z.lazy(() => drinksWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  timestamp: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  locationsId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  meandu_id: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  prices: z.lazy(() => PricesListRelationFilterSchema).optional(),
  locations: z.union([ z.lazy(() => LocationsRelationFilterSchema),z.lazy(() => locationsWhereInputSchema) ]).optional(),
  drink_categories: z.lazy(() => Drinks_to_drink_categoriesListRelationFilterSchema).optional()
}).strict();

export const drinksOrderByWithRelationAndSearchRelevanceInputSchema: z.ZodType<Prisma.drinksOrderByWithRelationAndSearchRelevanceInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  locationsId: z.lazy(() => SortOrderSchema).optional(),
  meandu_id: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  prices: z.lazy(() => pricesOrderByRelationAggregateInputSchema).optional(),
  locations: z.lazy(() => locationsOrderByWithRelationAndSearchRelevanceInputSchema).optional(),
  drink_categories: z.lazy(() => drinks_to_drink_categoriesOrderByRelationAggregateInputSchema).optional(),
  _relevance: z.lazy(() => drinksOrderByRelevanceInputSchema).optional()
}).strict();

export const drinksWhereUniqueInputSchema: z.ZodType<Prisma.drinksWhereUniqueInput> = z.object({
  id: z.string().uuid().optional(),
  locationsId_meandu_id: z.lazy(() => drinksLocationsIdMeandu_idCompoundUniqueInputSchema).optional()
}).strict();

export const drinksOrderByWithAggregationInputSchema: z.ZodType<Prisma.drinksOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  locationsId: z.lazy(() => SortOrderSchema).optional(),
  meandu_id: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => drinksCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => drinksMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => drinksMinOrderByAggregateInputSchema).optional()
}).strict();

export const drinksScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.drinksScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => drinksScalarWhereWithAggregatesInputSchema),z.lazy(() => drinksScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => drinksScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => drinksScalarWhereWithAggregatesInputSchema),z.lazy(() => drinksScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  timestamp: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  name: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  locationsId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  meandu_id: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const drinks_to_drink_categoriesWhereInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesWhereInput> = z.object({
  AND: z.union([ z.lazy(() => drinks_to_drink_categoriesWhereInputSchema),z.lazy(() => drinks_to_drink_categoriesWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => drinks_to_drink_categoriesWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => drinks_to_drink_categoriesWhereInputSchema),z.lazy(() => drinks_to_drink_categoriesWhereInputSchema).array() ]).optional(),
  drink_id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  drink_category_id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  drinks: z.union([ z.lazy(() => DrinksRelationFilterSchema),z.lazy(() => drinksWhereInputSchema) ]).optional(),
  drink_categories: z.union([ z.lazy(() => Drink_categoriesRelationFilterSchema),z.lazy(() => drink_categoriesWhereInputSchema) ]).optional(),
}).strict();

export const drinks_to_drink_categoriesOrderByWithRelationAndSearchRelevanceInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesOrderByWithRelationAndSearchRelevanceInput> = z.object({
  drink_id: z.lazy(() => SortOrderSchema).optional(),
  drink_category_id: z.lazy(() => SortOrderSchema).optional(),
  drinks: z.lazy(() => drinksOrderByWithRelationAndSearchRelevanceInputSchema).optional(),
  drink_categories: z.lazy(() => drink_categoriesOrderByWithRelationAndSearchRelevanceInputSchema).optional(),
  _relevance: z.lazy(() => drinks_to_drink_categoriesOrderByRelevanceInputSchema).optional()
}).strict();

export const drinks_to_drink_categoriesWhereUniqueInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesWhereUniqueInput> = z.object({
  drink_id_drink_category_id: z.lazy(() => drinks_to_drink_categoriesDrink_idDrink_category_idCompoundUniqueInputSchema).optional()
}).strict();

export const drinks_to_drink_categoriesOrderByWithAggregationInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesOrderByWithAggregationInput> = z.object({
  drink_id: z.lazy(() => SortOrderSchema).optional(),
  drink_category_id: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => drinks_to_drink_categoriesCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => drinks_to_drink_categoriesMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => drinks_to_drink_categoriesMinOrderByAggregateInputSchema).optional()
}).strict();

export const drinks_to_drink_categoriesScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => drinks_to_drink_categoriesScalarWhereWithAggregatesInputSchema),z.lazy(() => drinks_to_drink_categoriesScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => drinks_to_drink_categoriesScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => drinks_to_drink_categoriesScalarWhereWithAggregatesInputSchema),z.lazy(() => drinks_to_drink_categoriesScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  drink_id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  drink_category_id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
}).strict();

export const pricesWhereInputSchema: z.ZodType<Prisma.pricesWhereInput> = z.object({
  AND: z.union([ z.lazy(() => pricesWhereInputSchema),z.lazy(() => pricesWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => pricesWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => pricesWhereInputSchema),z.lazy(() => pricesWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  timestamp: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  menusId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  locationsId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  price: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  drink_id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  drink_variant_name: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  menus: z.union([ z.lazy(() => MenusRelationFilterSchema),z.lazy(() => menusWhereInputSchema) ]).optional().nullable(),
  locations: z.union([ z.lazy(() => LocationsRelationFilterSchema),z.lazy(() => locationsWhereInputSchema) ]).optional(),
  drinks: z.union([ z.lazy(() => DrinksRelationFilterSchema),z.lazy(() => drinksWhereInputSchema) ]).optional(),
}).strict();

export const pricesOrderByWithRelationAndSearchRelevanceInputSchema: z.ZodType<Prisma.pricesOrderByWithRelationAndSearchRelevanceInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  menusId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  locationsId: z.lazy(() => SortOrderSchema).optional(),
  price: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  drink_id: z.lazy(() => SortOrderSchema).optional(),
  drink_variant_name: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  menus: z.lazy(() => menusOrderByWithRelationAndSearchRelevanceInputSchema).optional(),
  locations: z.lazy(() => locationsOrderByWithRelationAndSearchRelevanceInputSchema).optional(),
  drinks: z.lazy(() => drinksOrderByWithRelationAndSearchRelevanceInputSchema).optional(),
  _relevance: z.lazy(() => pricesOrderByRelevanceInputSchema).optional()
}).strict();

export const pricesWhereUniqueInputSchema: z.ZodType<Prisma.pricesWhereUniqueInput> = z.object({
  id: z.string().uuid().optional()
}).strict();

export const pricesOrderByWithAggregationInputSchema: z.ZodType<Prisma.pricesOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  menusId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  locationsId: z.lazy(() => SortOrderSchema).optional(),
  price: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  drink_id: z.lazy(() => SortOrderSchema).optional(),
  drink_variant_name: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => pricesCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => pricesAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => pricesMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => pricesMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => pricesSumOrderByAggregateInputSchema).optional()
}).strict();

export const pricesScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.pricesScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => pricesScalarWhereWithAggregatesInputSchema),z.lazy(() => pricesScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => pricesScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => pricesScalarWhereWithAggregatesInputSchema),z.lazy(() => pricesScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  timestamp: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  menusId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  locationsId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  price: z.union([ z.lazy(() => FloatNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  drink_id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  drink_variant_name: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const menusWhereInputSchema: z.ZodType<Prisma.menusWhereInput> = z.object({
  AND: z.union([ z.lazy(() => menusWhereInputSchema),z.lazy(() => menusWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => menusWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => menusWhereInputSchema),z.lazy(() => menusWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  timestamp: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  isDiscounted: z.union([ z.lazy(() => BoolNullableFilterSchema),z.boolean() ]).optional().nullable(),
  locationsId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  daysOfWeek: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  timeStart: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  timeEnd: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  meandu_id: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  locations: z.union([ z.lazy(() => LocationsRelationFilterSchema),z.lazy(() => locationsWhereInputSchema) ]).optional(),
  prices: z.lazy(() => PricesListRelationFilterSchema).optional()
}).strict();

export const menusOrderByWithRelationAndSearchRelevanceInputSchema: z.ZodType<Prisma.menusOrderByWithRelationAndSearchRelevanceInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  isDiscounted: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  locationsId: z.lazy(() => SortOrderSchema).optional(),
  daysOfWeek: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  timeStart: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  timeEnd: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  meandu_id: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  locations: z.lazy(() => locationsOrderByWithRelationAndSearchRelevanceInputSchema).optional(),
  prices: z.lazy(() => pricesOrderByRelationAggregateInputSchema).optional(),
  _relevance: z.lazy(() => menusOrderByRelevanceInputSchema).optional()
}).strict();

export const menusWhereUniqueInputSchema: z.ZodType<Prisma.menusWhereUniqueInput> = z.object({
  id: z.string().uuid().optional(),
  locationsId_meandu_id: z.lazy(() => menusLocationsIdMeandu_idCompoundUniqueInputSchema).optional()
}).strict();

export const menusOrderByWithAggregationInputSchema: z.ZodType<Prisma.menusOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  isDiscounted: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  locationsId: z.lazy(() => SortOrderSchema).optional(),
  daysOfWeek: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  timeStart: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  timeEnd: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  meandu_id: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => menusCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => menusAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => menusMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => menusMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => menusSumOrderByAggregateInputSchema).optional()
}).strict();

export const menusScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.menusScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => menusScalarWhereWithAggregatesInputSchema),z.lazy(() => menusScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => menusScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => menusScalarWhereWithAggregatesInputSchema),z.lazy(() => menusScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  timestamp: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  name: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  isDiscounted: z.union([ z.lazy(() => BoolNullableWithAggregatesFilterSchema),z.boolean() ]).optional().nullable(),
  locationsId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  daysOfWeek: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  timeStart: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  timeEnd: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  meandu_id: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const AccountWhereInputSchema: z.ZodType<Prisma.AccountWhereInput> = z.object({
  AND: z.union([ z.lazy(() => AccountWhereInputSchema),z.lazy(() => AccountWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => AccountWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AccountWhereInputSchema),z.lazy(() => AccountWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  userId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  provider: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  providerAccountId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  refresh_token: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  access_token: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  expires_at: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  token_type: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  scope: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  id_token: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  session_state: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  user: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
}).strict();

export const AccountOrderByWithRelationAndSearchRelevanceInputSchema: z.ZodType<Prisma.AccountOrderByWithRelationAndSearchRelevanceInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  provider: z.lazy(() => SortOrderSchema).optional(),
  providerAccountId: z.lazy(() => SortOrderSchema).optional(),
  refresh_token: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  access_token: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  expires_at: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  token_type: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  scope: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  id_token: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  session_state: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  user: z.lazy(() => UserOrderByWithRelationAndSearchRelevanceInputSchema).optional(),
  _relevance: z.lazy(() => AccountOrderByRelevanceInputSchema).optional()
}).strict();

export const AccountWhereUniqueInputSchema: z.ZodType<Prisma.AccountWhereUniqueInput> = z.object({
  id: z.string().cuid().optional(),
  provider_providerAccountId: z.lazy(() => AccountProviderProviderAccountIdCompoundUniqueInputSchema).optional()
}).strict();

export const AccountOrderByWithAggregationInputSchema: z.ZodType<Prisma.AccountOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  provider: z.lazy(() => SortOrderSchema).optional(),
  providerAccountId: z.lazy(() => SortOrderSchema).optional(),
  refresh_token: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  access_token: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  expires_at: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  token_type: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  scope: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  id_token: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  session_state: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => AccountCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => AccountAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => AccountMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => AccountMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => AccountSumOrderByAggregateInputSchema).optional()
}).strict();

export const AccountScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.AccountScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => AccountScalarWhereWithAggregatesInputSchema),z.lazy(() => AccountScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => AccountScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AccountScalarWhereWithAggregatesInputSchema),z.lazy(() => AccountScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  userId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  provider: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  providerAccountId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  refresh_token: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  access_token: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  expires_at: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  token_type: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  scope: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  id_token: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  session_state: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const SessionWhereInputSchema: z.ZodType<Prisma.SessionWhereInput> = z.object({
  AND: z.union([ z.lazy(() => SessionWhereInputSchema),z.lazy(() => SessionWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SessionWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SessionWhereInputSchema),z.lazy(() => SessionWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  sessionToken: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  userId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  expires: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  user: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
}).strict();

export const SessionOrderByWithRelationAndSearchRelevanceInputSchema: z.ZodType<Prisma.SessionOrderByWithRelationAndSearchRelevanceInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  sessionToken: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional(),
  user: z.lazy(() => UserOrderByWithRelationAndSearchRelevanceInputSchema).optional(),
  _relevance: z.lazy(() => SessionOrderByRelevanceInputSchema).optional()
}).strict();

export const SessionWhereUniqueInputSchema: z.ZodType<Prisma.SessionWhereUniqueInput> = z.object({
  id: z.string().cuid().optional(),
  sessionToken: z.string().optional()
}).strict();

export const SessionOrderByWithAggregationInputSchema: z.ZodType<Prisma.SessionOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  sessionToken: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => SessionCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => SessionMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => SessionMinOrderByAggregateInputSchema).optional()
}).strict();

export const SessionScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.SessionScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => SessionScalarWhereWithAggregatesInputSchema),z.lazy(() => SessionScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => SessionScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SessionScalarWhereWithAggregatesInputSchema),z.lazy(() => SessionScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  sessionToken: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  userId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  expires: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const UserWhereInputSchema: z.ZodType<Prisma.UserWhereInput> = z.object({
  AND: z.union([ z.lazy(() => UserWhereInputSchema),z.lazy(() => UserWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => UserWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => UserWhereInputSchema),z.lazy(() => UserWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  email: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  emailVerified: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  image: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  apiKey: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  accounts: z.lazy(() => AccountListRelationFilterSchema).optional(),
  sessions: z.lazy(() => SessionListRelationFilterSchema).optional()
}).strict();

export const UserOrderByWithRelationAndSearchRelevanceInputSchema: z.ZodType<Prisma.UserOrderByWithRelationAndSearchRelevanceInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  email: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  emailVerified: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  image: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  apiKey: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  accounts: z.lazy(() => AccountOrderByRelationAggregateInputSchema).optional(),
  sessions: z.lazy(() => SessionOrderByRelationAggregateInputSchema).optional(),
  _relevance: z.lazy(() => UserOrderByRelevanceInputSchema).optional()
}).strict();

export const UserWhereUniqueInputSchema: z.ZodType<Prisma.UserWhereUniqueInput> = z.object({
  id: z.string().cuid().optional(),
  email: z.string().optional(),
  apiKey: z.string().uuid().optional()
}).strict();

export const UserOrderByWithAggregationInputSchema: z.ZodType<Prisma.UserOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  email: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  emailVerified: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  image: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  apiKey: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => UserCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => UserMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => UserMinOrderByAggregateInputSchema).optional()
}).strict();

export const UserScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.UserScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => UserScalarWhereWithAggregatesInputSchema),z.lazy(() => UserScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => UserScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => UserScalarWhereWithAggregatesInputSchema),z.lazy(() => UserScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  email: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  emailVerified: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  image: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  apiKey: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const VerificationTokenWhereInputSchema: z.ZodType<Prisma.VerificationTokenWhereInput> = z.object({
  AND: z.union([ z.lazy(() => VerificationTokenWhereInputSchema),z.lazy(() => VerificationTokenWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => VerificationTokenWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => VerificationTokenWhereInputSchema),z.lazy(() => VerificationTokenWhereInputSchema).array() ]).optional(),
  identifier: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  token: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  expires: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const VerificationTokenOrderByWithRelationAndSearchRelevanceInputSchema: z.ZodType<Prisma.VerificationTokenOrderByWithRelationAndSearchRelevanceInput> = z.object({
  identifier: z.lazy(() => SortOrderSchema).optional(),
  token: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional(),
  _relevance: z.lazy(() => VerificationTokenOrderByRelevanceInputSchema).optional()
}).strict();

export const VerificationTokenWhereUniqueInputSchema: z.ZodType<Prisma.VerificationTokenWhereUniqueInput> = z.object({
  token: z.string().optional(),
  identifier_token: z.lazy(() => VerificationTokenIdentifierTokenCompoundUniqueInputSchema).optional()
}).strict();

export const VerificationTokenOrderByWithAggregationInputSchema: z.ZodType<Prisma.VerificationTokenOrderByWithAggregationInput> = z.object({
  identifier: z.lazy(() => SortOrderSchema).optional(),
  token: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => VerificationTokenCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => VerificationTokenMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => VerificationTokenMinOrderByAggregateInputSchema).optional()
}).strict();

export const VerificationTokenScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.VerificationTokenScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => VerificationTokenScalarWhereWithAggregatesInputSchema),z.lazy(() => VerificationTokenScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => VerificationTokenScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => VerificationTokenScalarWhereWithAggregatesInputSchema),z.lazy(() => VerificationTokenScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  identifier: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  token: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  expires: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const contactWhereInputSchema: z.ZodType<Prisma.contactWhereInput> = z.object({
  AND: z.union([ z.lazy(() => contactWhereInputSchema),z.lazy(() => contactWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => contactWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => contactWhereInputSchema),z.lazy(() => contactWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  email: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  message: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  locationsId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  x: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  linkedin: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  instagram: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  facebook: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  website: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  phone: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  locations: z.union([ z.lazy(() => LocationsRelationFilterSchema),z.lazy(() => locationsWhereInputSchema) ]).optional().nullable(),
}).strict();

export const contactOrderByWithRelationAndSearchRelevanceInputSchema: z.ZodType<Prisma.contactOrderByWithRelationAndSearchRelevanceInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  email: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  message: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  locationsId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  x: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  linkedin: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  instagram: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  facebook: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  website: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  phone: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  locations: z.lazy(() => locationsOrderByWithRelationAndSearchRelevanceInputSchema).optional(),
  _relevance: z.lazy(() => contactOrderByRelevanceInputSchema).optional()
}).strict();

export const contactWhereUniqueInputSchema: z.ZodType<Prisma.contactWhereUniqueInput> = z.object({
  id: z.string().uuid().optional()
}).strict();

export const contactOrderByWithAggregationInputSchema: z.ZodType<Prisma.contactOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  email: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  message: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  locationsId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  x: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  linkedin: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  instagram: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  facebook: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  website: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  phone: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => contactCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => contactMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => contactMinOrderByAggregateInputSchema).optional()
}).strict();

export const contactScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.contactScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => contactScalarWhereWithAggregatesInputSchema),z.lazy(() => contactScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => contactScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => contactScalarWhereWithAggregatesInputSchema),z.lazy(() => contactScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  name: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  email: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  message: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  locationsId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  x: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  linkedin: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  instagram: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  facebook: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  website: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  phone: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const locationsCreateInputSchema: z.ZodType<Prisma.locationsCreateInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  meandu_id: z.string().optional().nullable(),
  meandu_id_1: z.string().optional().nullable(),
  meandu_id_2: z.string().optional().nullable(),
  name: z.string().optional().nullable(),
  address: z.string().optional().nullable(),
  latitude: z.number().optional().nullable(),
  longitude: z.number().optional().nullable(),
  date_created: z.coerce.date().optional().nullable(),
  google_name: z.string().optional().nullable(),
  google_place_id: z.string().optional().nullable(),
  currency: z.string().optional().nullable(),
  locale: z.string().optional().nullable(),
  meandu_menu_url: z.string().optional().nullable(),
  last_updated: z.coerce.date().optional().nullable(),
  google_operational: z.string().optional().nullable(),
  validityScore: z.number().int().optional().nullable(),
  hidden: z.boolean().optional().nullable(),
  menus: z.lazy(() => menusCreateNestedManyWithoutLocationsInputSchema).optional(),
  prices: z.lazy(() => pricesCreateNestedManyWithoutLocationsInputSchema).optional(),
  drinks: z.lazy(() => drinksCreateNestedManyWithoutLocationsInputSchema).optional(),
  drink_categories: z.lazy(() => drink_categoriesCreateNestedManyWithoutLocationsInputSchema).optional(),
  contact: z.lazy(() => contactCreateNestedManyWithoutLocationsInputSchema).optional()
}).strict();

export const locationsUncheckedCreateInputSchema: z.ZodType<Prisma.locationsUncheckedCreateInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  meandu_id: z.string().optional().nullable(),
  meandu_id_1: z.string().optional().nullable(),
  meandu_id_2: z.string().optional().nullable(),
  name: z.string().optional().nullable(),
  address: z.string().optional().nullable(),
  latitude: z.number().optional().nullable(),
  longitude: z.number().optional().nullable(),
  date_created: z.coerce.date().optional().nullable(),
  google_name: z.string().optional().nullable(),
  google_place_id: z.string().optional().nullable(),
  currency: z.string().optional().nullable(),
  locale: z.string().optional().nullable(),
  meandu_menu_url: z.string().optional().nullable(),
  last_updated: z.coerce.date().optional().nullable(),
  google_operational: z.string().optional().nullable(),
  validityScore: z.number().int().optional().nullable(),
  hidden: z.boolean().optional().nullable(),
  menus: z.lazy(() => menusUncheckedCreateNestedManyWithoutLocationsInputSchema).optional(),
  prices: z.lazy(() => pricesUncheckedCreateNestedManyWithoutLocationsInputSchema).optional(),
  drinks: z.lazy(() => drinksUncheckedCreateNestedManyWithoutLocationsInputSchema).optional(),
  drink_categories: z.lazy(() => drink_categoriesUncheckedCreateNestedManyWithoutLocationsInputSchema).optional(),
  contact: z.lazy(() => contactUncheckedCreateNestedManyWithoutLocationsInputSchema).optional()
}).strict();

export const locationsUpdateInputSchema: z.ZodType<Prisma.locationsUpdateInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id_1: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id_2: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  latitude: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  longitude: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  date_created: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_place_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currency: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locale: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_menu_url: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  last_updated: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_operational: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  validityScore: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hidden: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  menus: z.lazy(() => menusUpdateManyWithoutLocationsNestedInputSchema).optional(),
  prices: z.lazy(() => pricesUpdateManyWithoutLocationsNestedInputSchema).optional(),
  drinks: z.lazy(() => drinksUpdateManyWithoutLocationsNestedInputSchema).optional(),
  drink_categories: z.lazy(() => drink_categoriesUpdateManyWithoutLocationsNestedInputSchema).optional(),
  contact: z.lazy(() => contactUpdateManyWithoutLocationsNestedInputSchema).optional()
}).strict();

export const locationsUncheckedUpdateInputSchema: z.ZodType<Prisma.locationsUncheckedUpdateInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id_1: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id_2: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  latitude: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  longitude: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  date_created: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_place_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currency: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locale: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_menu_url: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  last_updated: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_operational: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  validityScore: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hidden: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  menus: z.lazy(() => menusUncheckedUpdateManyWithoutLocationsNestedInputSchema).optional(),
  prices: z.lazy(() => pricesUncheckedUpdateManyWithoutLocationsNestedInputSchema).optional(),
  drinks: z.lazy(() => drinksUncheckedUpdateManyWithoutLocationsNestedInputSchema).optional(),
  drink_categories: z.lazy(() => drink_categoriesUncheckedUpdateManyWithoutLocationsNestedInputSchema).optional(),
  contact: z.lazy(() => contactUncheckedUpdateManyWithoutLocationsNestedInputSchema).optional()
}).strict();

export const locationsCreateManyInputSchema: z.ZodType<Prisma.locationsCreateManyInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  meandu_id: z.string().optional().nullable(),
  meandu_id_1: z.string().optional().nullable(),
  meandu_id_2: z.string().optional().nullable(),
  name: z.string().optional().nullable(),
  address: z.string().optional().nullable(),
  latitude: z.number().optional().nullable(),
  longitude: z.number().optional().nullable(),
  date_created: z.coerce.date().optional().nullable(),
  google_name: z.string().optional().nullable(),
  google_place_id: z.string().optional().nullable(),
  currency: z.string().optional().nullable(),
  locale: z.string().optional().nullable(),
  meandu_menu_url: z.string().optional().nullable(),
  last_updated: z.coerce.date().optional().nullable(),
  google_operational: z.string().optional().nullable(),
  validityScore: z.number().int().optional().nullable(),
  hidden: z.boolean().optional().nullable()
}).strict();

export const locationsUpdateManyMutationInputSchema: z.ZodType<Prisma.locationsUpdateManyMutationInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id_1: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id_2: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  latitude: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  longitude: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  date_created: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_place_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currency: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locale: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_menu_url: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  last_updated: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_operational: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  validityScore: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hidden: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const locationsUncheckedUpdateManyInputSchema: z.ZodType<Prisma.locationsUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id_1: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id_2: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  latitude: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  longitude: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  date_created: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_place_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currency: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locale: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_menu_url: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  last_updated: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_operational: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  validityScore: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hidden: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const drink_categoriesCreateInputSchema: z.ZodType<Prisma.drink_categoriesCreateInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  name: z.string(),
  meandu_id: z.string().optional().nullable(),
  locations: z.lazy(() => locationsCreateNestedOneWithoutDrink_categoriesInputSchema),
  drinks: z.lazy(() => drinks_to_drink_categoriesCreateNestedManyWithoutDrink_categoriesInputSchema).optional()
}).strict();

export const drink_categoriesUncheckedCreateInputSchema: z.ZodType<Prisma.drink_categoriesUncheckedCreateInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  name: z.string(),
  meandu_id: z.string().optional().nullable(),
  locationsId: z.string(),
  drinks: z.lazy(() => drinks_to_drink_categoriesUncheckedCreateNestedManyWithoutDrink_categoriesInputSchema).optional()
}).strict();

export const drink_categoriesUpdateInputSchema: z.ZodType<Prisma.drink_categoriesUpdateInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locations: z.lazy(() => locationsUpdateOneRequiredWithoutDrink_categoriesNestedInputSchema).optional(),
  drinks: z.lazy(() => drinks_to_drink_categoriesUpdateManyWithoutDrink_categoriesNestedInputSchema).optional()
}).strict();

export const drink_categoriesUncheckedUpdateInputSchema: z.ZodType<Prisma.drink_categoriesUncheckedUpdateInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationsId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  drinks: z.lazy(() => drinks_to_drink_categoriesUncheckedUpdateManyWithoutDrink_categoriesNestedInputSchema).optional()
}).strict();

export const drink_categoriesCreateManyInputSchema: z.ZodType<Prisma.drink_categoriesCreateManyInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  name: z.string(),
  meandu_id: z.string().optional().nullable(),
  locationsId: z.string()
}).strict();

export const drink_categoriesUpdateManyMutationInputSchema: z.ZodType<Prisma.drink_categoriesUpdateManyMutationInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const drink_categoriesUncheckedUpdateManyInputSchema: z.ZodType<Prisma.drink_categoriesUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationsId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const drinksCreateInputSchema: z.ZodType<Prisma.drinksCreateInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  name: z.string(),
  meandu_id: z.string().optional().nullable(),
  prices: z.lazy(() => pricesCreateNestedManyWithoutDrinksInputSchema).optional(),
  locations: z.lazy(() => locationsCreateNestedOneWithoutDrinksInputSchema),
  drink_categories: z.lazy(() => drinks_to_drink_categoriesCreateNestedManyWithoutDrinksInputSchema).optional()
}).strict();

export const drinksUncheckedCreateInputSchema: z.ZodType<Prisma.drinksUncheckedCreateInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  name: z.string(),
  locationsId: z.string(),
  meandu_id: z.string().optional().nullable(),
  prices: z.lazy(() => pricesUncheckedCreateNestedManyWithoutDrinksInputSchema).optional(),
  drink_categories: z.lazy(() => drinks_to_drink_categoriesUncheckedCreateNestedManyWithoutDrinksInputSchema).optional()
}).strict();

export const drinksUpdateInputSchema: z.ZodType<Prisma.drinksUpdateInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  prices: z.lazy(() => pricesUpdateManyWithoutDrinksNestedInputSchema).optional(),
  locations: z.lazy(() => locationsUpdateOneRequiredWithoutDrinksNestedInputSchema).optional(),
  drink_categories: z.lazy(() => drinks_to_drink_categoriesUpdateManyWithoutDrinksNestedInputSchema).optional()
}).strict();

export const drinksUncheckedUpdateInputSchema: z.ZodType<Prisma.drinksUncheckedUpdateInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  locationsId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  prices: z.lazy(() => pricesUncheckedUpdateManyWithoutDrinksNestedInputSchema).optional(),
  drink_categories: z.lazy(() => drinks_to_drink_categoriesUncheckedUpdateManyWithoutDrinksNestedInputSchema).optional()
}).strict();

export const drinksCreateManyInputSchema: z.ZodType<Prisma.drinksCreateManyInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  name: z.string(),
  locationsId: z.string(),
  meandu_id: z.string().optional().nullable()
}).strict();

export const drinksUpdateManyMutationInputSchema: z.ZodType<Prisma.drinksUpdateManyMutationInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const drinksUncheckedUpdateManyInputSchema: z.ZodType<Prisma.drinksUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  locationsId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const drinks_to_drink_categoriesCreateInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesCreateInput> = z.object({
  drinks: z.lazy(() => drinksCreateNestedOneWithoutDrink_categoriesInputSchema),
  drink_categories: z.lazy(() => drink_categoriesCreateNestedOneWithoutDrinksInputSchema)
}).strict();

export const drinks_to_drink_categoriesUncheckedCreateInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesUncheckedCreateInput> = z.object({
  drink_id: z.string(),
  drink_category_id: z.string()
}).strict();

export const drinks_to_drink_categoriesUpdateInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesUpdateInput> = z.object({
  drinks: z.lazy(() => drinksUpdateOneRequiredWithoutDrink_categoriesNestedInputSchema).optional(),
  drink_categories: z.lazy(() => drink_categoriesUpdateOneRequiredWithoutDrinksNestedInputSchema).optional()
}).strict();

export const drinks_to_drink_categoriesUncheckedUpdateInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesUncheckedUpdateInput> = z.object({
  drink_id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  drink_category_id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const drinks_to_drink_categoriesCreateManyInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesCreateManyInput> = z.object({
  drink_id: z.string(),
  drink_category_id: z.string()
}).strict();

export const drinks_to_drink_categoriesUpdateManyMutationInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesUpdateManyMutationInput> = z.object({
}).strict();

export const drinks_to_drink_categoriesUncheckedUpdateManyInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesUncheckedUpdateManyInput> = z.object({
  drink_id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  drink_category_id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const pricesCreateInputSchema: z.ZodType<Prisma.pricesCreateInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  price: z.number().optional().nullable(),
  drink_variant_name: z.string().optional().nullable(),
  menus: z.lazy(() => menusCreateNestedOneWithoutPricesInputSchema).optional(),
  locations: z.lazy(() => locationsCreateNestedOneWithoutPricesInputSchema),
  drinks: z.lazy(() => drinksCreateNestedOneWithoutPricesInputSchema)
}).strict();

export const pricesUncheckedCreateInputSchema: z.ZodType<Prisma.pricesUncheckedCreateInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  menusId: z.string().optional().nullable(),
  locationsId: z.string(),
  price: z.number().optional().nullable(),
  drink_id: z.string(),
  drink_variant_name: z.string().optional().nullable()
}).strict();

export const pricesUpdateInputSchema: z.ZodType<Prisma.pricesUpdateInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  price: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  drink_variant_name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  menus: z.lazy(() => menusUpdateOneWithoutPricesNestedInputSchema).optional(),
  locations: z.lazy(() => locationsUpdateOneRequiredWithoutPricesNestedInputSchema).optional(),
  drinks: z.lazy(() => drinksUpdateOneRequiredWithoutPricesNestedInputSchema).optional()
}).strict();

export const pricesUncheckedUpdateInputSchema: z.ZodType<Prisma.pricesUncheckedUpdateInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  menusId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationsId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  price: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  drink_id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  drink_variant_name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const pricesCreateManyInputSchema: z.ZodType<Prisma.pricesCreateManyInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  menusId: z.string().optional().nullable(),
  locationsId: z.string(),
  price: z.number().optional().nullable(),
  drink_id: z.string(),
  drink_variant_name: z.string().optional().nullable()
}).strict();

export const pricesUpdateManyMutationInputSchema: z.ZodType<Prisma.pricesUpdateManyMutationInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  price: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  drink_variant_name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const pricesUncheckedUpdateManyInputSchema: z.ZodType<Prisma.pricesUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  menusId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationsId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  price: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  drink_id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  drink_variant_name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const menusCreateInputSchema: z.ZodType<Prisma.menusCreateInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  name: z.string(),
  isDiscounted: z.boolean().optional().nullable(),
  daysOfWeek: z.number().int().optional().nullable(),
  timeStart: z.number().int().optional().nullable(),
  timeEnd: z.number().int().optional().nullable(),
  meandu_id: z.string().optional().nullable(),
  locations: z.lazy(() => locationsCreateNestedOneWithoutMenusInputSchema),
  prices: z.lazy(() => pricesCreateNestedManyWithoutMenusInputSchema).optional()
}).strict();

export const menusUncheckedCreateInputSchema: z.ZodType<Prisma.menusUncheckedCreateInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  name: z.string(),
  isDiscounted: z.boolean().optional().nullable(),
  locationsId: z.string(),
  daysOfWeek: z.number().int().optional().nullable(),
  timeStart: z.number().int().optional().nullable(),
  timeEnd: z.number().int().optional().nullable(),
  meandu_id: z.string().optional().nullable(),
  prices: z.lazy(() => pricesUncheckedCreateNestedManyWithoutMenusInputSchema).optional()
}).strict();

export const menusUpdateInputSchema: z.ZodType<Prisma.menusUpdateInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  isDiscounted: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  daysOfWeek: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  timeStart: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  timeEnd: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locations: z.lazy(() => locationsUpdateOneRequiredWithoutMenusNestedInputSchema).optional(),
  prices: z.lazy(() => pricesUpdateManyWithoutMenusNestedInputSchema).optional()
}).strict();

export const menusUncheckedUpdateInputSchema: z.ZodType<Prisma.menusUncheckedUpdateInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  isDiscounted: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationsId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  daysOfWeek: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  timeStart: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  timeEnd: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  prices: z.lazy(() => pricesUncheckedUpdateManyWithoutMenusNestedInputSchema).optional()
}).strict();

export const menusCreateManyInputSchema: z.ZodType<Prisma.menusCreateManyInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  name: z.string(),
  isDiscounted: z.boolean().optional().nullable(),
  locationsId: z.string(),
  daysOfWeek: z.number().int().optional().nullable(),
  timeStart: z.number().int().optional().nullable(),
  timeEnd: z.number().int().optional().nullable(),
  meandu_id: z.string().optional().nullable()
}).strict();

export const menusUpdateManyMutationInputSchema: z.ZodType<Prisma.menusUpdateManyMutationInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  isDiscounted: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  daysOfWeek: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  timeStart: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  timeEnd: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const menusUncheckedUpdateManyInputSchema: z.ZodType<Prisma.menusUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  isDiscounted: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationsId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  daysOfWeek: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  timeStart: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  timeEnd: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AccountCreateInputSchema: z.ZodType<Prisma.AccountCreateInput> = z.object({
  id: z.string().cuid().optional(),
  type: z.string(),
  provider: z.string(),
  providerAccountId: z.string(),
  refresh_token: z.string().optional().nullable(),
  access_token: z.string().optional().nullable(),
  expires_at: z.number().int().optional().nullable(),
  token_type: z.string().optional().nullable(),
  scope: z.string().optional().nullable(),
  id_token: z.string().optional().nullable(),
  session_state: z.string().optional().nullable(),
  user: z.lazy(() => UserCreateNestedOneWithoutAccountsInputSchema)
}).strict();

export const AccountUncheckedCreateInputSchema: z.ZodType<Prisma.AccountUncheckedCreateInput> = z.object({
  id: z.string().cuid().optional(),
  userId: z.string(),
  type: z.string(),
  provider: z.string(),
  providerAccountId: z.string(),
  refresh_token: z.string().optional().nullable(),
  access_token: z.string().optional().nullable(),
  expires_at: z.number().int().optional().nullable(),
  token_type: z.string().optional().nullable(),
  scope: z.string().optional().nullable(),
  id_token: z.string().optional().nullable(),
  session_state: z.string().optional().nullable()
}).strict();

export const AccountUpdateInputSchema: z.ZodType<Prisma.AccountUpdateInput> = z.object({
  id: z.union([ z.string().cuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  provider: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  providerAccountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  refresh_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  access_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  expires_at: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  token_type: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  scope: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  id_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  session_state: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutAccountsNestedInputSchema).optional()
}).strict();

export const AccountUncheckedUpdateInputSchema: z.ZodType<Prisma.AccountUncheckedUpdateInput> = z.object({
  id: z.union([ z.string().cuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  provider: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  providerAccountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  refresh_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  access_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  expires_at: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  token_type: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  scope: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  id_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  session_state: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AccountCreateManyInputSchema: z.ZodType<Prisma.AccountCreateManyInput> = z.object({
  id: z.string().cuid().optional(),
  userId: z.string(),
  type: z.string(),
  provider: z.string(),
  providerAccountId: z.string(),
  refresh_token: z.string().optional().nullable(),
  access_token: z.string().optional().nullable(),
  expires_at: z.number().int().optional().nullable(),
  token_type: z.string().optional().nullable(),
  scope: z.string().optional().nullable(),
  id_token: z.string().optional().nullable(),
  session_state: z.string().optional().nullable()
}).strict();

export const AccountUpdateManyMutationInputSchema: z.ZodType<Prisma.AccountUpdateManyMutationInput> = z.object({
  id: z.union([ z.string().cuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  provider: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  providerAccountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  refresh_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  access_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  expires_at: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  token_type: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  scope: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  id_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  session_state: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AccountUncheckedUpdateManyInputSchema: z.ZodType<Prisma.AccountUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string().cuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  provider: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  providerAccountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  refresh_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  access_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  expires_at: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  token_type: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  scope: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  id_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  session_state: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const SessionCreateInputSchema: z.ZodType<Prisma.SessionCreateInput> = z.object({
  id: z.string().cuid().optional(),
  sessionToken: z.string(),
  expires: z.coerce.date(),
  user: z.lazy(() => UserCreateNestedOneWithoutSessionsInputSchema)
}).strict();

export const SessionUncheckedCreateInputSchema: z.ZodType<Prisma.SessionUncheckedCreateInput> = z.object({
  id: z.string().cuid().optional(),
  sessionToken: z.string(),
  userId: z.string(),
  expires: z.coerce.date()
}).strict();

export const SessionUpdateInputSchema: z.ZodType<Prisma.SessionUpdateInput> = z.object({
  id: z.union([ z.string().cuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sessionToken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutSessionsNestedInputSchema).optional()
}).strict();

export const SessionUncheckedUpdateInputSchema: z.ZodType<Prisma.SessionUncheckedUpdateInput> = z.object({
  id: z.union([ z.string().cuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sessionToken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SessionCreateManyInputSchema: z.ZodType<Prisma.SessionCreateManyInput> = z.object({
  id: z.string().cuid().optional(),
  sessionToken: z.string(),
  userId: z.string(),
  expires: z.coerce.date()
}).strict();

export const SessionUpdateManyMutationInputSchema: z.ZodType<Prisma.SessionUpdateManyMutationInput> = z.object({
  id: z.union([ z.string().cuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sessionToken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SessionUncheckedUpdateManyInputSchema: z.ZodType<Prisma.SessionUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string().cuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sessionToken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const UserCreateInputSchema: z.ZodType<Prisma.UserCreateInput> = z.object({
  id: z.string().cuid().optional(),
  name: z.string().optional().nullable(),
  email: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  apiKey: z.string().uuid().optional().nullable(),
  accounts: z.lazy(() => AccountCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserUncheckedCreateInputSchema: z.ZodType<Prisma.UserUncheckedCreateInput> = z.object({
  id: z.string().cuid().optional(),
  name: z.string().optional().nullable(),
  email: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  apiKey: z.string().uuid().optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserUpdateInputSchema: z.ZodType<Prisma.UserUpdateInput> = z.object({
  id: z.union([ z.string().cuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  apiKey: z.union([ z.string().uuid(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateInputSchema: z.ZodType<Prisma.UserUncheckedUpdateInput> = z.object({
  id: z.union([ z.string().cuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  apiKey: z.union([ z.string().uuid(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const UserCreateManyInputSchema: z.ZodType<Prisma.UserCreateManyInput> = z.object({
  id: z.string().cuid().optional(),
  name: z.string().optional().nullable(),
  email: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  apiKey: z.string().uuid().optional().nullable()
}).strict();

export const UserUpdateManyMutationInputSchema: z.ZodType<Prisma.UserUpdateManyMutationInput> = z.object({
  id: z.union([ z.string().cuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  apiKey: z.union([ z.string().uuid(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const UserUncheckedUpdateManyInputSchema: z.ZodType<Prisma.UserUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string().cuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  apiKey: z.union([ z.string().uuid(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const VerificationTokenCreateInputSchema: z.ZodType<Prisma.VerificationTokenCreateInput> = z.object({
  identifier: z.string(),
  token: z.string(),
  expires: z.coerce.date()
}).strict();

export const VerificationTokenUncheckedCreateInputSchema: z.ZodType<Prisma.VerificationTokenUncheckedCreateInput> = z.object({
  identifier: z.string(),
  token: z.string(),
  expires: z.coerce.date()
}).strict();

export const VerificationTokenUpdateInputSchema: z.ZodType<Prisma.VerificationTokenUpdateInput> = z.object({
  identifier: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const VerificationTokenUncheckedUpdateInputSchema: z.ZodType<Prisma.VerificationTokenUncheckedUpdateInput> = z.object({
  identifier: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const VerificationTokenCreateManyInputSchema: z.ZodType<Prisma.VerificationTokenCreateManyInput> = z.object({
  identifier: z.string(),
  token: z.string(),
  expires: z.coerce.date()
}).strict();

export const VerificationTokenUpdateManyMutationInputSchema: z.ZodType<Prisma.VerificationTokenUpdateManyMutationInput> = z.object({
  identifier: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const VerificationTokenUncheckedUpdateManyInputSchema: z.ZodType<Prisma.VerificationTokenUncheckedUpdateManyInput> = z.object({
  identifier: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const contactCreateInputSchema: z.ZodType<Prisma.contactCreateInput> = z.object({
  id: z.string().uuid().optional(),
  createdAt: z.coerce.date().optional(),
  name: z.string(),
  email: z.string().optional().nullable(),
  message: z.string().optional().nullable(),
  x: z.string().optional().nullable(),
  linkedin: z.string().optional().nullable(),
  instagram: z.string().optional().nullable(),
  facebook: z.string().optional().nullable(),
  website: z.string().optional().nullable(),
  phone: z.string().optional().nullable(),
  locations: z.lazy(() => locationsCreateNestedOneWithoutContactInputSchema).optional()
}).strict();

export const contactUncheckedCreateInputSchema: z.ZodType<Prisma.contactUncheckedCreateInput> = z.object({
  id: z.string().uuid().optional(),
  createdAt: z.coerce.date().optional(),
  name: z.string(),
  email: z.string().optional().nullable(),
  message: z.string().optional().nullable(),
  locationsId: z.string().optional().nullable(),
  x: z.string().optional().nullable(),
  linkedin: z.string().optional().nullable(),
  instagram: z.string().optional().nullable(),
  facebook: z.string().optional().nullable(),
  website: z.string().optional().nullable(),
  phone: z.string().optional().nullable()
}).strict();

export const contactUpdateInputSchema: z.ZodType<Prisma.contactUpdateInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  message: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  x: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  linkedin: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instagram: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  facebook: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  website: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locations: z.lazy(() => locationsUpdateOneWithoutContactNestedInputSchema).optional()
}).strict();

export const contactUncheckedUpdateInputSchema: z.ZodType<Prisma.contactUncheckedUpdateInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  message: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationsId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  x: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  linkedin: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instagram: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  facebook: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  website: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const contactCreateManyInputSchema: z.ZodType<Prisma.contactCreateManyInput> = z.object({
  id: z.string().uuid().optional(),
  createdAt: z.coerce.date().optional(),
  name: z.string(),
  email: z.string().optional().nullable(),
  message: z.string().optional().nullable(),
  locationsId: z.string().optional().nullable(),
  x: z.string().optional().nullable(),
  linkedin: z.string().optional().nullable(),
  instagram: z.string().optional().nullable(),
  facebook: z.string().optional().nullable(),
  website: z.string().optional().nullable(),
  phone: z.string().optional().nullable()
}).strict();

export const contactUpdateManyMutationInputSchema: z.ZodType<Prisma.contactUpdateManyMutationInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  message: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  x: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  linkedin: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instagram: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  facebook: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  website: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const contactUncheckedUpdateManyInputSchema: z.ZodType<Prisma.contactUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  message: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationsId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  x: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  linkedin: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instagram: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  facebook: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  website: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const StringFilterSchema: z.ZodType<Prisma.StringFilter> = z.object({
  equals: z.string().optional(),
  in: z.union([ z.string().array(),z.string() ]).optional(),
  notIn: z.union([ z.string().array(),z.string() ]).optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  search: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringFilterSchema) ]).optional(),
}).strict();

export const DateTimeFilterSchema: z.ZodType<Prisma.DateTimeFilter> = z.object({
  equals: z.coerce.date().optional(),
  in: z.union([ z.coerce.date().array(),z.coerce.date() ]).optional(),
  notIn: z.union([ z.coerce.date().array(),z.coerce.date() ]).optional(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeFilterSchema) ]).optional(),
}).strict();

export const StringNullableFilterSchema: z.ZodType<Prisma.StringNullableFilter> = z.object({
  equals: z.string().optional().nullable(),
  in: z.union([ z.string().array(),z.string() ]).optional().nullable(),
  notIn: z.union([ z.string().array(),z.string() ]).optional().nullable(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  search: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const FloatNullableFilterSchema: z.ZodType<Prisma.FloatNullableFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.union([ z.number().array(),z.number() ]).optional().nullable(),
  notIn: z.union([ z.number().array(),z.number() ]).optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const DateTimeNullableFilterSchema: z.ZodType<Prisma.DateTimeNullableFilter> = z.object({
  equals: z.coerce.date().optional().nullable(),
  in: z.union([ z.coerce.date().array(),z.coerce.date() ]).optional().nullable(),
  notIn: z.union([ z.coerce.date().array(),z.coerce.date() ]).optional().nullable(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const IntNullableFilterSchema: z.ZodType<Prisma.IntNullableFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.union([ z.number().array(),z.number() ]).optional().nullable(),
  notIn: z.union([ z.number().array(),z.number() ]).optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const BoolNullableFilterSchema: z.ZodType<Prisma.BoolNullableFilter> = z.object({
  equals: z.boolean().optional().nullable(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const MenusListRelationFilterSchema: z.ZodType<Prisma.MenusListRelationFilter> = z.object({
  every: z.lazy(() => menusWhereInputSchema).optional(),
  some: z.lazy(() => menusWhereInputSchema).optional(),
  none: z.lazy(() => menusWhereInputSchema).optional()
}).strict();

export const PricesListRelationFilterSchema: z.ZodType<Prisma.PricesListRelationFilter> = z.object({
  every: z.lazy(() => pricesWhereInputSchema).optional(),
  some: z.lazy(() => pricesWhereInputSchema).optional(),
  none: z.lazy(() => pricesWhereInputSchema).optional()
}).strict();

export const DrinksListRelationFilterSchema: z.ZodType<Prisma.DrinksListRelationFilter> = z.object({
  every: z.lazy(() => drinksWhereInputSchema).optional(),
  some: z.lazy(() => drinksWhereInputSchema).optional(),
  none: z.lazy(() => drinksWhereInputSchema).optional()
}).strict();

export const Drink_categoriesListRelationFilterSchema: z.ZodType<Prisma.Drink_categoriesListRelationFilter> = z.object({
  every: z.lazy(() => drink_categoriesWhereInputSchema).optional(),
  some: z.lazy(() => drink_categoriesWhereInputSchema).optional(),
  none: z.lazy(() => drink_categoriesWhereInputSchema).optional()
}).strict();

export const ContactListRelationFilterSchema: z.ZodType<Prisma.ContactListRelationFilter> = z.object({
  every: z.lazy(() => contactWhereInputSchema).optional(),
  some: z.lazy(() => contactWhereInputSchema).optional(),
  none: z.lazy(() => contactWhereInputSchema).optional()
}).strict();

export const SortOrderInputSchema: z.ZodType<Prisma.SortOrderInput> = z.object({
  sort: z.lazy(() => SortOrderSchema),
  nulls: z.lazy(() => NullsOrderSchema).optional()
}).strict();

export const menusOrderByRelationAggregateInputSchema: z.ZodType<Prisma.menusOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const pricesOrderByRelationAggregateInputSchema: z.ZodType<Prisma.pricesOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const drinksOrderByRelationAggregateInputSchema: z.ZodType<Prisma.drinksOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const drink_categoriesOrderByRelationAggregateInputSchema: z.ZodType<Prisma.drink_categoriesOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const contactOrderByRelationAggregateInputSchema: z.ZodType<Prisma.contactOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const locationsOrderByRelevanceInputSchema: z.ZodType<Prisma.locationsOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => locationsOrderByRelevanceFieldEnumSchema),z.lazy(() => locationsOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const locationsCountOrderByAggregateInputSchema: z.ZodType<Prisma.locationsCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  meandu_id: z.lazy(() => SortOrderSchema).optional(),
  meandu_id_1: z.lazy(() => SortOrderSchema).optional(),
  meandu_id_2: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  address: z.lazy(() => SortOrderSchema).optional(),
  latitude: z.lazy(() => SortOrderSchema).optional(),
  longitude: z.lazy(() => SortOrderSchema).optional(),
  date_created: z.lazy(() => SortOrderSchema).optional(),
  google_name: z.lazy(() => SortOrderSchema).optional(),
  google_place_id: z.lazy(() => SortOrderSchema).optional(),
  currency: z.lazy(() => SortOrderSchema).optional(),
  locale: z.lazy(() => SortOrderSchema).optional(),
  meandu_menu_url: z.lazy(() => SortOrderSchema).optional(),
  last_updated: z.lazy(() => SortOrderSchema).optional(),
  google_operational: z.lazy(() => SortOrderSchema).optional(),
  validityScore: z.lazy(() => SortOrderSchema).optional(),
  hidden: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const locationsAvgOrderByAggregateInputSchema: z.ZodType<Prisma.locationsAvgOrderByAggregateInput> = z.object({
  latitude: z.lazy(() => SortOrderSchema).optional(),
  longitude: z.lazy(() => SortOrderSchema).optional(),
  validityScore: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const locationsMaxOrderByAggregateInputSchema: z.ZodType<Prisma.locationsMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  meandu_id: z.lazy(() => SortOrderSchema).optional(),
  meandu_id_1: z.lazy(() => SortOrderSchema).optional(),
  meandu_id_2: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  address: z.lazy(() => SortOrderSchema).optional(),
  latitude: z.lazy(() => SortOrderSchema).optional(),
  longitude: z.lazy(() => SortOrderSchema).optional(),
  date_created: z.lazy(() => SortOrderSchema).optional(),
  google_name: z.lazy(() => SortOrderSchema).optional(),
  google_place_id: z.lazy(() => SortOrderSchema).optional(),
  currency: z.lazy(() => SortOrderSchema).optional(),
  locale: z.lazy(() => SortOrderSchema).optional(),
  meandu_menu_url: z.lazy(() => SortOrderSchema).optional(),
  last_updated: z.lazy(() => SortOrderSchema).optional(),
  google_operational: z.lazy(() => SortOrderSchema).optional(),
  validityScore: z.lazy(() => SortOrderSchema).optional(),
  hidden: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const locationsMinOrderByAggregateInputSchema: z.ZodType<Prisma.locationsMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  meandu_id: z.lazy(() => SortOrderSchema).optional(),
  meandu_id_1: z.lazy(() => SortOrderSchema).optional(),
  meandu_id_2: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  address: z.lazy(() => SortOrderSchema).optional(),
  latitude: z.lazy(() => SortOrderSchema).optional(),
  longitude: z.lazy(() => SortOrderSchema).optional(),
  date_created: z.lazy(() => SortOrderSchema).optional(),
  google_name: z.lazy(() => SortOrderSchema).optional(),
  google_place_id: z.lazy(() => SortOrderSchema).optional(),
  currency: z.lazy(() => SortOrderSchema).optional(),
  locale: z.lazy(() => SortOrderSchema).optional(),
  meandu_menu_url: z.lazy(() => SortOrderSchema).optional(),
  last_updated: z.lazy(() => SortOrderSchema).optional(),
  google_operational: z.lazy(() => SortOrderSchema).optional(),
  validityScore: z.lazy(() => SortOrderSchema).optional(),
  hidden: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const locationsSumOrderByAggregateInputSchema: z.ZodType<Prisma.locationsSumOrderByAggregateInput> = z.object({
  latitude: z.lazy(() => SortOrderSchema).optional(),
  longitude: z.lazy(() => SortOrderSchema).optional(),
  validityScore: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const StringWithAggregatesFilterSchema: z.ZodType<Prisma.StringWithAggregatesFilter> = z.object({
  equals: z.string().optional(),
  in: z.union([ z.string().array(),z.string() ]).optional(),
  notIn: z.union([ z.string().array(),z.string() ]).optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  search: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedStringFilterSchema).optional(),
  _max: z.lazy(() => NestedStringFilterSchema).optional()
}).strict();

export const DateTimeWithAggregatesFilterSchema: z.ZodType<Prisma.DateTimeWithAggregatesFilter> = z.object({
  equals: z.coerce.date().optional(),
  in: z.union([ z.coerce.date().array(),z.coerce.date() ]).optional(),
  notIn: z.union([ z.coerce.date().array(),z.coerce.date() ]).optional(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedDateTimeFilterSchema).optional(),
  _max: z.lazy(() => NestedDateTimeFilterSchema).optional()
}).strict();

export const StringNullableWithAggregatesFilterSchema: z.ZodType<Prisma.StringNullableWithAggregatesFilter> = z.object({
  equals: z.string().optional().nullable(),
  in: z.union([ z.string().array(),z.string() ]).optional().nullable(),
  notIn: z.union([ z.string().array(),z.string() ]).optional().nullable(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  search: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedStringNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedStringNullableFilterSchema).optional()
}).strict();

export const FloatNullableWithAggregatesFilterSchema: z.ZodType<Prisma.FloatNullableWithAggregatesFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.union([ z.number().array(),z.number() ]).optional().nullable(),
  notIn: z.union([ z.number().array(),z.number() ]).optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _sum: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedFloatNullableFilterSchema).optional()
}).strict();

export const DateTimeNullableWithAggregatesFilterSchema: z.ZodType<Prisma.DateTimeNullableWithAggregatesFilter> = z.object({
  equals: z.coerce.date().optional().nullable(),
  in: z.union([ z.coerce.date().array(),z.coerce.date() ]).optional().nullable(),
  notIn: z.union([ z.coerce.date().array(),z.coerce.date() ]).optional().nullable(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedDateTimeNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedDateTimeNullableFilterSchema).optional()
}).strict();

export const IntNullableWithAggregatesFilterSchema: z.ZodType<Prisma.IntNullableWithAggregatesFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.union([ z.number().array(),z.number() ]).optional().nullable(),
  notIn: z.union([ z.number().array(),z.number() ]).optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _sum: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedIntNullableFilterSchema).optional()
}).strict();

export const BoolNullableWithAggregatesFilterSchema: z.ZodType<Prisma.BoolNullableWithAggregatesFilter> = z.object({
  equals: z.boolean().optional().nullable(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedBoolNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedBoolNullableFilterSchema).optional()
}).strict();

export const LocationsRelationFilterSchema: z.ZodType<Prisma.LocationsRelationFilter> = z.object({
  is: z.lazy(() => locationsWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => locationsWhereInputSchema).optional().nullable()
}).strict();

export const Drinks_to_drink_categoriesListRelationFilterSchema: z.ZodType<Prisma.Drinks_to_drink_categoriesListRelationFilter> = z.object({
  every: z.lazy(() => drinks_to_drink_categoriesWhereInputSchema).optional(),
  some: z.lazy(() => drinks_to_drink_categoriesWhereInputSchema).optional(),
  none: z.lazy(() => drinks_to_drink_categoriesWhereInputSchema).optional()
}).strict();

export const drinks_to_drink_categoriesOrderByRelationAggregateInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const drink_categoriesOrderByRelevanceInputSchema: z.ZodType<Prisma.drink_categoriesOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => drink_categoriesOrderByRelevanceFieldEnumSchema),z.lazy(() => drink_categoriesOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const drink_categoriesLocationsIdMeandu_idCompoundUniqueInputSchema: z.ZodType<Prisma.drink_categoriesLocationsIdMeandu_idCompoundUniqueInput> = z.object({
  locationsId: z.string(),
  meandu_id: z.string()
}).strict();

export const drink_categoriesCountOrderByAggregateInputSchema: z.ZodType<Prisma.drink_categoriesCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  meandu_id: z.lazy(() => SortOrderSchema).optional(),
  locationsId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const drink_categoriesMaxOrderByAggregateInputSchema: z.ZodType<Prisma.drink_categoriesMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  meandu_id: z.lazy(() => SortOrderSchema).optional(),
  locationsId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const drink_categoriesMinOrderByAggregateInputSchema: z.ZodType<Prisma.drink_categoriesMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  meandu_id: z.lazy(() => SortOrderSchema).optional(),
  locationsId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const drinksOrderByRelevanceInputSchema: z.ZodType<Prisma.drinksOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => drinksOrderByRelevanceFieldEnumSchema),z.lazy(() => drinksOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const drinksLocationsIdMeandu_idCompoundUniqueInputSchema: z.ZodType<Prisma.drinksLocationsIdMeandu_idCompoundUniqueInput> = z.object({
  locationsId: z.string(),
  meandu_id: z.string()
}).strict();

export const drinksCountOrderByAggregateInputSchema: z.ZodType<Prisma.drinksCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  locationsId: z.lazy(() => SortOrderSchema).optional(),
  meandu_id: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const drinksMaxOrderByAggregateInputSchema: z.ZodType<Prisma.drinksMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  locationsId: z.lazy(() => SortOrderSchema).optional(),
  meandu_id: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const drinksMinOrderByAggregateInputSchema: z.ZodType<Prisma.drinksMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  locationsId: z.lazy(() => SortOrderSchema).optional(),
  meandu_id: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const DrinksRelationFilterSchema: z.ZodType<Prisma.DrinksRelationFilter> = z.object({
  is: z.lazy(() => drinksWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => drinksWhereInputSchema).optional().nullable()
}).strict();

export const Drink_categoriesRelationFilterSchema: z.ZodType<Prisma.Drink_categoriesRelationFilter> = z.object({
  is: z.lazy(() => drink_categoriesWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => drink_categoriesWhereInputSchema).optional().nullable()
}).strict();

export const drinks_to_drink_categoriesOrderByRelevanceInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => drinks_to_drink_categoriesOrderByRelevanceFieldEnumSchema),z.lazy(() => drinks_to_drink_categoriesOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const drinks_to_drink_categoriesDrink_idDrink_category_idCompoundUniqueInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesDrink_idDrink_category_idCompoundUniqueInput> = z.object({
  drink_id: z.string(),
  drink_category_id: z.string()
}).strict();

export const drinks_to_drink_categoriesCountOrderByAggregateInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesCountOrderByAggregateInput> = z.object({
  drink_id: z.lazy(() => SortOrderSchema).optional(),
  drink_category_id: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const drinks_to_drink_categoriesMaxOrderByAggregateInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesMaxOrderByAggregateInput> = z.object({
  drink_id: z.lazy(() => SortOrderSchema).optional(),
  drink_category_id: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const drinks_to_drink_categoriesMinOrderByAggregateInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesMinOrderByAggregateInput> = z.object({
  drink_id: z.lazy(() => SortOrderSchema).optional(),
  drink_category_id: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const MenusRelationFilterSchema: z.ZodType<Prisma.MenusRelationFilter> = z.object({
  is: z.lazy(() => menusWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => menusWhereInputSchema).optional().nullable()
}).strict();

export const pricesOrderByRelevanceInputSchema: z.ZodType<Prisma.pricesOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => pricesOrderByRelevanceFieldEnumSchema),z.lazy(() => pricesOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const pricesCountOrderByAggregateInputSchema: z.ZodType<Prisma.pricesCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  menusId: z.lazy(() => SortOrderSchema).optional(),
  locationsId: z.lazy(() => SortOrderSchema).optional(),
  price: z.lazy(() => SortOrderSchema).optional(),
  drink_id: z.lazy(() => SortOrderSchema).optional(),
  drink_variant_name: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const pricesAvgOrderByAggregateInputSchema: z.ZodType<Prisma.pricesAvgOrderByAggregateInput> = z.object({
  price: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const pricesMaxOrderByAggregateInputSchema: z.ZodType<Prisma.pricesMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  menusId: z.lazy(() => SortOrderSchema).optional(),
  locationsId: z.lazy(() => SortOrderSchema).optional(),
  price: z.lazy(() => SortOrderSchema).optional(),
  drink_id: z.lazy(() => SortOrderSchema).optional(),
  drink_variant_name: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const pricesMinOrderByAggregateInputSchema: z.ZodType<Prisma.pricesMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  menusId: z.lazy(() => SortOrderSchema).optional(),
  locationsId: z.lazy(() => SortOrderSchema).optional(),
  price: z.lazy(() => SortOrderSchema).optional(),
  drink_id: z.lazy(() => SortOrderSchema).optional(),
  drink_variant_name: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const pricesSumOrderByAggregateInputSchema: z.ZodType<Prisma.pricesSumOrderByAggregateInput> = z.object({
  price: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const menusOrderByRelevanceInputSchema: z.ZodType<Prisma.menusOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => menusOrderByRelevanceFieldEnumSchema),z.lazy(() => menusOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const menusLocationsIdMeandu_idCompoundUniqueInputSchema: z.ZodType<Prisma.menusLocationsIdMeandu_idCompoundUniqueInput> = z.object({
  locationsId: z.string(),
  meandu_id: z.string()
}).strict();

export const menusCountOrderByAggregateInputSchema: z.ZodType<Prisma.menusCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  isDiscounted: z.lazy(() => SortOrderSchema).optional(),
  locationsId: z.lazy(() => SortOrderSchema).optional(),
  daysOfWeek: z.lazy(() => SortOrderSchema).optional(),
  timeStart: z.lazy(() => SortOrderSchema).optional(),
  timeEnd: z.lazy(() => SortOrderSchema).optional(),
  meandu_id: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const menusAvgOrderByAggregateInputSchema: z.ZodType<Prisma.menusAvgOrderByAggregateInput> = z.object({
  daysOfWeek: z.lazy(() => SortOrderSchema).optional(),
  timeStart: z.lazy(() => SortOrderSchema).optional(),
  timeEnd: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const menusMaxOrderByAggregateInputSchema: z.ZodType<Prisma.menusMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  isDiscounted: z.lazy(() => SortOrderSchema).optional(),
  locationsId: z.lazy(() => SortOrderSchema).optional(),
  daysOfWeek: z.lazy(() => SortOrderSchema).optional(),
  timeStart: z.lazy(() => SortOrderSchema).optional(),
  timeEnd: z.lazy(() => SortOrderSchema).optional(),
  meandu_id: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const menusMinOrderByAggregateInputSchema: z.ZodType<Prisma.menusMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  isDiscounted: z.lazy(() => SortOrderSchema).optional(),
  locationsId: z.lazy(() => SortOrderSchema).optional(),
  daysOfWeek: z.lazy(() => SortOrderSchema).optional(),
  timeStart: z.lazy(() => SortOrderSchema).optional(),
  timeEnd: z.lazy(() => SortOrderSchema).optional(),
  meandu_id: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const menusSumOrderByAggregateInputSchema: z.ZodType<Prisma.menusSumOrderByAggregateInput> = z.object({
  daysOfWeek: z.lazy(() => SortOrderSchema).optional(),
  timeStart: z.lazy(() => SortOrderSchema).optional(),
  timeEnd: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const UserRelationFilterSchema: z.ZodType<Prisma.UserRelationFilter> = z.object({
  is: z.lazy(() => UserWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => UserWhereInputSchema).optional().nullable()
}).strict();

export const AccountOrderByRelevanceInputSchema: z.ZodType<Prisma.AccountOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => AccountOrderByRelevanceFieldEnumSchema),z.lazy(() => AccountOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const AccountProviderProviderAccountIdCompoundUniqueInputSchema: z.ZodType<Prisma.AccountProviderProviderAccountIdCompoundUniqueInput> = z.object({
  provider: z.string(),
  providerAccountId: z.string()
}).strict();

export const AccountCountOrderByAggregateInputSchema: z.ZodType<Prisma.AccountCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  provider: z.lazy(() => SortOrderSchema).optional(),
  providerAccountId: z.lazy(() => SortOrderSchema).optional(),
  refresh_token: z.lazy(() => SortOrderSchema).optional(),
  access_token: z.lazy(() => SortOrderSchema).optional(),
  expires_at: z.lazy(() => SortOrderSchema).optional(),
  token_type: z.lazy(() => SortOrderSchema).optional(),
  scope: z.lazy(() => SortOrderSchema).optional(),
  id_token: z.lazy(() => SortOrderSchema).optional(),
  session_state: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AccountAvgOrderByAggregateInputSchema: z.ZodType<Prisma.AccountAvgOrderByAggregateInput> = z.object({
  expires_at: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AccountMaxOrderByAggregateInputSchema: z.ZodType<Prisma.AccountMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  provider: z.lazy(() => SortOrderSchema).optional(),
  providerAccountId: z.lazy(() => SortOrderSchema).optional(),
  refresh_token: z.lazy(() => SortOrderSchema).optional(),
  access_token: z.lazy(() => SortOrderSchema).optional(),
  expires_at: z.lazy(() => SortOrderSchema).optional(),
  token_type: z.lazy(() => SortOrderSchema).optional(),
  scope: z.lazy(() => SortOrderSchema).optional(),
  id_token: z.lazy(() => SortOrderSchema).optional(),
  session_state: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AccountMinOrderByAggregateInputSchema: z.ZodType<Prisma.AccountMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  provider: z.lazy(() => SortOrderSchema).optional(),
  providerAccountId: z.lazy(() => SortOrderSchema).optional(),
  refresh_token: z.lazy(() => SortOrderSchema).optional(),
  access_token: z.lazy(() => SortOrderSchema).optional(),
  expires_at: z.lazy(() => SortOrderSchema).optional(),
  token_type: z.lazy(() => SortOrderSchema).optional(),
  scope: z.lazy(() => SortOrderSchema).optional(),
  id_token: z.lazy(() => SortOrderSchema).optional(),
  session_state: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AccountSumOrderByAggregateInputSchema: z.ZodType<Prisma.AccountSumOrderByAggregateInput> = z.object({
  expires_at: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SessionOrderByRelevanceInputSchema: z.ZodType<Prisma.SessionOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => SessionOrderByRelevanceFieldEnumSchema),z.lazy(() => SessionOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const SessionCountOrderByAggregateInputSchema: z.ZodType<Prisma.SessionCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  sessionToken: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SessionMaxOrderByAggregateInputSchema: z.ZodType<Prisma.SessionMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  sessionToken: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SessionMinOrderByAggregateInputSchema: z.ZodType<Prisma.SessionMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  sessionToken: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AccountListRelationFilterSchema: z.ZodType<Prisma.AccountListRelationFilter> = z.object({
  every: z.lazy(() => AccountWhereInputSchema).optional(),
  some: z.lazy(() => AccountWhereInputSchema).optional(),
  none: z.lazy(() => AccountWhereInputSchema).optional()
}).strict();

export const SessionListRelationFilterSchema: z.ZodType<Prisma.SessionListRelationFilter> = z.object({
  every: z.lazy(() => SessionWhereInputSchema).optional(),
  some: z.lazy(() => SessionWhereInputSchema).optional(),
  none: z.lazy(() => SessionWhereInputSchema).optional()
}).strict();

export const AccountOrderByRelationAggregateInputSchema: z.ZodType<Prisma.AccountOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SessionOrderByRelationAggregateInputSchema: z.ZodType<Prisma.SessionOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const UserOrderByRelevanceInputSchema: z.ZodType<Prisma.UserOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => UserOrderByRelevanceFieldEnumSchema),z.lazy(() => UserOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const UserCountOrderByAggregateInputSchema: z.ZodType<Prisma.UserCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  emailVerified: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional(),
  apiKey: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const UserMaxOrderByAggregateInputSchema: z.ZodType<Prisma.UserMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  emailVerified: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional(),
  apiKey: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const UserMinOrderByAggregateInputSchema: z.ZodType<Prisma.UserMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  emailVerified: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional(),
  apiKey: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const VerificationTokenOrderByRelevanceInputSchema: z.ZodType<Prisma.VerificationTokenOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => VerificationTokenOrderByRelevanceFieldEnumSchema),z.lazy(() => VerificationTokenOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const VerificationTokenIdentifierTokenCompoundUniqueInputSchema: z.ZodType<Prisma.VerificationTokenIdentifierTokenCompoundUniqueInput> = z.object({
  identifier: z.string(),
  token: z.string()
}).strict();

export const VerificationTokenCountOrderByAggregateInputSchema: z.ZodType<Prisma.VerificationTokenCountOrderByAggregateInput> = z.object({
  identifier: z.lazy(() => SortOrderSchema).optional(),
  token: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const VerificationTokenMaxOrderByAggregateInputSchema: z.ZodType<Prisma.VerificationTokenMaxOrderByAggregateInput> = z.object({
  identifier: z.lazy(() => SortOrderSchema).optional(),
  token: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const VerificationTokenMinOrderByAggregateInputSchema: z.ZodType<Prisma.VerificationTokenMinOrderByAggregateInput> = z.object({
  identifier: z.lazy(() => SortOrderSchema).optional(),
  token: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const contactOrderByRelevanceInputSchema: z.ZodType<Prisma.contactOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => contactOrderByRelevanceFieldEnumSchema),z.lazy(() => contactOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const contactCountOrderByAggregateInputSchema: z.ZodType<Prisma.contactCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  message: z.lazy(() => SortOrderSchema).optional(),
  locationsId: z.lazy(() => SortOrderSchema).optional(),
  x: z.lazy(() => SortOrderSchema).optional(),
  linkedin: z.lazy(() => SortOrderSchema).optional(),
  instagram: z.lazy(() => SortOrderSchema).optional(),
  facebook: z.lazy(() => SortOrderSchema).optional(),
  website: z.lazy(() => SortOrderSchema).optional(),
  phone: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const contactMaxOrderByAggregateInputSchema: z.ZodType<Prisma.contactMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  message: z.lazy(() => SortOrderSchema).optional(),
  locationsId: z.lazy(() => SortOrderSchema).optional(),
  x: z.lazy(() => SortOrderSchema).optional(),
  linkedin: z.lazy(() => SortOrderSchema).optional(),
  instagram: z.lazy(() => SortOrderSchema).optional(),
  facebook: z.lazy(() => SortOrderSchema).optional(),
  website: z.lazy(() => SortOrderSchema).optional(),
  phone: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const contactMinOrderByAggregateInputSchema: z.ZodType<Prisma.contactMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  message: z.lazy(() => SortOrderSchema).optional(),
  locationsId: z.lazy(() => SortOrderSchema).optional(),
  x: z.lazy(() => SortOrderSchema).optional(),
  linkedin: z.lazy(() => SortOrderSchema).optional(),
  instagram: z.lazy(() => SortOrderSchema).optional(),
  facebook: z.lazy(() => SortOrderSchema).optional(),
  website: z.lazy(() => SortOrderSchema).optional(),
  phone: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const menusCreateNestedManyWithoutLocationsInputSchema: z.ZodType<Prisma.menusCreateNestedManyWithoutLocationsInput> = z.object({
  create: z.union([ z.lazy(() => menusCreateWithoutLocationsInputSchema),z.lazy(() => menusCreateWithoutLocationsInputSchema).array(),z.lazy(() => menusUncheckedCreateWithoutLocationsInputSchema),z.lazy(() => menusUncheckedCreateWithoutLocationsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => menusCreateOrConnectWithoutLocationsInputSchema),z.lazy(() => menusCreateOrConnectWithoutLocationsInputSchema).array() ]).optional(),
  createMany: z.lazy(() => menusCreateManyLocationsInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => menusWhereUniqueInputSchema),z.lazy(() => menusWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const pricesCreateNestedManyWithoutLocationsInputSchema: z.ZodType<Prisma.pricesCreateNestedManyWithoutLocationsInput> = z.object({
  create: z.union([ z.lazy(() => pricesCreateWithoutLocationsInputSchema),z.lazy(() => pricesCreateWithoutLocationsInputSchema).array(),z.lazy(() => pricesUncheckedCreateWithoutLocationsInputSchema),z.lazy(() => pricesUncheckedCreateWithoutLocationsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => pricesCreateOrConnectWithoutLocationsInputSchema),z.lazy(() => pricesCreateOrConnectWithoutLocationsInputSchema).array() ]).optional(),
  createMany: z.lazy(() => pricesCreateManyLocationsInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const drinksCreateNestedManyWithoutLocationsInputSchema: z.ZodType<Prisma.drinksCreateNestedManyWithoutLocationsInput> = z.object({
  create: z.union([ z.lazy(() => drinksCreateWithoutLocationsInputSchema),z.lazy(() => drinksCreateWithoutLocationsInputSchema).array(),z.lazy(() => drinksUncheckedCreateWithoutLocationsInputSchema),z.lazy(() => drinksUncheckedCreateWithoutLocationsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => drinksCreateOrConnectWithoutLocationsInputSchema),z.lazy(() => drinksCreateOrConnectWithoutLocationsInputSchema).array() ]).optional(),
  createMany: z.lazy(() => drinksCreateManyLocationsInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => drinksWhereUniqueInputSchema),z.lazy(() => drinksWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const drink_categoriesCreateNestedManyWithoutLocationsInputSchema: z.ZodType<Prisma.drink_categoriesCreateNestedManyWithoutLocationsInput> = z.object({
  create: z.union([ z.lazy(() => drink_categoriesCreateWithoutLocationsInputSchema),z.lazy(() => drink_categoriesCreateWithoutLocationsInputSchema).array(),z.lazy(() => drink_categoriesUncheckedCreateWithoutLocationsInputSchema),z.lazy(() => drink_categoriesUncheckedCreateWithoutLocationsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => drink_categoriesCreateOrConnectWithoutLocationsInputSchema),z.lazy(() => drink_categoriesCreateOrConnectWithoutLocationsInputSchema).array() ]).optional(),
  createMany: z.lazy(() => drink_categoriesCreateManyLocationsInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => drink_categoriesWhereUniqueInputSchema),z.lazy(() => drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const contactCreateNestedManyWithoutLocationsInputSchema: z.ZodType<Prisma.contactCreateNestedManyWithoutLocationsInput> = z.object({
  create: z.union([ z.lazy(() => contactCreateWithoutLocationsInputSchema),z.lazy(() => contactCreateWithoutLocationsInputSchema).array(),z.lazy(() => contactUncheckedCreateWithoutLocationsInputSchema),z.lazy(() => contactUncheckedCreateWithoutLocationsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => contactCreateOrConnectWithoutLocationsInputSchema),z.lazy(() => contactCreateOrConnectWithoutLocationsInputSchema).array() ]).optional(),
  createMany: z.lazy(() => contactCreateManyLocationsInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => contactWhereUniqueInputSchema),z.lazy(() => contactWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const menusUncheckedCreateNestedManyWithoutLocationsInputSchema: z.ZodType<Prisma.menusUncheckedCreateNestedManyWithoutLocationsInput> = z.object({
  create: z.union([ z.lazy(() => menusCreateWithoutLocationsInputSchema),z.lazy(() => menusCreateWithoutLocationsInputSchema).array(),z.lazy(() => menusUncheckedCreateWithoutLocationsInputSchema),z.lazy(() => menusUncheckedCreateWithoutLocationsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => menusCreateOrConnectWithoutLocationsInputSchema),z.lazy(() => menusCreateOrConnectWithoutLocationsInputSchema).array() ]).optional(),
  createMany: z.lazy(() => menusCreateManyLocationsInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => menusWhereUniqueInputSchema),z.lazy(() => menusWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const pricesUncheckedCreateNestedManyWithoutLocationsInputSchema: z.ZodType<Prisma.pricesUncheckedCreateNestedManyWithoutLocationsInput> = z.object({
  create: z.union([ z.lazy(() => pricesCreateWithoutLocationsInputSchema),z.lazy(() => pricesCreateWithoutLocationsInputSchema).array(),z.lazy(() => pricesUncheckedCreateWithoutLocationsInputSchema),z.lazy(() => pricesUncheckedCreateWithoutLocationsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => pricesCreateOrConnectWithoutLocationsInputSchema),z.lazy(() => pricesCreateOrConnectWithoutLocationsInputSchema).array() ]).optional(),
  createMany: z.lazy(() => pricesCreateManyLocationsInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const drinksUncheckedCreateNestedManyWithoutLocationsInputSchema: z.ZodType<Prisma.drinksUncheckedCreateNestedManyWithoutLocationsInput> = z.object({
  create: z.union([ z.lazy(() => drinksCreateWithoutLocationsInputSchema),z.lazy(() => drinksCreateWithoutLocationsInputSchema).array(),z.lazy(() => drinksUncheckedCreateWithoutLocationsInputSchema),z.lazy(() => drinksUncheckedCreateWithoutLocationsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => drinksCreateOrConnectWithoutLocationsInputSchema),z.lazy(() => drinksCreateOrConnectWithoutLocationsInputSchema).array() ]).optional(),
  createMany: z.lazy(() => drinksCreateManyLocationsInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => drinksWhereUniqueInputSchema),z.lazy(() => drinksWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const drink_categoriesUncheckedCreateNestedManyWithoutLocationsInputSchema: z.ZodType<Prisma.drink_categoriesUncheckedCreateNestedManyWithoutLocationsInput> = z.object({
  create: z.union([ z.lazy(() => drink_categoriesCreateWithoutLocationsInputSchema),z.lazy(() => drink_categoriesCreateWithoutLocationsInputSchema).array(),z.lazy(() => drink_categoriesUncheckedCreateWithoutLocationsInputSchema),z.lazy(() => drink_categoriesUncheckedCreateWithoutLocationsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => drink_categoriesCreateOrConnectWithoutLocationsInputSchema),z.lazy(() => drink_categoriesCreateOrConnectWithoutLocationsInputSchema).array() ]).optional(),
  createMany: z.lazy(() => drink_categoriesCreateManyLocationsInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => drink_categoriesWhereUniqueInputSchema),z.lazy(() => drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const contactUncheckedCreateNestedManyWithoutLocationsInputSchema: z.ZodType<Prisma.contactUncheckedCreateNestedManyWithoutLocationsInput> = z.object({
  create: z.union([ z.lazy(() => contactCreateWithoutLocationsInputSchema),z.lazy(() => contactCreateWithoutLocationsInputSchema).array(),z.lazy(() => contactUncheckedCreateWithoutLocationsInputSchema),z.lazy(() => contactUncheckedCreateWithoutLocationsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => contactCreateOrConnectWithoutLocationsInputSchema),z.lazy(() => contactCreateOrConnectWithoutLocationsInputSchema).array() ]).optional(),
  createMany: z.lazy(() => contactCreateManyLocationsInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => contactWhereUniqueInputSchema),z.lazy(() => contactWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const StringFieldUpdateOperationsInputSchema: z.ZodType<Prisma.StringFieldUpdateOperationsInput> = z.object({
  set: z.string().optional()
}).strict();

export const DateTimeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.DateTimeFieldUpdateOperationsInput> = z.object({
  set: z.coerce.date().optional()
}).strict();

export const NullableStringFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableStringFieldUpdateOperationsInput> = z.object({
  set: z.string().optional().nullable()
}).strict();

export const NullableFloatFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableFloatFieldUpdateOperationsInput> = z.object({
  set: z.number().optional().nullable(),
  increment: z.number().optional(),
  decrement: z.number().optional(),
  multiply: z.number().optional(),
  divide: z.number().optional()
}).strict();

export const NullableDateTimeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableDateTimeFieldUpdateOperationsInput> = z.object({
  set: z.coerce.date().optional().nullable()
}).strict();

export const NullableIntFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableIntFieldUpdateOperationsInput> = z.object({
  set: z.number().optional().nullable(),
  increment: z.number().optional(),
  decrement: z.number().optional(),
  multiply: z.number().optional(),
  divide: z.number().optional()
}).strict();

export const NullableBoolFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableBoolFieldUpdateOperationsInput> = z.object({
  set: z.boolean().optional().nullable()
}).strict();

export const menusUpdateManyWithoutLocationsNestedInputSchema: z.ZodType<Prisma.menusUpdateManyWithoutLocationsNestedInput> = z.object({
  create: z.union([ z.lazy(() => menusCreateWithoutLocationsInputSchema),z.lazy(() => menusCreateWithoutLocationsInputSchema).array(),z.lazy(() => menusUncheckedCreateWithoutLocationsInputSchema),z.lazy(() => menusUncheckedCreateWithoutLocationsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => menusCreateOrConnectWithoutLocationsInputSchema),z.lazy(() => menusCreateOrConnectWithoutLocationsInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => menusUpsertWithWhereUniqueWithoutLocationsInputSchema),z.lazy(() => menusUpsertWithWhereUniqueWithoutLocationsInputSchema).array() ]).optional(),
  createMany: z.lazy(() => menusCreateManyLocationsInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => menusWhereUniqueInputSchema),z.lazy(() => menusWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => menusWhereUniqueInputSchema),z.lazy(() => menusWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => menusWhereUniqueInputSchema),z.lazy(() => menusWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => menusWhereUniqueInputSchema),z.lazy(() => menusWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => menusUpdateWithWhereUniqueWithoutLocationsInputSchema),z.lazy(() => menusUpdateWithWhereUniqueWithoutLocationsInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => menusUpdateManyWithWhereWithoutLocationsInputSchema),z.lazy(() => menusUpdateManyWithWhereWithoutLocationsInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => menusScalarWhereInputSchema),z.lazy(() => menusScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const pricesUpdateManyWithoutLocationsNestedInputSchema: z.ZodType<Prisma.pricesUpdateManyWithoutLocationsNestedInput> = z.object({
  create: z.union([ z.lazy(() => pricesCreateWithoutLocationsInputSchema),z.lazy(() => pricesCreateWithoutLocationsInputSchema).array(),z.lazy(() => pricesUncheckedCreateWithoutLocationsInputSchema),z.lazy(() => pricesUncheckedCreateWithoutLocationsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => pricesCreateOrConnectWithoutLocationsInputSchema),z.lazy(() => pricesCreateOrConnectWithoutLocationsInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => pricesUpsertWithWhereUniqueWithoutLocationsInputSchema),z.lazy(() => pricesUpsertWithWhereUniqueWithoutLocationsInputSchema).array() ]).optional(),
  createMany: z.lazy(() => pricesCreateManyLocationsInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => pricesUpdateWithWhereUniqueWithoutLocationsInputSchema),z.lazy(() => pricesUpdateWithWhereUniqueWithoutLocationsInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => pricesUpdateManyWithWhereWithoutLocationsInputSchema),z.lazy(() => pricesUpdateManyWithWhereWithoutLocationsInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => pricesScalarWhereInputSchema),z.lazy(() => pricesScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const drinksUpdateManyWithoutLocationsNestedInputSchema: z.ZodType<Prisma.drinksUpdateManyWithoutLocationsNestedInput> = z.object({
  create: z.union([ z.lazy(() => drinksCreateWithoutLocationsInputSchema),z.lazy(() => drinksCreateWithoutLocationsInputSchema).array(),z.lazy(() => drinksUncheckedCreateWithoutLocationsInputSchema),z.lazy(() => drinksUncheckedCreateWithoutLocationsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => drinksCreateOrConnectWithoutLocationsInputSchema),z.lazy(() => drinksCreateOrConnectWithoutLocationsInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => drinksUpsertWithWhereUniqueWithoutLocationsInputSchema),z.lazy(() => drinksUpsertWithWhereUniqueWithoutLocationsInputSchema).array() ]).optional(),
  createMany: z.lazy(() => drinksCreateManyLocationsInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => drinksWhereUniqueInputSchema),z.lazy(() => drinksWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => drinksWhereUniqueInputSchema),z.lazy(() => drinksWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => drinksWhereUniqueInputSchema),z.lazy(() => drinksWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => drinksWhereUniqueInputSchema),z.lazy(() => drinksWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => drinksUpdateWithWhereUniqueWithoutLocationsInputSchema),z.lazy(() => drinksUpdateWithWhereUniqueWithoutLocationsInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => drinksUpdateManyWithWhereWithoutLocationsInputSchema),z.lazy(() => drinksUpdateManyWithWhereWithoutLocationsInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => drinksScalarWhereInputSchema),z.lazy(() => drinksScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const drink_categoriesUpdateManyWithoutLocationsNestedInputSchema: z.ZodType<Prisma.drink_categoriesUpdateManyWithoutLocationsNestedInput> = z.object({
  create: z.union([ z.lazy(() => drink_categoriesCreateWithoutLocationsInputSchema),z.lazy(() => drink_categoriesCreateWithoutLocationsInputSchema).array(),z.lazy(() => drink_categoriesUncheckedCreateWithoutLocationsInputSchema),z.lazy(() => drink_categoriesUncheckedCreateWithoutLocationsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => drink_categoriesCreateOrConnectWithoutLocationsInputSchema),z.lazy(() => drink_categoriesCreateOrConnectWithoutLocationsInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => drink_categoriesUpsertWithWhereUniqueWithoutLocationsInputSchema),z.lazy(() => drink_categoriesUpsertWithWhereUniqueWithoutLocationsInputSchema).array() ]).optional(),
  createMany: z.lazy(() => drink_categoriesCreateManyLocationsInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => drink_categoriesWhereUniqueInputSchema),z.lazy(() => drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => drink_categoriesWhereUniqueInputSchema),z.lazy(() => drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => drink_categoriesWhereUniqueInputSchema),z.lazy(() => drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => drink_categoriesWhereUniqueInputSchema),z.lazy(() => drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => drink_categoriesUpdateWithWhereUniqueWithoutLocationsInputSchema),z.lazy(() => drink_categoriesUpdateWithWhereUniqueWithoutLocationsInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => drink_categoriesUpdateManyWithWhereWithoutLocationsInputSchema),z.lazy(() => drink_categoriesUpdateManyWithWhereWithoutLocationsInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => drink_categoriesScalarWhereInputSchema),z.lazy(() => drink_categoriesScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const contactUpdateManyWithoutLocationsNestedInputSchema: z.ZodType<Prisma.contactUpdateManyWithoutLocationsNestedInput> = z.object({
  create: z.union([ z.lazy(() => contactCreateWithoutLocationsInputSchema),z.lazy(() => contactCreateWithoutLocationsInputSchema).array(),z.lazy(() => contactUncheckedCreateWithoutLocationsInputSchema),z.lazy(() => contactUncheckedCreateWithoutLocationsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => contactCreateOrConnectWithoutLocationsInputSchema),z.lazy(() => contactCreateOrConnectWithoutLocationsInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => contactUpsertWithWhereUniqueWithoutLocationsInputSchema),z.lazy(() => contactUpsertWithWhereUniqueWithoutLocationsInputSchema).array() ]).optional(),
  createMany: z.lazy(() => contactCreateManyLocationsInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => contactWhereUniqueInputSchema),z.lazy(() => contactWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => contactWhereUniqueInputSchema),z.lazy(() => contactWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => contactWhereUniqueInputSchema),z.lazy(() => contactWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => contactWhereUniqueInputSchema),z.lazy(() => contactWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => contactUpdateWithWhereUniqueWithoutLocationsInputSchema),z.lazy(() => contactUpdateWithWhereUniqueWithoutLocationsInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => contactUpdateManyWithWhereWithoutLocationsInputSchema),z.lazy(() => contactUpdateManyWithWhereWithoutLocationsInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => contactScalarWhereInputSchema),z.lazy(() => contactScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const menusUncheckedUpdateManyWithoutLocationsNestedInputSchema: z.ZodType<Prisma.menusUncheckedUpdateManyWithoutLocationsNestedInput> = z.object({
  create: z.union([ z.lazy(() => menusCreateWithoutLocationsInputSchema),z.lazy(() => menusCreateWithoutLocationsInputSchema).array(),z.lazy(() => menusUncheckedCreateWithoutLocationsInputSchema),z.lazy(() => menusUncheckedCreateWithoutLocationsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => menusCreateOrConnectWithoutLocationsInputSchema),z.lazy(() => menusCreateOrConnectWithoutLocationsInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => menusUpsertWithWhereUniqueWithoutLocationsInputSchema),z.lazy(() => menusUpsertWithWhereUniqueWithoutLocationsInputSchema).array() ]).optional(),
  createMany: z.lazy(() => menusCreateManyLocationsInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => menusWhereUniqueInputSchema),z.lazy(() => menusWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => menusWhereUniqueInputSchema),z.lazy(() => menusWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => menusWhereUniqueInputSchema),z.lazy(() => menusWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => menusWhereUniqueInputSchema),z.lazy(() => menusWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => menusUpdateWithWhereUniqueWithoutLocationsInputSchema),z.lazy(() => menusUpdateWithWhereUniqueWithoutLocationsInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => menusUpdateManyWithWhereWithoutLocationsInputSchema),z.lazy(() => menusUpdateManyWithWhereWithoutLocationsInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => menusScalarWhereInputSchema),z.lazy(() => menusScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const pricesUncheckedUpdateManyWithoutLocationsNestedInputSchema: z.ZodType<Prisma.pricesUncheckedUpdateManyWithoutLocationsNestedInput> = z.object({
  create: z.union([ z.lazy(() => pricesCreateWithoutLocationsInputSchema),z.lazy(() => pricesCreateWithoutLocationsInputSchema).array(),z.lazy(() => pricesUncheckedCreateWithoutLocationsInputSchema),z.lazy(() => pricesUncheckedCreateWithoutLocationsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => pricesCreateOrConnectWithoutLocationsInputSchema),z.lazy(() => pricesCreateOrConnectWithoutLocationsInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => pricesUpsertWithWhereUniqueWithoutLocationsInputSchema),z.lazy(() => pricesUpsertWithWhereUniqueWithoutLocationsInputSchema).array() ]).optional(),
  createMany: z.lazy(() => pricesCreateManyLocationsInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => pricesUpdateWithWhereUniqueWithoutLocationsInputSchema),z.lazy(() => pricesUpdateWithWhereUniqueWithoutLocationsInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => pricesUpdateManyWithWhereWithoutLocationsInputSchema),z.lazy(() => pricesUpdateManyWithWhereWithoutLocationsInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => pricesScalarWhereInputSchema),z.lazy(() => pricesScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const drinksUncheckedUpdateManyWithoutLocationsNestedInputSchema: z.ZodType<Prisma.drinksUncheckedUpdateManyWithoutLocationsNestedInput> = z.object({
  create: z.union([ z.lazy(() => drinksCreateWithoutLocationsInputSchema),z.lazy(() => drinksCreateWithoutLocationsInputSchema).array(),z.lazy(() => drinksUncheckedCreateWithoutLocationsInputSchema),z.lazy(() => drinksUncheckedCreateWithoutLocationsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => drinksCreateOrConnectWithoutLocationsInputSchema),z.lazy(() => drinksCreateOrConnectWithoutLocationsInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => drinksUpsertWithWhereUniqueWithoutLocationsInputSchema),z.lazy(() => drinksUpsertWithWhereUniqueWithoutLocationsInputSchema).array() ]).optional(),
  createMany: z.lazy(() => drinksCreateManyLocationsInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => drinksWhereUniqueInputSchema),z.lazy(() => drinksWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => drinksWhereUniqueInputSchema),z.lazy(() => drinksWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => drinksWhereUniqueInputSchema),z.lazy(() => drinksWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => drinksWhereUniqueInputSchema),z.lazy(() => drinksWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => drinksUpdateWithWhereUniqueWithoutLocationsInputSchema),z.lazy(() => drinksUpdateWithWhereUniqueWithoutLocationsInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => drinksUpdateManyWithWhereWithoutLocationsInputSchema),z.lazy(() => drinksUpdateManyWithWhereWithoutLocationsInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => drinksScalarWhereInputSchema),z.lazy(() => drinksScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const drink_categoriesUncheckedUpdateManyWithoutLocationsNestedInputSchema: z.ZodType<Prisma.drink_categoriesUncheckedUpdateManyWithoutLocationsNestedInput> = z.object({
  create: z.union([ z.lazy(() => drink_categoriesCreateWithoutLocationsInputSchema),z.lazy(() => drink_categoriesCreateWithoutLocationsInputSchema).array(),z.lazy(() => drink_categoriesUncheckedCreateWithoutLocationsInputSchema),z.lazy(() => drink_categoriesUncheckedCreateWithoutLocationsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => drink_categoriesCreateOrConnectWithoutLocationsInputSchema),z.lazy(() => drink_categoriesCreateOrConnectWithoutLocationsInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => drink_categoriesUpsertWithWhereUniqueWithoutLocationsInputSchema),z.lazy(() => drink_categoriesUpsertWithWhereUniqueWithoutLocationsInputSchema).array() ]).optional(),
  createMany: z.lazy(() => drink_categoriesCreateManyLocationsInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => drink_categoriesWhereUniqueInputSchema),z.lazy(() => drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => drink_categoriesWhereUniqueInputSchema),z.lazy(() => drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => drink_categoriesWhereUniqueInputSchema),z.lazy(() => drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => drink_categoriesWhereUniqueInputSchema),z.lazy(() => drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => drink_categoriesUpdateWithWhereUniqueWithoutLocationsInputSchema),z.lazy(() => drink_categoriesUpdateWithWhereUniqueWithoutLocationsInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => drink_categoriesUpdateManyWithWhereWithoutLocationsInputSchema),z.lazy(() => drink_categoriesUpdateManyWithWhereWithoutLocationsInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => drink_categoriesScalarWhereInputSchema),z.lazy(() => drink_categoriesScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const contactUncheckedUpdateManyWithoutLocationsNestedInputSchema: z.ZodType<Prisma.contactUncheckedUpdateManyWithoutLocationsNestedInput> = z.object({
  create: z.union([ z.lazy(() => contactCreateWithoutLocationsInputSchema),z.lazy(() => contactCreateWithoutLocationsInputSchema).array(),z.lazy(() => contactUncheckedCreateWithoutLocationsInputSchema),z.lazy(() => contactUncheckedCreateWithoutLocationsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => contactCreateOrConnectWithoutLocationsInputSchema),z.lazy(() => contactCreateOrConnectWithoutLocationsInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => contactUpsertWithWhereUniqueWithoutLocationsInputSchema),z.lazy(() => contactUpsertWithWhereUniqueWithoutLocationsInputSchema).array() ]).optional(),
  createMany: z.lazy(() => contactCreateManyLocationsInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => contactWhereUniqueInputSchema),z.lazy(() => contactWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => contactWhereUniqueInputSchema),z.lazy(() => contactWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => contactWhereUniqueInputSchema),z.lazy(() => contactWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => contactWhereUniqueInputSchema),z.lazy(() => contactWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => contactUpdateWithWhereUniqueWithoutLocationsInputSchema),z.lazy(() => contactUpdateWithWhereUniqueWithoutLocationsInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => contactUpdateManyWithWhereWithoutLocationsInputSchema),z.lazy(() => contactUpdateManyWithWhereWithoutLocationsInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => contactScalarWhereInputSchema),z.lazy(() => contactScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const locationsCreateNestedOneWithoutDrink_categoriesInputSchema: z.ZodType<Prisma.locationsCreateNestedOneWithoutDrink_categoriesInput> = z.object({
  create: z.union([ z.lazy(() => locationsCreateWithoutDrink_categoriesInputSchema),z.lazy(() => locationsUncheckedCreateWithoutDrink_categoriesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => locationsCreateOrConnectWithoutDrink_categoriesInputSchema).optional(),
  connect: z.lazy(() => locationsWhereUniqueInputSchema).optional()
}).strict();

export const drinks_to_drink_categoriesCreateNestedManyWithoutDrink_categoriesInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesCreateNestedManyWithoutDrink_categoriesInput> = z.object({
  create: z.union([ z.lazy(() => drinks_to_drink_categoriesCreateWithoutDrink_categoriesInputSchema),z.lazy(() => drinks_to_drink_categoriesCreateWithoutDrink_categoriesInputSchema).array(),z.lazy(() => drinks_to_drink_categoriesUncheckedCreateWithoutDrink_categoriesInputSchema),z.lazy(() => drinks_to_drink_categoriesUncheckedCreateWithoutDrink_categoriesInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => drinks_to_drink_categoriesCreateOrConnectWithoutDrink_categoriesInputSchema),z.lazy(() => drinks_to_drink_categoriesCreateOrConnectWithoutDrink_categoriesInputSchema).array() ]).optional(),
  createMany: z.lazy(() => drinks_to_drink_categoriesCreateManyDrink_categoriesInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema),z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const drinks_to_drink_categoriesUncheckedCreateNestedManyWithoutDrink_categoriesInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesUncheckedCreateNestedManyWithoutDrink_categoriesInput> = z.object({
  create: z.union([ z.lazy(() => drinks_to_drink_categoriesCreateWithoutDrink_categoriesInputSchema),z.lazy(() => drinks_to_drink_categoriesCreateWithoutDrink_categoriesInputSchema).array(),z.lazy(() => drinks_to_drink_categoriesUncheckedCreateWithoutDrink_categoriesInputSchema),z.lazy(() => drinks_to_drink_categoriesUncheckedCreateWithoutDrink_categoriesInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => drinks_to_drink_categoriesCreateOrConnectWithoutDrink_categoriesInputSchema),z.lazy(() => drinks_to_drink_categoriesCreateOrConnectWithoutDrink_categoriesInputSchema).array() ]).optional(),
  createMany: z.lazy(() => drinks_to_drink_categoriesCreateManyDrink_categoriesInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema),z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const locationsUpdateOneRequiredWithoutDrink_categoriesNestedInputSchema: z.ZodType<Prisma.locationsUpdateOneRequiredWithoutDrink_categoriesNestedInput> = z.object({
  create: z.union([ z.lazy(() => locationsCreateWithoutDrink_categoriesInputSchema),z.lazy(() => locationsUncheckedCreateWithoutDrink_categoriesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => locationsCreateOrConnectWithoutDrink_categoriesInputSchema).optional(),
  upsert: z.lazy(() => locationsUpsertWithoutDrink_categoriesInputSchema).optional(),
  connect: z.lazy(() => locationsWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => locationsUpdateWithoutDrink_categoriesInputSchema),z.lazy(() => locationsUncheckedUpdateWithoutDrink_categoriesInputSchema) ]).optional(),
}).strict();

export const drinks_to_drink_categoriesUpdateManyWithoutDrink_categoriesNestedInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesUpdateManyWithoutDrink_categoriesNestedInput> = z.object({
  create: z.union([ z.lazy(() => drinks_to_drink_categoriesCreateWithoutDrink_categoriesInputSchema),z.lazy(() => drinks_to_drink_categoriesCreateWithoutDrink_categoriesInputSchema).array(),z.lazy(() => drinks_to_drink_categoriesUncheckedCreateWithoutDrink_categoriesInputSchema),z.lazy(() => drinks_to_drink_categoriesUncheckedCreateWithoutDrink_categoriesInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => drinks_to_drink_categoriesCreateOrConnectWithoutDrink_categoriesInputSchema),z.lazy(() => drinks_to_drink_categoriesCreateOrConnectWithoutDrink_categoriesInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => drinks_to_drink_categoriesUpsertWithWhereUniqueWithoutDrink_categoriesInputSchema),z.lazy(() => drinks_to_drink_categoriesUpsertWithWhereUniqueWithoutDrink_categoriesInputSchema).array() ]).optional(),
  createMany: z.lazy(() => drinks_to_drink_categoriesCreateManyDrink_categoriesInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema),z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema),z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema),z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema),z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => drinks_to_drink_categoriesUpdateWithWhereUniqueWithoutDrink_categoriesInputSchema),z.lazy(() => drinks_to_drink_categoriesUpdateWithWhereUniqueWithoutDrink_categoriesInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => drinks_to_drink_categoriesUpdateManyWithWhereWithoutDrink_categoriesInputSchema),z.lazy(() => drinks_to_drink_categoriesUpdateManyWithWhereWithoutDrink_categoriesInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => drinks_to_drink_categoriesScalarWhereInputSchema),z.lazy(() => drinks_to_drink_categoriesScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const drinks_to_drink_categoriesUncheckedUpdateManyWithoutDrink_categoriesNestedInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesUncheckedUpdateManyWithoutDrink_categoriesNestedInput> = z.object({
  create: z.union([ z.lazy(() => drinks_to_drink_categoriesCreateWithoutDrink_categoriesInputSchema),z.lazy(() => drinks_to_drink_categoriesCreateWithoutDrink_categoriesInputSchema).array(),z.lazy(() => drinks_to_drink_categoriesUncheckedCreateWithoutDrink_categoriesInputSchema),z.lazy(() => drinks_to_drink_categoriesUncheckedCreateWithoutDrink_categoriesInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => drinks_to_drink_categoriesCreateOrConnectWithoutDrink_categoriesInputSchema),z.lazy(() => drinks_to_drink_categoriesCreateOrConnectWithoutDrink_categoriesInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => drinks_to_drink_categoriesUpsertWithWhereUniqueWithoutDrink_categoriesInputSchema),z.lazy(() => drinks_to_drink_categoriesUpsertWithWhereUniqueWithoutDrink_categoriesInputSchema).array() ]).optional(),
  createMany: z.lazy(() => drinks_to_drink_categoriesCreateManyDrink_categoriesInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema),z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema),z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema),z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema),z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => drinks_to_drink_categoriesUpdateWithWhereUniqueWithoutDrink_categoriesInputSchema),z.lazy(() => drinks_to_drink_categoriesUpdateWithWhereUniqueWithoutDrink_categoriesInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => drinks_to_drink_categoriesUpdateManyWithWhereWithoutDrink_categoriesInputSchema),z.lazy(() => drinks_to_drink_categoriesUpdateManyWithWhereWithoutDrink_categoriesInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => drinks_to_drink_categoriesScalarWhereInputSchema),z.lazy(() => drinks_to_drink_categoriesScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const pricesCreateNestedManyWithoutDrinksInputSchema: z.ZodType<Prisma.pricesCreateNestedManyWithoutDrinksInput> = z.object({
  create: z.union([ z.lazy(() => pricesCreateWithoutDrinksInputSchema),z.lazy(() => pricesCreateWithoutDrinksInputSchema).array(),z.lazy(() => pricesUncheckedCreateWithoutDrinksInputSchema),z.lazy(() => pricesUncheckedCreateWithoutDrinksInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => pricesCreateOrConnectWithoutDrinksInputSchema),z.lazy(() => pricesCreateOrConnectWithoutDrinksInputSchema).array() ]).optional(),
  createMany: z.lazy(() => pricesCreateManyDrinksInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const locationsCreateNestedOneWithoutDrinksInputSchema: z.ZodType<Prisma.locationsCreateNestedOneWithoutDrinksInput> = z.object({
  create: z.union([ z.lazy(() => locationsCreateWithoutDrinksInputSchema),z.lazy(() => locationsUncheckedCreateWithoutDrinksInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => locationsCreateOrConnectWithoutDrinksInputSchema).optional(),
  connect: z.lazy(() => locationsWhereUniqueInputSchema).optional()
}).strict();

export const drinks_to_drink_categoriesCreateNestedManyWithoutDrinksInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesCreateNestedManyWithoutDrinksInput> = z.object({
  create: z.union([ z.lazy(() => drinks_to_drink_categoriesCreateWithoutDrinksInputSchema),z.lazy(() => drinks_to_drink_categoriesCreateWithoutDrinksInputSchema).array(),z.lazy(() => drinks_to_drink_categoriesUncheckedCreateWithoutDrinksInputSchema),z.lazy(() => drinks_to_drink_categoriesUncheckedCreateWithoutDrinksInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => drinks_to_drink_categoriesCreateOrConnectWithoutDrinksInputSchema),z.lazy(() => drinks_to_drink_categoriesCreateOrConnectWithoutDrinksInputSchema).array() ]).optional(),
  createMany: z.lazy(() => drinks_to_drink_categoriesCreateManyDrinksInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema),z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const pricesUncheckedCreateNestedManyWithoutDrinksInputSchema: z.ZodType<Prisma.pricesUncheckedCreateNestedManyWithoutDrinksInput> = z.object({
  create: z.union([ z.lazy(() => pricesCreateWithoutDrinksInputSchema),z.lazy(() => pricesCreateWithoutDrinksInputSchema).array(),z.lazy(() => pricesUncheckedCreateWithoutDrinksInputSchema),z.lazy(() => pricesUncheckedCreateWithoutDrinksInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => pricesCreateOrConnectWithoutDrinksInputSchema),z.lazy(() => pricesCreateOrConnectWithoutDrinksInputSchema).array() ]).optional(),
  createMany: z.lazy(() => pricesCreateManyDrinksInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const drinks_to_drink_categoriesUncheckedCreateNestedManyWithoutDrinksInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesUncheckedCreateNestedManyWithoutDrinksInput> = z.object({
  create: z.union([ z.lazy(() => drinks_to_drink_categoriesCreateWithoutDrinksInputSchema),z.lazy(() => drinks_to_drink_categoriesCreateWithoutDrinksInputSchema).array(),z.lazy(() => drinks_to_drink_categoriesUncheckedCreateWithoutDrinksInputSchema),z.lazy(() => drinks_to_drink_categoriesUncheckedCreateWithoutDrinksInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => drinks_to_drink_categoriesCreateOrConnectWithoutDrinksInputSchema),z.lazy(() => drinks_to_drink_categoriesCreateOrConnectWithoutDrinksInputSchema).array() ]).optional(),
  createMany: z.lazy(() => drinks_to_drink_categoriesCreateManyDrinksInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema),z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const pricesUpdateManyWithoutDrinksNestedInputSchema: z.ZodType<Prisma.pricesUpdateManyWithoutDrinksNestedInput> = z.object({
  create: z.union([ z.lazy(() => pricesCreateWithoutDrinksInputSchema),z.lazy(() => pricesCreateWithoutDrinksInputSchema).array(),z.lazy(() => pricesUncheckedCreateWithoutDrinksInputSchema),z.lazy(() => pricesUncheckedCreateWithoutDrinksInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => pricesCreateOrConnectWithoutDrinksInputSchema),z.lazy(() => pricesCreateOrConnectWithoutDrinksInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => pricesUpsertWithWhereUniqueWithoutDrinksInputSchema),z.lazy(() => pricesUpsertWithWhereUniqueWithoutDrinksInputSchema).array() ]).optional(),
  createMany: z.lazy(() => pricesCreateManyDrinksInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => pricesUpdateWithWhereUniqueWithoutDrinksInputSchema),z.lazy(() => pricesUpdateWithWhereUniqueWithoutDrinksInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => pricesUpdateManyWithWhereWithoutDrinksInputSchema),z.lazy(() => pricesUpdateManyWithWhereWithoutDrinksInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => pricesScalarWhereInputSchema),z.lazy(() => pricesScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const locationsUpdateOneRequiredWithoutDrinksNestedInputSchema: z.ZodType<Prisma.locationsUpdateOneRequiredWithoutDrinksNestedInput> = z.object({
  create: z.union([ z.lazy(() => locationsCreateWithoutDrinksInputSchema),z.lazy(() => locationsUncheckedCreateWithoutDrinksInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => locationsCreateOrConnectWithoutDrinksInputSchema).optional(),
  upsert: z.lazy(() => locationsUpsertWithoutDrinksInputSchema).optional(),
  connect: z.lazy(() => locationsWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => locationsUpdateWithoutDrinksInputSchema),z.lazy(() => locationsUncheckedUpdateWithoutDrinksInputSchema) ]).optional(),
}).strict();

export const drinks_to_drink_categoriesUpdateManyWithoutDrinksNestedInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesUpdateManyWithoutDrinksNestedInput> = z.object({
  create: z.union([ z.lazy(() => drinks_to_drink_categoriesCreateWithoutDrinksInputSchema),z.lazy(() => drinks_to_drink_categoriesCreateWithoutDrinksInputSchema).array(),z.lazy(() => drinks_to_drink_categoriesUncheckedCreateWithoutDrinksInputSchema),z.lazy(() => drinks_to_drink_categoriesUncheckedCreateWithoutDrinksInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => drinks_to_drink_categoriesCreateOrConnectWithoutDrinksInputSchema),z.lazy(() => drinks_to_drink_categoriesCreateOrConnectWithoutDrinksInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => drinks_to_drink_categoriesUpsertWithWhereUniqueWithoutDrinksInputSchema),z.lazy(() => drinks_to_drink_categoriesUpsertWithWhereUniqueWithoutDrinksInputSchema).array() ]).optional(),
  createMany: z.lazy(() => drinks_to_drink_categoriesCreateManyDrinksInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema),z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema),z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema),z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema),z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => drinks_to_drink_categoriesUpdateWithWhereUniqueWithoutDrinksInputSchema),z.lazy(() => drinks_to_drink_categoriesUpdateWithWhereUniqueWithoutDrinksInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => drinks_to_drink_categoriesUpdateManyWithWhereWithoutDrinksInputSchema),z.lazy(() => drinks_to_drink_categoriesUpdateManyWithWhereWithoutDrinksInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => drinks_to_drink_categoriesScalarWhereInputSchema),z.lazy(() => drinks_to_drink_categoriesScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const pricesUncheckedUpdateManyWithoutDrinksNestedInputSchema: z.ZodType<Prisma.pricesUncheckedUpdateManyWithoutDrinksNestedInput> = z.object({
  create: z.union([ z.lazy(() => pricesCreateWithoutDrinksInputSchema),z.lazy(() => pricesCreateWithoutDrinksInputSchema).array(),z.lazy(() => pricesUncheckedCreateWithoutDrinksInputSchema),z.lazy(() => pricesUncheckedCreateWithoutDrinksInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => pricesCreateOrConnectWithoutDrinksInputSchema),z.lazy(() => pricesCreateOrConnectWithoutDrinksInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => pricesUpsertWithWhereUniqueWithoutDrinksInputSchema),z.lazy(() => pricesUpsertWithWhereUniqueWithoutDrinksInputSchema).array() ]).optional(),
  createMany: z.lazy(() => pricesCreateManyDrinksInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => pricesUpdateWithWhereUniqueWithoutDrinksInputSchema),z.lazy(() => pricesUpdateWithWhereUniqueWithoutDrinksInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => pricesUpdateManyWithWhereWithoutDrinksInputSchema),z.lazy(() => pricesUpdateManyWithWhereWithoutDrinksInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => pricesScalarWhereInputSchema),z.lazy(() => pricesScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const drinks_to_drink_categoriesUncheckedUpdateManyWithoutDrinksNestedInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesUncheckedUpdateManyWithoutDrinksNestedInput> = z.object({
  create: z.union([ z.lazy(() => drinks_to_drink_categoriesCreateWithoutDrinksInputSchema),z.lazy(() => drinks_to_drink_categoriesCreateWithoutDrinksInputSchema).array(),z.lazy(() => drinks_to_drink_categoriesUncheckedCreateWithoutDrinksInputSchema),z.lazy(() => drinks_to_drink_categoriesUncheckedCreateWithoutDrinksInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => drinks_to_drink_categoriesCreateOrConnectWithoutDrinksInputSchema),z.lazy(() => drinks_to_drink_categoriesCreateOrConnectWithoutDrinksInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => drinks_to_drink_categoriesUpsertWithWhereUniqueWithoutDrinksInputSchema),z.lazy(() => drinks_to_drink_categoriesUpsertWithWhereUniqueWithoutDrinksInputSchema).array() ]).optional(),
  createMany: z.lazy(() => drinks_to_drink_categoriesCreateManyDrinksInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema),z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema),z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema),z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema),z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => drinks_to_drink_categoriesUpdateWithWhereUniqueWithoutDrinksInputSchema),z.lazy(() => drinks_to_drink_categoriesUpdateWithWhereUniqueWithoutDrinksInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => drinks_to_drink_categoriesUpdateManyWithWhereWithoutDrinksInputSchema),z.lazy(() => drinks_to_drink_categoriesUpdateManyWithWhereWithoutDrinksInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => drinks_to_drink_categoriesScalarWhereInputSchema),z.lazy(() => drinks_to_drink_categoriesScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const drinksCreateNestedOneWithoutDrink_categoriesInputSchema: z.ZodType<Prisma.drinksCreateNestedOneWithoutDrink_categoriesInput> = z.object({
  create: z.union([ z.lazy(() => drinksCreateWithoutDrink_categoriesInputSchema),z.lazy(() => drinksUncheckedCreateWithoutDrink_categoriesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => drinksCreateOrConnectWithoutDrink_categoriesInputSchema).optional(),
  connect: z.lazy(() => drinksWhereUniqueInputSchema).optional()
}).strict();

export const drink_categoriesCreateNestedOneWithoutDrinksInputSchema: z.ZodType<Prisma.drink_categoriesCreateNestedOneWithoutDrinksInput> = z.object({
  create: z.union([ z.lazy(() => drink_categoriesCreateWithoutDrinksInputSchema),z.lazy(() => drink_categoriesUncheckedCreateWithoutDrinksInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => drink_categoriesCreateOrConnectWithoutDrinksInputSchema).optional(),
  connect: z.lazy(() => drink_categoriesWhereUniqueInputSchema).optional()
}).strict();

export const drinksUpdateOneRequiredWithoutDrink_categoriesNestedInputSchema: z.ZodType<Prisma.drinksUpdateOneRequiredWithoutDrink_categoriesNestedInput> = z.object({
  create: z.union([ z.lazy(() => drinksCreateWithoutDrink_categoriesInputSchema),z.lazy(() => drinksUncheckedCreateWithoutDrink_categoriesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => drinksCreateOrConnectWithoutDrink_categoriesInputSchema).optional(),
  upsert: z.lazy(() => drinksUpsertWithoutDrink_categoriesInputSchema).optional(),
  connect: z.lazy(() => drinksWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => drinksUpdateWithoutDrink_categoriesInputSchema),z.lazy(() => drinksUncheckedUpdateWithoutDrink_categoriesInputSchema) ]).optional(),
}).strict();

export const drink_categoriesUpdateOneRequiredWithoutDrinksNestedInputSchema: z.ZodType<Prisma.drink_categoriesUpdateOneRequiredWithoutDrinksNestedInput> = z.object({
  create: z.union([ z.lazy(() => drink_categoriesCreateWithoutDrinksInputSchema),z.lazy(() => drink_categoriesUncheckedCreateWithoutDrinksInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => drink_categoriesCreateOrConnectWithoutDrinksInputSchema).optional(),
  upsert: z.lazy(() => drink_categoriesUpsertWithoutDrinksInputSchema).optional(),
  connect: z.lazy(() => drink_categoriesWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => drink_categoriesUpdateWithoutDrinksInputSchema),z.lazy(() => drink_categoriesUncheckedUpdateWithoutDrinksInputSchema) ]).optional(),
}).strict();

export const menusCreateNestedOneWithoutPricesInputSchema: z.ZodType<Prisma.menusCreateNestedOneWithoutPricesInput> = z.object({
  create: z.union([ z.lazy(() => menusCreateWithoutPricesInputSchema),z.lazy(() => menusUncheckedCreateWithoutPricesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => menusCreateOrConnectWithoutPricesInputSchema).optional(),
  connect: z.lazy(() => menusWhereUniqueInputSchema).optional()
}).strict();

export const locationsCreateNestedOneWithoutPricesInputSchema: z.ZodType<Prisma.locationsCreateNestedOneWithoutPricesInput> = z.object({
  create: z.union([ z.lazy(() => locationsCreateWithoutPricesInputSchema),z.lazy(() => locationsUncheckedCreateWithoutPricesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => locationsCreateOrConnectWithoutPricesInputSchema).optional(),
  connect: z.lazy(() => locationsWhereUniqueInputSchema).optional()
}).strict();

export const drinksCreateNestedOneWithoutPricesInputSchema: z.ZodType<Prisma.drinksCreateNestedOneWithoutPricesInput> = z.object({
  create: z.union([ z.lazy(() => drinksCreateWithoutPricesInputSchema),z.lazy(() => drinksUncheckedCreateWithoutPricesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => drinksCreateOrConnectWithoutPricesInputSchema).optional(),
  connect: z.lazy(() => drinksWhereUniqueInputSchema).optional()
}).strict();

export const menusUpdateOneWithoutPricesNestedInputSchema: z.ZodType<Prisma.menusUpdateOneWithoutPricesNestedInput> = z.object({
  create: z.union([ z.lazy(() => menusCreateWithoutPricesInputSchema),z.lazy(() => menusUncheckedCreateWithoutPricesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => menusCreateOrConnectWithoutPricesInputSchema).optional(),
  upsert: z.lazy(() => menusUpsertWithoutPricesInputSchema).optional(),
  disconnect: z.boolean().optional(),
  delete: z.boolean().optional(),
  connect: z.lazy(() => menusWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => menusUpdateWithoutPricesInputSchema),z.lazy(() => menusUncheckedUpdateWithoutPricesInputSchema) ]).optional(),
}).strict();

export const locationsUpdateOneRequiredWithoutPricesNestedInputSchema: z.ZodType<Prisma.locationsUpdateOneRequiredWithoutPricesNestedInput> = z.object({
  create: z.union([ z.lazy(() => locationsCreateWithoutPricesInputSchema),z.lazy(() => locationsUncheckedCreateWithoutPricesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => locationsCreateOrConnectWithoutPricesInputSchema).optional(),
  upsert: z.lazy(() => locationsUpsertWithoutPricesInputSchema).optional(),
  connect: z.lazy(() => locationsWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => locationsUpdateWithoutPricesInputSchema),z.lazy(() => locationsUncheckedUpdateWithoutPricesInputSchema) ]).optional(),
}).strict();

export const drinksUpdateOneRequiredWithoutPricesNestedInputSchema: z.ZodType<Prisma.drinksUpdateOneRequiredWithoutPricesNestedInput> = z.object({
  create: z.union([ z.lazy(() => drinksCreateWithoutPricesInputSchema),z.lazy(() => drinksUncheckedCreateWithoutPricesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => drinksCreateOrConnectWithoutPricesInputSchema).optional(),
  upsert: z.lazy(() => drinksUpsertWithoutPricesInputSchema).optional(),
  connect: z.lazy(() => drinksWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => drinksUpdateWithoutPricesInputSchema),z.lazy(() => drinksUncheckedUpdateWithoutPricesInputSchema) ]).optional(),
}).strict();

export const locationsCreateNestedOneWithoutMenusInputSchema: z.ZodType<Prisma.locationsCreateNestedOneWithoutMenusInput> = z.object({
  create: z.union([ z.lazy(() => locationsCreateWithoutMenusInputSchema),z.lazy(() => locationsUncheckedCreateWithoutMenusInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => locationsCreateOrConnectWithoutMenusInputSchema).optional(),
  connect: z.lazy(() => locationsWhereUniqueInputSchema).optional()
}).strict();

export const pricesCreateNestedManyWithoutMenusInputSchema: z.ZodType<Prisma.pricesCreateNestedManyWithoutMenusInput> = z.object({
  create: z.union([ z.lazy(() => pricesCreateWithoutMenusInputSchema),z.lazy(() => pricesCreateWithoutMenusInputSchema).array(),z.lazy(() => pricesUncheckedCreateWithoutMenusInputSchema),z.lazy(() => pricesUncheckedCreateWithoutMenusInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => pricesCreateOrConnectWithoutMenusInputSchema),z.lazy(() => pricesCreateOrConnectWithoutMenusInputSchema).array() ]).optional(),
  createMany: z.lazy(() => pricesCreateManyMenusInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const pricesUncheckedCreateNestedManyWithoutMenusInputSchema: z.ZodType<Prisma.pricesUncheckedCreateNestedManyWithoutMenusInput> = z.object({
  create: z.union([ z.lazy(() => pricesCreateWithoutMenusInputSchema),z.lazy(() => pricesCreateWithoutMenusInputSchema).array(),z.lazy(() => pricesUncheckedCreateWithoutMenusInputSchema),z.lazy(() => pricesUncheckedCreateWithoutMenusInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => pricesCreateOrConnectWithoutMenusInputSchema),z.lazy(() => pricesCreateOrConnectWithoutMenusInputSchema).array() ]).optional(),
  createMany: z.lazy(() => pricesCreateManyMenusInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const locationsUpdateOneRequiredWithoutMenusNestedInputSchema: z.ZodType<Prisma.locationsUpdateOneRequiredWithoutMenusNestedInput> = z.object({
  create: z.union([ z.lazy(() => locationsCreateWithoutMenusInputSchema),z.lazy(() => locationsUncheckedCreateWithoutMenusInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => locationsCreateOrConnectWithoutMenusInputSchema).optional(),
  upsert: z.lazy(() => locationsUpsertWithoutMenusInputSchema).optional(),
  connect: z.lazy(() => locationsWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => locationsUpdateWithoutMenusInputSchema),z.lazy(() => locationsUncheckedUpdateWithoutMenusInputSchema) ]).optional(),
}).strict();

export const pricesUpdateManyWithoutMenusNestedInputSchema: z.ZodType<Prisma.pricesUpdateManyWithoutMenusNestedInput> = z.object({
  create: z.union([ z.lazy(() => pricesCreateWithoutMenusInputSchema),z.lazy(() => pricesCreateWithoutMenusInputSchema).array(),z.lazy(() => pricesUncheckedCreateWithoutMenusInputSchema),z.lazy(() => pricesUncheckedCreateWithoutMenusInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => pricesCreateOrConnectWithoutMenusInputSchema),z.lazy(() => pricesCreateOrConnectWithoutMenusInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => pricesUpsertWithWhereUniqueWithoutMenusInputSchema),z.lazy(() => pricesUpsertWithWhereUniqueWithoutMenusInputSchema).array() ]).optional(),
  createMany: z.lazy(() => pricesCreateManyMenusInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => pricesUpdateWithWhereUniqueWithoutMenusInputSchema),z.lazy(() => pricesUpdateWithWhereUniqueWithoutMenusInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => pricesUpdateManyWithWhereWithoutMenusInputSchema),z.lazy(() => pricesUpdateManyWithWhereWithoutMenusInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => pricesScalarWhereInputSchema),z.lazy(() => pricesScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const pricesUncheckedUpdateManyWithoutMenusNestedInputSchema: z.ZodType<Prisma.pricesUncheckedUpdateManyWithoutMenusNestedInput> = z.object({
  create: z.union([ z.lazy(() => pricesCreateWithoutMenusInputSchema),z.lazy(() => pricesCreateWithoutMenusInputSchema).array(),z.lazy(() => pricesUncheckedCreateWithoutMenusInputSchema),z.lazy(() => pricesUncheckedCreateWithoutMenusInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => pricesCreateOrConnectWithoutMenusInputSchema),z.lazy(() => pricesCreateOrConnectWithoutMenusInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => pricesUpsertWithWhereUniqueWithoutMenusInputSchema),z.lazy(() => pricesUpsertWithWhereUniqueWithoutMenusInputSchema).array() ]).optional(),
  createMany: z.lazy(() => pricesCreateManyMenusInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => pricesWhereUniqueInputSchema),z.lazy(() => pricesWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => pricesUpdateWithWhereUniqueWithoutMenusInputSchema),z.lazy(() => pricesUpdateWithWhereUniqueWithoutMenusInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => pricesUpdateManyWithWhereWithoutMenusInputSchema),z.lazy(() => pricesUpdateManyWithWhereWithoutMenusInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => pricesScalarWhereInputSchema),z.lazy(() => pricesScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const UserCreateNestedOneWithoutAccountsInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutAccountsInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutAccountsInputSchema),z.lazy(() => UserUncheckedCreateWithoutAccountsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutAccountsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const UserUpdateOneRequiredWithoutAccountsNestedInputSchema: z.ZodType<Prisma.UserUpdateOneRequiredWithoutAccountsNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutAccountsInputSchema),z.lazy(() => UserUncheckedCreateWithoutAccountsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutAccountsInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutAccountsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateWithoutAccountsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutAccountsInputSchema) ]).optional(),
}).strict();

export const UserCreateNestedOneWithoutSessionsInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutSessionsInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutSessionsInputSchema),z.lazy(() => UserUncheckedCreateWithoutSessionsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutSessionsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const UserUpdateOneRequiredWithoutSessionsNestedInputSchema: z.ZodType<Prisma.UserUpdateOneRequiredWithoutSessionsNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutSessionsInputSchema),z.lazy(() => UserUncheckedCreateWithoutSessionsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutSessionsInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutSessionsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateWithoutSessionsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutSessionsInputSchema) ]).optional(),
}).strict();

export const AccountCreateNestedManyWithoutUserInputSchema: z.ZodType<Prisma.AccountCreateNestedManyWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutUserInputSchema),z.lazy(() => AccountCreateWithoutUserInputSchema).array(),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AccountCreateOrConnectWithoutUserInputSchema),z.lazy(() => AccountCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AccountCreateManyUserInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SessionCreateNestedManyWithoutUserInputSchema: z.ZodType<Prisma.SessionCreateNestedManyWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => SessionCreateWithoutUserInputSchema),z.lazy(() => SessionCreateWithoutUserInputSchema).array(),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SessionCreateOrConnectWithoutUserInputSchema),z.lazy(() => SessionCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SessionCreateManyUserInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const AccountUncheckedCreateNestedManyWithoutUserInputSchema: z.ZodType<Prisma.AccountUncheckedCreateNestedManyWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutUserInputSchema),z.lazy(() => AccountCreateWithoutUserInputSchema).array(),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AccountCreateOrConnectWithoutUserInputSchema),z.lazy(() => AccountCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AccountCreateManyUserInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SessionUncheckedCreateNestedManyWithoutUserInputSchema: z.ZodType<Prisma.SessionUncheckedCreateNestedManyWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => SessionCreateWithoutUserInputSchema),z.lazy(() => SessionCreateWithoutUserInputSchema).array(),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SessionCreateOrConnectWithoutUserInputSchema),z.lazy(() => SessionCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SessionCreateManyUserInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const AccountUpdateManyWithoutUserNestedInputSchema: z.ZodType<Prisma.AccountUpdateManyWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutUserInputSchema),z.lazy(() => AccountCreateWithoutUserInputSchema).array(),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AccountCreateOrConnectWithoutUserInputSchema),z.lazy(() => AccountCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => AccountUpsertWithWhereUniqueWithoutUserInputSchema),z.lazy(() => AccountUpsertWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AccountCreateManyUserInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => AccountUpdateWithWhereUniqueWithoutUserInputSchema),z.lazy(() => AccountUpdateWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => AccountUpdateManyWithWhereWithoutUserInputSchema),z.lazy(() => AccountUpdateManyWithWhereWithoutUserInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => AccountScalarWhereInputSchema),z.lazy(() => AccountScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SessionUpdateManyWithoutUserNestedInputSchema: z.ZodType<Prisma.SessionUpdateManyWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => SessionCreateWithoutUserInputSchema),z.lazy(() => SessionCreateWithoutUserInputSchema).array(),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SessionCreateOrConnectWithoutUserInputSchema),z.lazy(() => SessionCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SessionUpsertWithWhereUniqueWithoutUserInputSchema),z.lazy(() => SessionUpsertWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SessionCreateManyUserInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SessionUpdateWithWhereUniqueWithoutUserInputSchema),z.lazy(() => SessionUpdateWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SessionUpdateManyWithWhereWithoutUserInputSchema),z.lazy(() => SessionUpdateManyWithWhereWithoutUserInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SessionScalarWhereInputSchema),z.lazy(() => SessionScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const AccountUncheckedUpdateManyWithoutUserNestedInputSchema: z.ZodType<Prisma.AccountUncheckedUpdateManyWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutUserInputSchema),z.lazy(() => AccountCreateWithoutUserInputSchema).array(),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AccountCreateOrConnectWithoutUserInputSchema),z.lazy(() => AccountCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => AccountUpsertWithWhereUniqueWithoutUserInputSchema),z.lazy(() => AccountUpsertWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AccountCreateManyUserInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => AccountUpdateWithWhereUniqueWithoutUserInputSchema),z.lazy(() => AccountUpdateWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => AccountUpdateManyWithWhereWithoutUserInputSchema),z.lazy(() => AccountUpdateManyWithWhereWithoutUserInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => AccountScalarWhereInputSchema),z.lazy(() => AccountScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SessionUncheckedUpdateManyWithoutUserNestedInputSchema: z.ZodType<Prisma.SessionUncheckedUpdateManyWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => SessionCreateWithoutUserInputSchema),z.lazy(() => SessionCreateWithoutUserInputSchema).array(),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SessionCreateOrConnectWithoutUserInputSchema),z.lazy(() => SessionCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SessionUpsertWithWhereUniqueWithoutUserInputSchema),z.lazy(() => SessionUpsertWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SessionCreateManyUserInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SessionUpdateWithWhereUniqueWithoutUserInputSchema),z.lazy(() => SessionUpdateWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SessionUpdateManyWithWhereWithoutUserInputSchema),z.lazy(() => SessionUpdateManyWithWhereWithoutUserInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SessionScalarWhereInputSchema),z.lazy(() => SessionScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const locationsCreateNestedOneWithoutContactInputSchema: z.ZodType<Prisma.locationsCreateNestedOneWithoutContactInput> = z.object({
  create: z.union([ z.lazy(() => locationsCreateWithoutContactInputSchema),z.lazy(() => locationsUncheckedCreateWithoutContactInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => locationsCreateOrConnectWithoutContactInputSchema).optional(),
  connect: z.lazy(() => locationsWhereUniqueInputSchema).optional()
}).strict();

export const locationsUpdateOneWithoutContactNestedInputSchema: z.ZodType<Prisma.locationsUpdateOneWithoutContactNestedInput> = z.object({
  create: z.union([ z.lazy(() => locationsCreateWithoutContactInputSchema),z.lazy(() => locationsUncheckedCreateWithoutContactInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => locationsCreateOrConnectWithoutContactInputSchema).optional(),
  upsert: z.lazy(() => locationsUpsertWithoutContactInputSchema).optional(),
  disconnect: z.boolean().optional(),
  delete: z.boolean().optional(),
  connect: z.lazy(() => locationsWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => locationsUpdateWithoutContactInputSchema),z.lazy(() => locationsUncheckedUpdateWithoutContactInputSchema) ]).optional(),
}).strict();

export const NestedStringFilterSchema: z.ZodType<Prisma.NestedStringFilter> = z.object({
  equals: z.string().optional(),
  in: z.union([ z.string().array(),z.string() ]).optional(),
  notIn: z.union([ z.string().array(),z.string() ]).optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  search: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringFilterSchema) ]).optional(),
}).strict();

export const NestedDateTimeFilterSchema: z.ZodType<Prisma.NestedDateTimeFilter> = z.object({
  equals: z.coerce.date().optional(),
  in: z.union([ z.coerce.date().array(),z.coerce.date() ]).optional(),
  notIn: z.union([ z.coerce.date().array(),z.coerce.date() ]).optional(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeFilterSchema) ]).optional(),
}).strict();

export const NestedStringNullableFilterSchema: z.ZodType<Prisma.NestedStringNullableFilter> = z.object({
  equals: z.string().optional().nullable(),
  in: z.union([ z.string().array(),z.string() ]).optional().nullable(),
  notIn: z.union([ z.string().array(),z.string() ]).optional().nullable(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  search: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedFloatNullableFilterSchema: z.ZodType<Prisma.NestedFloatNullableFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.union([ z.number().array(),z.number() ]).optional().nullable(),
  notIn: z.union([ z.number().array(),z.number() ]).optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedDateTimeNullableFilterSchema: z.ZodType<Prisma.NestedDateTimeNullableFilter> = z.object({
  equals: z.coerce.date().optional().nullable(),
  in: z.union([ z.coerce.date().array(),z.coerce.date() ]).optional().nullable(),
  notIn: z.union([ z.coerce.date().array(),z.coerce.date() ]).optional().nullable(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedIntNullableFilterSchema: z.ZodType<Prisma.NestedIntNullableFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.union([ z.number().array(),z.number() ]).optional().nullable(),
  notIn: z.union([ z.number().array(),z.number() ]).optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedBoolNullableFilterSchema: z.ZodType<Prisma.NestedBoolNullableFilter> = z.object({
  equals: z.boolean().optional().nullable(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedStringWithAggregatesFilterSchema: z.ZodType<Prisma.NestedStringWithAggregatesFilter> = z.object({
  equals: z.string().optional(),
  in: z.union([ z.string().array(),z.string() ]).optional(),
  notIn: z.union([ z.string().array(),z.string() ]).optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  search: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedStringFilterSchema).optional(),
  _max: z.lazy(() => NestedStringFilterSchema).optional()
}).strict();

export const NestedIntFilterSchema: z.ZodType<Prisma.NestedIntFilter> = z.object({
  equals: z.number().optional(),
  in: z.union([ z.number().array(),z.number() ]).optional(),
  notIn: z.union([ z.number().array(),z.number() ]).optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntFilterSchema) ]).optional(),
}).strict();

export const NestedDateTimeWithAggregatesFilterSchema: z.ZodType<Prisma.NestedDateTimeWithAggregatesFilter> = z.object({
  equals: z.coerce.date().optional(),
  in: z.union([ z.coerce.date().array(),z.coerce.date() ]).optional(),
  notIn: z.union([ z.coerce.date().array(),z.coerce.date() ]).optional(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedDateTimeFilterSchema).optional(),
  _max: z.lazy(() => NestedDateTimeFilterSchema).optional()
}).strict();

export const NestedStringNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedStringNullableWithAggregatesFilter> = z.object({
  equals: z.string().optional().nullable(),
  in: z.union([ z.string().array(),z.string() ]).optional().nullable(),
  notIn: z.union([ z.string().array(),z.string() ]).optional().nullable(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  search: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedStringNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedStringNullableFilterSchema).optional()
}).strict();

export const NestedFloatNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedFloatNullableWithAggregatesFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.union([ z.number().array(),z.number() ]).optional().nullable(),
  notIn: z.union([ z.number().array(),z.number() ]).optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _sum: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedFloatNullableFilterSchema).optional()
}).strict();

export const NestedDateTimeNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedDateTimeNullableWithAggregatesFilter> = z.object({
  equals: z.coerce.date().optional().nullable(),
  in: z.union([ z.coerce.date().array(),z.coerce.date() ]).optional().nullable(),
  notIn: z.union([ z.coerce.date().array(),z.coerce.date() ]).optional().nullable(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedDateTimeNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedDateTimeNullableFilterSchema).optional()
}).strict();

export const NestedIntNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedIntNullableWithAggregatesFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.union([ z.number().array(),z.number() ]).optional().nullable(),
  notIn: z.union([ z.number().array(),z.number() ]).optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _sum: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedIntNullableFilterSchema).optional()
}).strict();

export const NestedBoolNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedBoolNullableWithAggregatesFilter> = z.object({
  equals: z.boolean().optional().nullable(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedBoolNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedBoolNullableFilterSchema).optional()
}).strict();

export const menusCreateWithoutLocationsInputSchema: z.ZodType<Prisma.menusCreateWithoutLocationsInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  name: z.string(),
  isDiscounted: z.boolean().optional().nullable(),
  daysOfWeek: z.number().int().optional().nullable(),
  timeStart: z.number().int().optional().nullable(),
  timeEnd: z.number().int().optional().nullable(),
  meandu_id: z.string().optional().nullable(),
  prices: z.lazy(() => pricesCreateNestedManyWithoutMenusInputSchema).optional()
}).strict();

export const menusUncheckedCreateWithoutLocationsInputSchema: z.ZodType<Prisma.menusUncheckedCreateWithoutLocationsInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  name: z.string(),
  isDiscounted: z.boolean().optional().nullable(),
  daysOfWeek: z.number().int().optional().nullable(),
  timeStart: z.number().int().optional().nullable(),
  timeEnd: z.number().int().optional().nullable(),
  meandu_id: z.string().optional().nullable(),
  prices: z.lazy(() => pricesUncheckedCreateNestedManyWithoutMenusInputSchema).optional()
}).strict();

export const menusCreateOrConnectWithoutLocationsInputSchema: z.ZodType<Prisma.menusCreateOrConnectWithoutLocationsInput> = z.object({
  where: z.lazy(() => menusWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => menusCreateWithoutLocationsInputSchema),z.lazy(() => menusUncheckedCreateWithoutLocationsInputSchema) ]),
}).strict();

export const menusCreateManyLocationsInputEnvelopeSchema: z.ZodType<Prisma.menusCreateManyLocationsInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => menusCreateManyLocationsInputSchema),z.lazy(() => menusCreateManyLocationsInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const pricesCreateWithoutLocationsInputSchema: z.ZodType<Prisma.pricesCreateWithoutLocationsInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  price: z.number().optional().nullable(),
  drink_variant_name: z.string().optional().nullable(),
  menus: z.lazy(() => menusCreateNestedOneWithoutPricesInputSchema).optional(),
  drinks: z.lazy(() => drinksCreateNestedOneWithoutPricesInputSchema)
}).strict();

export const pricesUncheckedCreateWithoutLocationsInputSchema: z.ZodType<Prisma.pricesUncheckedCreateWithoutLocationsInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  menusId: z.string().optional().nullable(),
  price: z.number().optional().nullable(),
  drink_id: z.string(),
  drink_variant_name: z.string().optional().nullable()
}).strict();

export const pricesCreateOrConnectWithoutLocationsInputSchema: z.ZodType<Prisma.pricesCreateOrConnectWithoutLocationsInput> = z.object({
  where: z.lazy(() => pricesWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => pricesCreateWithoutLocationsInputSchema),z.lazy(() => pricesUncheckedCreateWithoutLocationsInputSchema) ]),
}).strict();

export const pricesCreateManyLocationsInputEnvelopeSchema: z.ZodType<Prisma.pricesCreateManyLocationsInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => pricesCreateManyLocationsInputSchema),z.lazy(() => pricesCreateManyLocationsInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const drinksCreateWithoutLocationsInputSchema: z.ZodType<Prisma.drinksCreateWithoutLocationsInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  name: z.string(),
  meandu_id: z.string().optional().nullable(),
  prices: z.lazy(() => pricesCreateNestedManyWithoutDrinksInputSchema).optional(),
  drink_categories: z.lazy(() => drinks_to_drink_categoriesCreateNestedManyWithoutDrinksInputSchema).optional()
}).strict();

export const drinksUncheckedCreateWithoutLocationsInputSchema: z.ZodType<Prisma.drinksUncheckedCreateWithoutLocationsInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  name: z.string(),
  meandu_id: z.string().optional().nullable(),
  prices: z.lazy(() => pricesUncheckedCreateNestedManyWithoutDrinksInputSchema).optional(),
  drink_categories: z.lazy(() => drinks_to_drink_categoriesUncheckedCreateNestedManyWithoutDrinksInputSchema).optional()
}).strict();

export const drinksCreateOrConnectWithoutLocationsInputSchema: z.ZodType<Prisma.drinksCreateOrConnectWithoutLocationsInput> = z.object({
  where: z.lazy(() => drinksWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => drinksCreateWithoutLocationsInputSchema),z.lazy(() => drinksUncheckedCreateWithoutLocationsInputSchema) ]),
}).strict();

export const drinksCreateManyLocationsInputEnvelopeSchema: z.ZodType<Prisma.drinksCreateManyLocationsInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => drinksCreateManyLocationsInputSchema),z.lazy(() => drinksCreateManyLocationsInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const drink_categoriesCreateWithoutLocationsInputSchema: z.ZodType<Prisma.drink_categoriesCreateWithoutLocationsInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  name: z.string(),
  meandu_id: z.string().optional().nullable(),
  drinks: z.lazy(() => drinks_to_drink_categoriesCreateNestedManyWithoutDrink_categoriesInputSchema).optional()
}).strict();

export const drink_categoriesUncheckedCreateWithoutLocationsInputSchema: z.ZodType<Prisma.drink_categoriesUncheckedCreateWithoutLocationsInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  name: z.string(),
  meandu_id: z.string().optional().nullable(),
  drinks: z.lazy(() => drinks_to_drink_categoriesUncheckedCreateNestedManyWithoutDrink_categoriesInputSchema).optional()
}).strict();

export const drink_categoriesCreateOrConnectWithoutLocationsInputSchema: z.ZodType<Prisma.drink_categoriesCreateOrConnectWithoutLocationsInput> = z.object({
  where: z.lazy(() => drink_categoriesWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => drink_categoriesCreateWithoutLocationsInputSchema),z.lazy(() => drink_categoriesUncheckedCreateWithoutLocationsInputSchema) ]),
}).strict();

export const drink_categoriesCreateManyLocationsInputEnvelopeSchema: z.ZodType<Prisma.drink_categoriesCreateManyLocationsInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => drink_categoriesCreateManyLocationsInputSchema),z.lazy(() => drink_categoriesCreateManyLocationsInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const contactCreateWithoutLocationsInputSchema: z.ZodType<Prisma.contactCreateWithoutLocationsInput> = z.object({
  id: z.string().uuid().optional(),
  createdAt: z.coerce.date().optional(),
  name: z.string(),
  email: z.string().optional().nullable(),
  message: z.string().optional().nullable(),
  x: z.string().optional().nullable(),
  linkedin: z.string().optional().nullable(),
  instagram: z.string().optional().nullable(),
  facebook: z.string().optional().nullable(),
  website: z.string().optional().nullable(),
  phone: z.string().optional().nullable()
}).strict();

export const contactUncheckedCreateWithoutLocationsInputSchema: z.ZodType<Prisma.contactUncheckedCreateWithoutLocationsInput> = z.object({
  id: z.string().uuid().optional(),
  createdAt: z.coerce.date().optional(),
  name: z.string(),
  email: z.string().optional().nullable(),
  message: z.string().optional().nullable(),
  x: z.string().optional().nullable(),
  linkedin: z.string().optional().nullable(),
  instagram: z.string().optional().nullable(),
  facebook: z.string().optional().nullable(),
  website: z.string().optional().nullable(),
  phone: z.string().optional().nullable()
}).strict();

export const contactCreateOrConnectWithoutLocationsInputSchema: z.ZodType<Prisma.contactCreateOrConnectWithoutLocationsInput> = z.object({
  where: z.lazy(() => contactWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => contactCreateWithoutLocationsInputSchema),z.lazy(() => contactUncheckedCreateWithoutLocationsInputSchema) ]),
}).strict();

export const contactCreateManyLocationsInputEnvelopeSchema: z.ZodType<Prisma.contactCreateManyLocationsInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => contactCreateManyLocationsInputSchema),z.lazy(() => contactCreateManyLocationsInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const menusUpsertWithWhereUniqueWithoutLocationsInputSchema: z.ZodType<Prisma.menusUpsertWithWhereUniqueWithoutLocationsInput> = z.object({
  where: z.lazy(() => menusWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => menusUpdateWithoutLocationsInputSchema),z.lazy(() => menusUncheckedUpdateWithoutLocationsInputSchema) ]),
  create: z.union([ z.lazy(() => menusCreateWithoutLocationsInputSchema),z.lazy(() => menusUncheckedCreateWithoutLocationsInputSchema) ]),
}).strict();

export const menusUpdateWithWhereUniqueWithoutLocationsInputSchema: z.ZodType<Prisma.menusUpdateWithWhereUniqueWithoutLocationsInput> = z.object({
  where: z.lazy(() => menusWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => menusUpdateWithoutLocationsInputSchema),z.lazy(() => menusUncheckedUpdateWithoutLocationsInputSchema) ]),
}).strict();

export const menusUpdateManyWithWhereWithoutLocationsInputSchema: z.ZodType<Prisma.menusUpdateManyWithWhereWithoutLocationsInput> = z.object({
  where: z.lazy(() => menusScalarWhereInputSchema),
  data: z.union([ z.lazy(() => menusUpdateManyMutationInputSchema),z.lazy(() => menusUncheckedUpdateManyWithoutMenusInputSchema) ]),
}).strict();

export const menusScalarWhereInputSchema: z.ZodType<Prisma.menusScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => menusScalarWhereInputSchema),z.lazy(() => menusScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => menusScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => menusScalarWhereInputSchema),z.lazy(() => menusScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  timestamp: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  isDiscounted: z.union([ z.lazy(() => BoolNullableFilterSchema),z.boolean() ]).optional().nullable(),
  locationsId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  daysOfWeek: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  timeStart: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  timeEnd: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  meandu_id: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const pricesUpsertWithWhereUniqueWithoutLocationsInputSchema: z.ZodType<Prisma.pricesUpsertWithWhereUniqueWithoutLocationsInput> = z.object({
  where: z.lazy(() => pricesWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => pricesUpdateWithoutLocationsInputSchema),z.lazy(() => pricesUncheckedUpdateWithoutLocationsInputSchema) ]),
  create: z.union([ z.lazy(() => pricesCreateWithoutLocationsInputSchema),z.lazy(() => pricesUncheckedCreateWithoutLocationsInputSchema) ]),
}).strict();

export const pricesUpdateWithWhereUniqueWithoutLocationsInputSchema: z.ZodType<Prisma.pricesUpdateWithWhereUniqueWithoutLocationsInput> = z.object({
  where: z.lazy(() => pricesWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => pricesUpdateWithoutLocationsInputSchema),z.lazy(() => pricesUncheckedUpdateWithoutLocationsInputSchema) ]),
}).strict();

export const pricesUpdateManyWithWhereWithoutLocationsInputSchema: z.ZodType<Prisma.pricesUpdateManyWithWhereWithoutLocationsInput> = z.object({
  where: z.lazy(() => pricesScalarWhereInputSchema),
  data: z.union([ z.lazy(() => pricesUpdateManyMutationInputSchema),z.lazy(() => pricesUncheckedUpdateManyWithoutPricesInputSchema) ]),
}).strict();

export const pricesScalarWhereInputSchema: z.ZodType<Prisma.pricesScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => pricesScalarWhereInputSchema),z.lazy(() => pricesScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => pricesScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => pricesScalarWhereInputSchema),z.lazy(() => pricesScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  timestamp: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  menusId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  locationsId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  price: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  drink_id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  drink_variant_name: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const drinksUpsertWithWhereUniqueWithoutLocationsInputSchema: z.ZodType<Prisma.drinksUpsertWithWhereUniqueWithoutLocationsInput> = z.object({
  where: z.lazy(() => drinksWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => drinksUpdateWithoutLocationsInputSchema),z.lazy(() => drinksUncheckedUpdateWithoutLocationsInputSchema) ]),
  create: z.union([ z.lazy(() => drinksCreateWithoutLocationsInputSchema),z.lazy(() => drinksUncheckedCreateWithoutLocationsInputSchema) ]),
}).strict();

export const drinksUpdateWithWhereUniqueWithoutLocationsInputSchema: z.ZodType<Prisma.drinksUpdateWithWhereUniqueWithoutLocationsInput> = z.object({
  where: z.lazy(() => drinksWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => drinksUpdateWithoutLocationsInputSchema),z.lazy(() => drinksUncheckedUpdateWithoutLocationsInputSchema) ]),
}).strict();

export const drinksUpdateManyWithWhereWithoutLocationsInputSchema: z.ZodType<Prisma.drinksUpdateManyWithWhereWithoutLocationsInput> = z.object({
  where: z.lazy(() => drinksScalarWhereInputSchema),
  data: z.union([ z.lazy(() => drinksUpdateManyMutationInputSchema),z.lazy(() => drinksUncheckedUpdateManyWithoutDrinksInputSchema) ]),
}).strict();

export const drinksScalarWhereInputSchema: z.ZodType<Prisma.drinksScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => drinksScalarWhereInputSchema),z.lazy(() => drinksScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => drinksScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => drinksScalarWhereInputSchema),z.lazy(() => drinksScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  timestamp: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  locationsId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  meandu_id: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const drink_categoriesUpsertWithWhereUniqueWithoutLocationsInputSchema: z.ZodType<Prisma.drink_categoriesUpsertWithWhereUniqueWithoutLocationsInput> = z.object({
  where: z.lazy(() => drink_categoriesWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => drink_categoriesUpdateWithoutLocationsInputSchema),z.lazy(() => drink_categoriesUncheckedUpdateWithoutLocationsInputSchema) ]),
  create: z.union([ z.lazy(() => drink_categoriesCreateWithoutLocationsInputSchema),z.lazy(() => drink_categoriesUncheckedCreateWithoutLocationsInputSchema) ]),
}).strict();

export const drink_categoriesUpdateWithWhereUniqueWithoutLocationsInputSchema: z.ZodType<Prisma.drink_categoriesUpdateWithWhereUniqueWithoutLocationsInput> = z.object({
  where: z.lazy(() => drink_categoriesWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => drink_categoriesUpdateWithoutLocationsInputSchema),z.lazy(() => drink_categoriesUncheckedUpdateWithoutLocationsInputSchema) ]),
}).strict();

export const drink_categoriesUpdateManyWithWhereWithoutLocationsInputSchema: z.ZodType<Prisma.drink_categoriesUpdateManyWithWhereWithoutLocationsInput> = z.object({
  where: z.lazy(() => drink_categoriesScalarWhereInputSchema),
  data: z.union([ z.lazy(() => drink_categoriesUpdateManyMutationInputSchema),z.lazy(() => drink_categoriesUncheckedUpdateManyWithoutDrink_categoriesInputSchema) ]),
}).strict();

export const drink_categoriesScalarWhereInputSchema: z.ZodType<Prisma.drink_categoriesScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => drink_categoriesScalarWhereInputSchema),z.lazy(() => drink_categoriesScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => drink_categoriesScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => drink_categoriesScalarWhereInputSchema),z.lazy(() => drink_categoriesScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  timestamp: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  meandu_id: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  locationsId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
}).strict();

export const contactUpsertWithWhereUniqueWithoutLocationsInputSchema: z.ZodType<Prisma.contactUpsertWithWhereUniqueWithoutLocationsInput> = z.object({
  where: z.lazy(() => contactWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => contactUpdateWithoutLocationsInputSchema),z.lazy(() => contactUncheckedUpdateWithoutLocationsInputSchema) ]),
  create: z.union([ z.lazy(() => contactCreateWithoutLocationsInputSchema),z.lazy(() => contactUncheckedCreateWithoutLocationsInputSchema) ]),
}).strict();

export const contactUpdateWithWhereUniqueWithoutLocationsInputSchema: z.ZodType<Prisma.contactUpdateWithWhereUniqueWithoutLocationsInput> = z.object({
  where: z.lazy(() => contactWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => contactUpdateWithoutLocationsInputSchema),z.lazy(() => contactUncheckedUpdateWithoutLocationsInputSchema) ]),
}).strict();

export const contactUpdateManyWithWhereWithoutLocationsInputSchema: z.ZodType<Prisma.contactUpdateManyWithWhereWithoutLocationsInput> = z.object({
  where: z.lazy(() => contactScalarWhereInputSchema),
  data: z.union([ z.lazy(() => contactUpdateManyMutationInputSchema),z.lazy(() => contactUncheckedUpdateManyWithoutContactInputSchema) ]),
}).strict();

export const contactScalarWhereInputSchema: z.ZodType<Prisma.contactScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => contactScalarWhereInputSchema),z.lazy(() => contactScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => contactScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => contactScalarWhereInputSchema),z.lazy(() => contactScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  email: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  message: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  locationsId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  x: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  linkedin: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  instagram: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  facebook: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  website: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  phone: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const locationsCreateWithoutDrink_categoriesInputSchema: z.ZodType<Prisma.locationsCreateWithoutDrink_categoriesInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  meandu_id: z.string().optional().nullable(),
  meandu_id_1: z.string().optional().nullable(),
  meandu_id_2: z.string().optional().nullable(),
  name: z.string().optional().nullable(),
  address: z.string().optional().nullable(),
  latitude: z.number().optional().nullable(),
  longitude: z.number().optional().nullable(),
  date_created: z.coerce.date().optional().nullable(),
  google_name: z.string().optional().nullable(),
  google_place_id: z.string().optional().nullable(),
  currency: z.string().optional().nullable(),
  locale: z.string().optional().nullable(),
  meandu_menu_url: z.string().optional().nullable(),
  last_updated: z.coerce.date().optional().nullable(),
  google_operational: z.string().optional().nullable(),
  validityScore: z.number().int().optional().nullable(),
  hidden: z.boolean().optional().nullable(),
  menus: z.lazy(() => menusCreateNestedManyWithoutLocationsInputSchema).optional(),
  prices: z.lazy(() => pricesCreateNestedManyWithoutLocationsInputSchema).optional(),
  drinks: z.lazy(() => drinksCreateNestedManyWithoutLocationsInputSchema).optional(),
  contact: z.lazy(() => contactCreateNestedManyWithoutLocationsInputSchema).optional()
}).strict();

export const locationsUncheckedCreateWithoutDrink_categoriesInputSchema: z.ZodType<Prisma.locationsUncheckedCreateWithoutDrink_categoriesInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  meandu_id: z.string().optional().nullable(),
  meandu_id_1: z.string().optional().nullable(),
  meandu_id_2: z.string().optional().nullable(),
  name: z.string().optional().nullable(),
  address: z.string().optional().nullable(),
  latitude: z.number().optional().nullable(),
  longitude: z.number().optional().nullable(),
  date_created: z.coerce.date().optional().nullable(),
  google_name: z.string().optional().nullable(),
  google_place_id: z.string().optional().nullable(),
  currency: z.string().optional().nullable(),
  locale: z.string().optional().nullable(),
  meandu_menu_url: z.string().optional().nullable(),
  last_updated: z.coerce.date().optional().nullable(),
  google_operational: z.string().optional().nullable(),
  validityScore: z.number().int().optional().nullable(),
  hidden: z.boolean().optional().nullable(),
  menus: z.lazy(() => menusUncheckedCreateNestedManyWithoutLocationsInputSchema).optional(),
  prices: z.lazy(() => pricesUncheckedCreateNestedManyWithoutLocationsInputSchema).optional(),
  drinks: z.lazy(() => drinksUncheckedCreateNestedManyWithoutLocationsInputSchema).optional(),
  contact: z.lazy(() => contactUncheckedCreateNestedManyWithoutLocationsInputSchema).optional()
}).strict();

export const locationsCreateOrConnectWithoutDrink_categoriesInputSchema: z.ZodType<Prisma.locationsCreateOrConnectWithoutDrink_categoriesInput> = z.object({
  where: z.lazy(() => locationsWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => locationsCreateWithoutDrink_categoriesInputSchema),z.lazy(() => locationsUncheckedCreateWithoutDrink_categoriesInputSchema) ]),
}).strict();

export const drinks_to_drink_categoriesCreateWithoutDrink_categoriesInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesCreateWithoutDrink_categoriesInput> = z.object({
  drinks: z.lazy(() => drinksCreateNestedOneWithoutDrink_categoriesInputSchema)
}).strict();

export const drinks_to_drink_categoriesUncheckedCreateWithoutDrink_categoriesInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesUncheckedCreateWithoutDrink_categoriesInput> = z.object({
  drink_id: z.string()
}).strict();

export const drinks_to_drink_categoriesCreateOrConnectWithoutDrink_categoriesInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesCreateOrConnectWithoutDrink_categoriesInput> = z.object({
  where: z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => drinks_to_drink_categoriesCreateWithoutDrink_categoriesInputSchema),z.lazy(() => drinks_to_drink_categoriesUncheckedCreateWithoutDrink_categoriesInputSchema) ]),
}).strict();

export const drinks_to_drink_categoriesCreateManyDrink_categoriesInputEnvelopeSchema: z.ZodType<Prisma.drinks_to_drink_categoriesCreateManyDrink_categoriesInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => drinks_to_drink_categoriesCreateManyDrink_categoriesInputSchema),z.lazy(() => drinks_to_drink_categoriesCreateManyDrink_categoriesInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const locationsUpsertWithoutDrink_categoriesInputSchema: z.ZodType<Prisma.locationsUpsertWithoutDrink_categoriesInput> = z.object({
  update: z.union([ z.lazy(() => locationsUpdateWithoutDrink_categoriesInputSchema),z.lazy(() => locationsUncheckedUpdateWithoutDrink_categoriesInputSchema) ]),
  create: z.union([ z.lazy(() => locationsCreateWithoutDrink_categoriesInputSchema),z.lazy(() => locationsUncheckedCreateWithoutDrink_categoriesInputSchema) ]),
}).strict();

export const locationsUpdateWithoutDrink_categoriesInputSchema: z.ZodType<Prisma.locationsUpdateWithoutDrink_categoriesInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id_1: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id_2: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  latitude: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  longitude: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  date_created: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_place_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currency: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locale: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_menu_url: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  last_updated: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_operational: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  validityScore: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hidden: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  menus: z.lazy(() => menusUpdateManyWithoutLocationsNestedInputSchema).optional(),
  prices: z.lazy(() => pricesUpdateManyWithoutLocationsNestedInputSchema).optional(),
  drinks: z.lazy(() => drinksUpdateManyWithoutLocationsNestedInputSchema).optional(),
  contact: z.lazy(() => contactUpdateManyWithoutLocationsNestedInputSchema).optional()
}).strict();

export const locationsUncheckedUpdateWithoutDrink_categoriesInputSchema: z.ZodType<Prisma.locationsUncheckedUpdateWithoutDrink_categoriesInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id_1: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id_2: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  latitude: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  longitude: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  date_created: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_place_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currency: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locale: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_menu_url: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  last_updated: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_operational: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  validityScore: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hidden: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  menus: z.lazy(() => menusUncheckedUpdateManyWithoutLocationsNestedInputSchema).optional(),
  prices: z.lazy(() => pricesUncheckedUpdateManyWithoutLocationsNestedInputSchema).optional(),
  drinks: z.lazy(() => drinksUncheckedUpdateManyWithoutLocationsNestedInputSchema).optional(),
  contact: z.lazy(() => contactUncheckedUpdateManyWithoutLocationsNestedInputSchema).optional()
}).strict();

export const drinks_to_drink_categoriesUpsertWithWhereUniqueWithoutDrink_categoriesInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesUpsertWithWhereUniqueWithoutDrink_categoriesInput> = z.object({
  where: z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => drinks_to_drink_categoriesUpdateWithoutDrink_categoriesInputSchema),z.lazy(() => drinks_to_drink_categoriesUncheckedUpdateWithoutDrink_categoriesInputSchema) ]),
  create: z.union([ z.lazy(() => drinks_to_drink_categoriesCreateWithoutDrink_categoriesInputSchema),z.lazy(() => drinks_to_drink_categoriesUncheckedCreateWithoutDrink_categoriesInputSchema) ]),
}).strict();

export const drinks_to_drink_categoriesUpdateWithWhereUniqueWithoutDrink_categoriesInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesUpdateWithWhereUniqueWithoutDrink_categoriesInput> = z.object({
  where: z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => drinks_to_drink_categoriesUpdateWithoutDrink_categoriesInputSchema),z.lazy(() => drinks_to_drink_categoriesUncheckedUpdateWithoutDrink_categoriesInputSchema) ]),
}).strict();

export const drinks_to_drink_categoriesUpdateManyWithWhereWithoutDrink_categoriesInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesUpdateManyWithWhereWithoutDrink_categoriesInput> = z.object({
  where: z.lazy(() => drinks_to_drink_categoriesScalarWhereInputSchema),
  data: z.union([ z.lazy(() => drinks_to_drink_categoriesUpdateManyMutationInputSchema),z.lazy(() => drinks_to_drink_categoriesUncheckedUpdateManyWithoutDrinksInputSchema) ]),
}).strict();

export const drinks_to_drink_categoriesScalarWhereInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => drinks_to_drink_categoriesScalarWhereInputSchema),z.lazy(() => drinks_to_drink_categoriesScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => drinks_to_drink_categoriesScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => drinks_to_drink_categoriesScalarWhereInputSchema),z.lazy(() => drinks_to_drink_categoriesScalarWhereInputSchema).array() ]).optional(),
  drink_id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  drink_category_id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
}).strict();

export const pricesCreateWithoutDrinksInputSchema: z.ZodType<Prisma.pricesCreateWithoutDrinksInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  price: z.number().optional().nullable(),
  drink_variant_name: z.string().optional().nullable(),
  menus: z.lazy(() => menusCreateNestedOneWithoutPricesInputSchema).optional(),
  locations: z.lazy(() => locationsCreateNestedOneWithoutPricesInputSchema)
}).strict();

export const pricesUncheckedCreateWithoutDrinksInputSchema: z.ZodType<Prisma.pricesUncheckedCreateWithoutDrinksInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  menusId: z.string().optional().nullable(),
  locationsId: z.string(),
  price: z.number().optional().nullable(),
  drink_variant_name: z.string().optional().nullable()
}).strict();

export const pricesCreateOrConnectWithoutDrinksInputSchema: z.ZodType<Prisma.pricesCreateOrConnectWithoutDrinksInput> = z.object({
  where: z.lazy(() => pricesWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => pricesCreateWithoutDrinksInputSchema),z.lazy(() => pricesUncheckedCreateWithoutDrinksInputSchema) ]),
}).strict();

export const pricesCreateManyDrinksInputEnvelopeSchema: z.ZodType<Prisma.pricesCreateManyDrinksInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => pricesCreateManyDrinksInputSchema),z.lazy(() => pricesCreateManyDrinksInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const locationsCreateWithoutDrinksInputSchema: z.ZodType<Prisma.locationsCreateWithoutDrinksInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  meandu_id: z.string().optional().nullable(),
  meandu_id_1: z.string().optional().nullable(),
  meandu_id_2: z.string().optional().nullable(),
  name: z.string().optional().nullable(),
  address: z.string().optional().nullable(),
  latitude: z.number().optional().nullable(),
  longitude: z.number().optional().nullable(),
  date_created: z.coerce.date().optional().nullable(),
  google_name: z.string().optional().nullable(),
  google_place_id: z.string().optional().nullable(),
  currency: z.string().optional().nullable(),
  locale: z.string().optional().nullable(),
  meandu_menu_url: z.string().optional().nullable(),
  last_updated: z.coerce.date().optional().nullable(),
  google_operational: z.string().optional().nullable(),
  validityScore: z.number().int().optional().nullable(),
  hidden: z.boolean().optional().nullable(),
  menus: z.lazy(() => menusCreateNestedManyWithoutLocationsInputSchema).optional(),
  prices: z.lazy(() => pricesCreateNestedManyWithoutLocationsInputSchema).optional(),
  drink_categories: z.lazy(() => drink_categoriesCreateNestedManyWithoutLocationsInputSchema).optional(),
  contact: z.lazy(() => contactCreateNestedManyWithoutLocationsInputSchema).optional()
}).strict();

export const locationsUncheckedCreateWithoutDrinksInputSchema: z.ZodType<Prisma.locationsUncheckedCreateWithoutDrinksInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  meandu_id: z.string().optional().nullable(),
  meandu_id_1: z.string().optional().nullable(),
  meandu_id_2: z.string().optional().nullable(),
  name: z.string().optional().nullable(),
  address: z.string().optional().nullable(),
  latitude: z.number().optional().nullable(),
  longitude: z.number().optional().nullable(),
  date_created: z.coerce.date().optional().nullable(),
  google_name: z.string().optional().nullable(),
  google_place_id: z.string().optional().nullable(),
  currency: z.string().optional().nullable(),
  locale: z.string().optional().nullable(),
  meandu_menu_url: z.string().optional().nullable(),
  last_updated: z.coerce.date().optional().nullable(),
  google_operational: z.string().optional().nullable(),
  validityScore: z.number().int().optional().nullable(),
  hidden: z.boolean().optional().nullable(),
  menus: z.lazy(() => menusUncheckedCreateNestedManyWithoutLocationsInputSchema).optional(),
  prices: z.lazy(() => pricesUncheckedCreateNestedManyWithoutLocationsInputSchema).optional(),
  drink_categories: z.lazy(() => drink_categoriesUncheckedCreateNestedManyWithoutLocationsInputSchema).optional(),
  contact: z.lazy(() => contactUncheckedCreateNestedManyWithoutLocationsInputSchema).optional()
}).strict();

export const locationsCreateOrConnectWithoutDrinksInputSchema: z.ZodType<Prisma.locationsCreateOrConnectWithoutDrinksInput> = z.object({
  where: z.lazy(() => locationsWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => locationsCreateWithoutDrinksInputSchema),z.lazy(() => locationsUncheckedCreateWithoutDrinksInputSchema) ]),
}).strict();

export const drinks_to_drink_categoriesCreateWithoutDrinksInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesCreateWithoutDrinksInput> = z.object({
  drink_categories: z.lazy(() => drink_categoriesCreateNestedOneWithoutDrinksInputSchema)
}).strict();

export const drinks_to_drink_categoriesUncheckedCreateWithoutDrinksInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesUncheckedCreateWithoutDrinksInput> = z.object({
  drink_category_id: z.string()
}).strict();

export const drinks_to_drink_categoriesCreateOrConnectWithoutDrinksInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesCreateOrConnectWithoutDrinksInput> = z.object({
  where: z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => drinks_to_drink_categoriesCreateWithoutDrinksInputSchema),z.lazy(() => drinks_to_drink_categoriesUncheckedCreateWithoutDrinksInputSchema) ]),
}).strict();

export const drinks_to_drink_categoriesCreateManyDrinksInputEnvelopeSchema: z.ZodType<Prisma.drinks_to_drink_categoriesCreateManyDrinksInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => drinks_to_drink_categoriesCreateManyDrinksInputSchema),z.lazy(() => drinks_to_drink_categoriesCreateManyDrinksInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const pricesUpsertWithWhereUniqueWithoutDrinksInputSchema: z.ZodType<Prisma.pricesUpsertWithWhereUniqueWithoutDrinksInput> = z.object({
  where: z.lazy(() => pricesWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => pricesUpdateWithoutDrinksInputSchema),z.lazy(() => pricesUncheckedUpdateWithoutDrinksInputSchema) ]),
  create: z.union([ z.lazy(() => pricesCreateWithoutDrinksInputSchema),z.lazy(() => pricesUncheckedCreateWithoutDrinksInputSchema) ]),
}).strict();

export const pricesUpdateWithWhereUniqueWithoutDrinksInputSchema: z.ZodType<Prisma.pricesUpdateWithWhereUniqueWithoutDrinksInput> = z.object({
  where: z.lazy(() => pricesWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => pricesUpdateWithoutDrinksInputSchema),z.lazy(() => pricesUncheckedUpdateWithoutDrinksInputSchema) ]),
}).strict();

export const pricesUpdateManyWithWhereWithoutDrinksInputSchema: z.ZodType<Prisma.pricesUpdateManyWithWhereWithoutDrinksInput> = z.object({
  where: z.lazy(() => pricesScalarWhereInputSchema),
  data: z.union([ z.lazy(() => pricesUpdateManyMutationInputSchema),z.lazy(() => pricesUncheckedUpdateManyWithoutPricesInputSchema) ]),
}).strict();

export const locationsUpsertWithoutDrinksInputSchema: z.ZodType<Prisma.locationsUpsertWithoutDrinksInput> = z.object({
  update: z.union([ z.lazy(() => locationsUpdateWithoutDrinksInputSchema),z.lazy(() => locationsUncheckedUpdateWithoutDrinksInputSchema) ]),
  create: z.union([ z.lazy(() => locationsCreateWithoutDrinksInputSchema),z.lazy(() => locationsUncheckedCreateWithoutDrinksInputSchema) ]),
}).strict();

export const locationsUpdateWithoutDrinksInputSchema: z.ZodType<Prisma.locationsUpdateWithoutDrinksInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id_1: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id_2: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  latitude: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  longitude: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  date_created: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_place_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currency: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locale: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_menu_url: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  last_updated: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_operational: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  validityScore: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hidden: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  menus: z.lazy(() => menusUpdateManyWithoutLocationsNestedInputSchema).optional(),
  prices: z.lazy(() => pricesUpdateManyWithoutLocationsNestedInputSchema).optional(),
  drink_categories: z.lazy(() => drink_categoriesUpdateManyWithoutLocationsNestedInputSchema).optional(),
  contact: z.lazy(() => contactUpdateManyWithoutLocationsNestedInputSchema).optional()
}).strict();

export const locationsUncheckedUpdateWithoutDrinksInputSchema: z.ZodType<Prisma.locationsUncheckedUpdateWithoutDrinksInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id_1: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id_2: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  latitude: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  longitude: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  date_created: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_place_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currency: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locale: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_menu_url: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  last_updated: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_operational: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  validityScore: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hidden: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  menus: z.lazy(() => menusUncheckedUpdateManyWithoutLocationsNestedInputSchema).optional(),
  prices: z.lazy(() => pricesUncheckedUpdateManyWithoutLocationsNestedInputSchema).optional(),
  drink_categories: z.lazy(() => drink_categoriesUncheckedUpdateManyWithoutLocationsNestedInputSchema).optional(),
  contact: z.lazy(() => contactUncheckedUpdateManyWithoutLocationsNestedInputSchema).optional()
}).strict();

export const drinks_to_drink_categoriesUpsertWithWhereUniqueWithoutDrinksInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesUpsertWithWhereUniqueWithoutDrinksInput> = z.object({
  where: z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => drinks_to_drink_categoriesUpdateWithoutDrinksInputSchema),z.lazy(() => drinks_to_drink_categoriesUncheckedUpdateWithoutDrinksInputSchema) ]),
  create: z.union([ z.lazy(() => drinks_to_drink_categoriesCreateWithoutDrinksInputSchema),z.lazy(() => drinks_to_drink_categoriesUncheckedCreateWithoutDrinksInputSchema) ]),
}).strict();

export const drinks_to_drink_categoriesUpdateWithWhereUniqueWithoutDrinksInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesUpdateWithWhereUniqueWithoutDrinksInput> = z.object({
  where: z.lazy(() => drinks_to_drink_categoriesWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => drinks_to_drink_categoriesUpdateWithoutDrinksInputSchema),z.lazy(() => drinks_to_drink_categoriesUncheckedUpdateWithoutDrinksInputSchema) ]),
}).strict();

export const drinks_to_drink_categoriesUpdateManyWithWhereWithoutDrinksInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesUpdateManyWithWhereWithoutDrinksInput> = z.object({
  where: z.lazy(() => drinks_to_drink_categoriesScalarWhereInputSchema),
  data: z.union([ z.lazy(() => drinks_to_drink_categoriesUpdateManyMutationInputSchema),z.lazy(() => drinks_to_drink_categoriesUncheckedUpdateManyWithoutDrink_categoriesInputSchema) ]),
}).strict();

export const drinksCreateWithoutDrink_categoriesInputSchema: z.ZodType<Prisma.drinksCreateWithoutDrink_categoriesInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  name: z.string(),
  meandu_id: z.string().optional().nullable(),
  prices: z.lazy(() => pricesCreateNestedManyWithoutDrinksInputSchema).optional(),
  locations: z.lazy(() => locationsCreateNestedOneWithoutDrinksInputSchema)
}).strict();

export const drinksUncheckedCreateWithoutDrink_categoriesInputSchema: z.ZodType<Prisma.drinksUncheckedCreateWithoutDrink_categoriesInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  name: z.string(),
  locationsId: z.string(),
  meandu_id: z.string().optional().nullable(),
  prices: z.lazy(() => pricesUncheckedCreateNestedManyWithoutDrinksInputSchema).optional()
}).strict();

export const drinksCreateOrConnectWithoutDrink_categoriesInputSchema: z.ZodType<Prisma.drinksCreateOrConnectWithoutDrink_categoriesInput> = z.object({
  where: z.lazy(() => drinksWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => drinksCreateWithoutDrink_categoriesInputSchema),z.lazy(() => drinksUncheckedCreateWithoutDrink_categoriesInputSchema) ]),
}).strict();

export const drink_categoriesCreateWithoutDrinksInputSchema: z.ZodType<Prisma.drink_categoriesCreateWithoutDrinksInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  name: z.string(),
  meandu_id: z.string().optional().nullable(),
  locations: z.lazy(() => locationsCreateNestedOneWithoutDrink_categoriesInputSchema)
}).strict();

export const drink_categoriesUncheckedCreateWithoutDrinksInputSchema: z.ZodType<Prisma.drink_categoriesUncheckedCreateWithoutDrinksInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  name: z.string(),
  meandu_id: z.string().optional().nullable(),
  locationsId: z.string()
}).strict();

export const drink_categoriesCreateOrConnectWithoutDrinksInputSchema: z.ZodType<Prisma.drink_categoriesCreateOrConnectWithoutDrinksInput> = z.object({
  where: z.lazy(() => drink_categoriesWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => drink_categoriesCreateWithoutDrinksInputSchema),z.lazy(() => drink_categoriesUncheckedCreateWithoutDrinksInputSchema) ]),
}).strict();

export const drinksUpsertWithoutDrink_categoriesInputSchema: z.ZodType<Prisma.drinksUpsertWithoutDrink_categoriesInput> = z.object({
  update: z.union([ z.lazy(() => drinksUpdateWithoutDrink_categoriesInputSchema),z.lazy(() => drinksUncheckedUpdateWithoutDrink_categoriesInputSchema) ]),
  create: z.union([ z.lazy(() => drinksCreateWithoutDrink_categoriesInputSchema),z.lazy(() => drinksUncheckedCreateWithoutDrink_categoriesInputSchema) ]),
}).strict();

export const drinksUpdateWithoutDrink_categoriesInputSchema: z.ZodType<Prisma.drinksUpdateWithoutDrink_categoriesInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  prices: z.lazy(() => pricesUpdateManyWithoutDrinksNestedInputSchema).optional(),
  locations: z.lazy(() => locationsUpdateOneRequiredWithoutDrinksNestedInputSchema).optional()
}).strict();

export const drinksUncheckedUpdateWithoutDrink_categoriesInputSchema: z.ZodType<Prisma.drinksUncheckedUpdateWithoutDrink_categoriesInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  locationsId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  prices: z.lazy(() => pricesUncheckedUpdateManyWithoutDrinksNestedInputSchema).optional()
}).strict();

export const drink_categoriesUpsertWithoutDrinksInputSchema: z.ZodType<Prisma.drink_categoriesUpsertWithoutDrinksInput> = z.object({
  update: z.union([ z.lazy(() => drink_categoriesUpdateWithoutDrinksInputSchema),z.lazy(() => drink_categoriesUncheckedUpdateWithoutDrinksInputSchema) ]),
  create: z.union([ z.lazy(() => drink_categoriesCreateWithoutDrinksInputSchema),z.lazy(() => drink_categoriesUncheckedCreateWithoutDrinksInputSchema) ]),
}).strict();

export const drink_categoriesUpdateWithoutDrinksInputSchema: z.ZodType<Prisma.drink_categoriesUpdateWithoutDrinksInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locations: z.lazy(() => locationsUpdateOneRequiredWithoutDrink_categoriesNestedInputSchema).optional()
}).strict();

export const drink_categoriesUncheckedUpdateWithoutDrinksInputSchema: z.ZodType<Prisma.drink_categoriesUncheckedUpdateWithoutDrinksInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationsId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const menusCreateWithoutPricesInputSchema: z.ZodType<Prisma.menusCreateWithoutPricesInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  name: z.string(),
  isDiscounted: z.boolean().optional().nullable(),
  daysOfWeek: z.number().int().optional().nullable(),
  timeStart: z.number().int().optional().nullable(),
  timeEnd: z.number().int().optional().nullable(),
  meandu_id: z.string().optional().nullable(),
  locations: z.lazy(() => locationsCreateNestedOneWithoutMenusInputSchema)
}).strict();

export const menusUncheckedCreateWithoutPricesInputSchema: z.ZodType<Prisma.menusUncheckedCreateWithoutPricesInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  name: z.string(),
  isDiscounted: z.boolean().optional().nullable(),
  locationsId: z.string(),
  daysOfWeek: z.number().int().optional().nullable(),
  timeStart: z.number().int().optional().nullable(),
  timeEnd: z.number().int().optional().nullable(),
  meandu_id: z.string().optional().nullable()
}).strict();

export const menusCreateOrConnectWithoutPricesInputSchema: z.ZodType<Prisma.menusCreateOrConnectWithoutPricesInput> = z.object({
  where: z.lazy(() => menusWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => menusCreateWithoutPricesInputSchema),z.lazy(() => menusUncheckedCreateWithoutPricesInputSchema) ]),
}).strict();

export const locationsCreateWithoutPricesInputSchema: z.ZodType<Prisma.locationsCreateWithoutPricesInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  meandu_id: z.string().optional().nullable(),
  meandu_id_1: z.string().optional().nullable(),
  meandu_id_2: z.string().optional().nullable(),
  name: z.string().optional().nullable(),
  address: z.string().optional().nullable(),
  latitude: z.number().optional().nullable(),
  longitude: z.number().optional().nullable(),
  date_created: z.coerce.date().optional().nullable(),
  google_name: z.string().optional().nullable(),
  google_place_id: z.string().optional().nullable(),
  currency: z.string().optional().nullable(),
  locale: z.string().optional().nullable(),
  meandu_menu_url: z.string().optional().nullable(),
  last_updated: z.coerce.date().optional().nullable(),
  google_operational: z.string().optional().nullable(),
  validityScore: z.number().int().optional().nullable(),
  hidden: z.boolean().optional().nullable(),
  menus: z.lazy(() => menusCreateNestedManyWithoutLocationsInputSchema).optional(),
  drinks: z.lazy(() => drinksCreateNestedManyWithoutLocationsInputSchema).optional(),
  drink_categories: z.lazy(() => drink_categoriesCreateNestedManyWithoutLocationsInputSchema).optional(),
  contact: z.lazy(() => contactCreateNestedManyWithoutLocationsInputSchema).optional()
}).strict();

export const locationsUncheckedCreateWithoutPricesInputSchema: z.ZodType<Prisma.locationsUncheckedCreateWithoutPricesInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  meandu_id: z.string().optional().nullable(),
  meandu_id_1: z.string().optional().nullable(),
  meandu_id_2: z.string().optional().nullable(),
  name: z.string().optional().nullable(),
  address: z.string().optional().nullable(),
  latitude: z.number().optional().nullable(),
  longitude: z.number().optional().nullable(),
  date_created: z.coerce.date().optional().nullable(),
  google_name: z.string().optional().nullable(),
  google_place_id: z.string().optional().nullable(),
  currency: z.string().optional().nullable(),
  locale: z.string().optional().nullable(),
  meandu_menu_url: z.string().optional().nullable(),
  last_updated: z.coerce.date().optional().nullable(),
  google_operational: z.string().optional().nullable(),
  validityScore: z.number().int().optional().nullable(),
  hidden: z.boolean().optional().nullable(),
  menus: z.lazy(() => menusUncheckedCreateNestedManyWithoutLocationsInputSchema).optional(),
  drinks: z.lazy(() => drinksUncheckedCreateNestedManyWithoutLocationsInputSchema).optional(),
  drink_categories: z.lazy(() => drink_categoriesUncheckedCreateNestedManyWithoutLocationsInputSchema).optional(),
  contact: z.lazy(() => contactUncheckedCreateNestedManyWithoutLocationsInputSchema).optional()
}).strict();

export const locationsCreateOrConnectWithoutPricesInputSchema: z.ZodType<Prisma.locationsCreateOrConnectWithoutPricesInput> = z.object({
  where: z.lazy(() => locationsWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => locationsCreateWithoutPricesInputSchema),z.lazy(() => locationsUncheckedCreateWithoutPricesInputSchema) ]),
}).strict();

export const drinksCreateWithoutPricesInputSchema: z.ZodType<Prisma.drinksCreateWithoutPricesInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  name: z.string(),
  meandu_id: z.string().optional().nullable(),
  locations: z.lazy(() => locationsCreateNestedOneWithoutDrinksInputSchema),
  drink_categories: z.lazy(() => drinks_to_drink_categoriesCreateNestedManyWithoutDrinksInputSchema).optional()
}).strict();

export const drinksUncheckedCreateWithoutPricesInputSchema: z.ZodType<Prisma.drinksUncheckedCreateWithoutPricesInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  name: z.string(),
  locationsId: z.string(),
  meandu_id: z.string().optional().nullable(),
  drink_categories: z.lazy(() => drinks_to_drink_categoriesUncheckedCreateNestedManyWithoutDrinksInputSchema).optional()
}).strict();

export const drinksCreateOrConnectWithoutPricesInputSchema: z.ZodType<Prisma.drinksCreateOrConnectWithoutPricesInput> = z.object({
  where: z.lazy(() => drinksWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => drinksCreateWithoutPricesInputSchema),z.lazy(() => drinksUncheckedCreateWithoutPricesInputSchema) ]),
}).strict();

export const menusUpsertWithoutPricesInputSchema: z.ZodType<Prisma.menusUpsertWithoutPricesInput> = z.object({
  update: z.union([ z.lazy(() => menusUpdateWithoutPricesInputSchema),z.lazy(() => menusUncheckedUpdateWithoutPricesInputSchema) ]),
  create: z.union([ z.lazy(() => menusCreateWithoutPricesInputSchema),z.lazy(() => menusUncheckedCreateWithoutPricesInputSchema) ]),
}).strict();

export const menusUpdateWithoutPricesInputSchema: z.ZodType<Prisma.menusUpdateWithoutPricesInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  isDiscounted: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  daysOfWeek: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  timeStart: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  timeEnd: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locations: z.lazy(() => locationsUpdateOneRequiredWithoutMenusNestedInputSchema).optional()
}).strict();

export const menusUncheckedUpdateWithoutPricesInputSchema: z.ZodType<Prisma.menusUncheckedUpdateWithoutPricesInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  isDiscounted: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationsId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  daysOfWeek: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  timeStart: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  timeEnd: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const locationsUpsertWithoutPricesInputSchema: z.ZodType<Prisma.locationsUpsertWithoutPricesInput> = z.object({
  update: z.union([ z.lazy(() => locationsUpdateWithoutPricesInputSchema),z.lazy(() => locationsUncheckedUpdateWithoutPricesInputSchema) ]),
  create: z.union([ z.lazy(() => locationsCreateWithoutPricesInputSchema),z.lazy(() => locationsUncheckedCreateWithoutPricesInputSchema) ]),
}).strict();

export const locationsUpdateWithoutPricesInputSchema: z.ZodType<Prisma.locationsUpdateWithoutPricesInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id_1: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id_2: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  latitude: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  longitude: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  date_created: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_place_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currency: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locale: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_menu_url: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  last_updated: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_operational: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  validityScore: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hidden: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  menus: z.lazy(() => menusUpdateManyWithoutLocationsNestedInputSchema).optional(),
  drinks: z.lazy(() => drinksUpdateManyWithoutLocationsNestedInputSchema).optional(),
  drink_categories: z.lazy(() => drink_categoriesUpdateManyWithoutLocationsNestedInputSchema).optional(),
  contact: z.lazy(() => contactUpdateManyWithoutLocationsNestedInputSchema).optional()
}).strict();

export const locationsUncheckedUpdateWithoutPricesInputSchema: z.ZodType<Prisma.locationsUncheckedUpdateWithoutPricesInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id_1: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id_2: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  latitude: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  longitude: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  date_created: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_place_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currency: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locale: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_menu_url: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  last_updated: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_operational: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  validityScore: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hidden: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  menus: z.lazy(() => menusUncheckedUpdateManyWithoutLocationsNestedInputSchema).optional(),
  drinks: z.lazy(() => drinksUncheckedUpdateManyWithoutLocationsNestedInputSchema).optional(),
  drink_categories: z.lazy(() => drink_categoriesUncheckedUpdateManyWithoutLocationsNestedInputSchema).optional(),
  contact: z.lazy(() => contactUncheckedUpdateManyWithoutLocationsNestedInputSchema).optional()
}).strict();

export const drinksUpsertWithoutPricesInputSchema: z.ZodType<Prisma.drinksUpsertWithoutPricesInput> = z.object({
  update: z.union([ z.lazy(() => drinksUpdateWithoutPricesInputSchema),z.lazy(() => drinksUncheckedUpdateWithoutPricesInputSchema) ]),
  create: z.union([ z.lazy(() => drinksCreateWithoutPricesInputSchema),z.lazy(() => drinksUncheckedCreateWithoutPricesInputSchema) ]),
}).strict();

export const drinksUpdateWithoutPricesInputSchema: z.ZodType<Prisma.drinksUpdateWithoutPricesInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locations: z.lazy(() => locationsUpdateOneRequiredWithoutDrinksNestedInputSchema).optional(),
  drink_categories: z.lazy(() => drinks_to_drink_categoriesUpdateManyWithoutDrinksNestedInputSchema).optional()
}).strict();

export const drinksUncheckedUpdateWithoutPricesInputSchema: z.ZodType<Prisma.drinksUncheckedUpdateWithoutPricesInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  locationsId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  drink_categories: z.lazy(() => drinks_to_drink_categoriesUncheckedUpdateManyWithoutDrinksNestedInputSchema).optional()
}).strict();

export const locationsCreateWithoutMenusInputSchema: z.ZodType<Prisma.locationsCreateWithoutMenusInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  meandu_id: z.string().optional().nullable(),
  meandu_id_1: z.string().optional().nullable(),
  meandu_id_2: z.string().optional().nullable(),
  name: z.string().optional().nullable(),
  address: z.string().optional().nullable(),
  latitude: z.number().optional().nullable(),
  longitude: z.number().optional().nullable(),
  date_created: z.coerce.date().optional().nullable(),
  google_name: z.string().optional().nullable(),
  google_place_id: z.string().optional().nullable(),
  currency: z.string().optional().nullable(),
  locale: z.string().optional().nullable(),
  meandu_menu_url: z.string().optional().nullable(),
  last_updated: z.coerce.date().optional().nullable(),
  google_operational: z.string().optional().nullable(),
  validityScore: z.number().int().optional().nullable(),
  hidden: z.boolean().optional().nullable(),
  prices: z.lazy(() => pricesCreateNestedManyWithoutLocationsInputSchema).optional(),
  drinks: z.lazy(() => drinksCreateNestedManyWithoutLocationsInputSchema).optional(),
  drink_categories: z.lazy(() => drink_categoriesCreateNestedManyWithoutLocationsInputSchema).optional(),
  contact: z.lazy(() => contactCreateNestedManyWithoutLocationsInputSchema).optional()
}).strict();

export const locationsUncheckedCreateWithoutMenusInputSchema: z.ZodType<Prisma.locationsUncheckedCreateWithoutMenusInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  meandu_id: z.string().optional().nullable(),
  meandu_id_1: z.string().optional().nullable(),
  meandu_id_2: z.string().optional().nullable(),
  name: z.string().optional().nullable(),
  address: z.string().optional().nullable(),
  latitude: z.number().optional().nullable(),
  longitude: z.number().optional().nullable(),
  date_created: z.coerce.date().optional().nullable(),
  google_name: z.string().optional().nullable(),
  google_place_id: z.string().optional().nullable(),
  currency: z.string().optional().nullable(),
  locale: z.string().optional().nullable(),
  meandu_menu_url: z.string().optional().nullable(),
  last_updated: z.coerce.date().optional().nullable(),
  google_operational: z.string().optional().nullable(),
  validityScore: z.number().int().optional().nullable(),
  hidden: z.boolean().optional().nullable(),
  prices: z.lazy(() => pricesUncheckedCreateNestedManyWithoutLocationsInputSchema).optional(),
  drinks: z.lazy(() => drinksUncheckedCreateNestedManyWithoutLocationsInputSchema).optional(),
  drink_categories: z.lazy(() => drink_categoriesUncheckedCreateNestedManyWithoutLocationsInputSchema).optional(),
  contact: z.lazy(() => contactUncheckedCreateNestedManyWithoutLocationsInputSchema).optional()
}).strict();

export const locationsCreateOrConnectWithoutMenusInputSchema: z.ZodType<Prisma.locationsCreateOrConnectWithoutMenusInput> = z.object({
  where: z.lazy(() => locationsWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => locationsCreateWithoutMenusInputSchema),z.lazy(() => locationsUncheckedCreateWithoutMenusInputSchema) ]),
}).strict();

export const pricesCreateWithoutMenusInputSchema: z.ZodType<Prisma.pricesCreateWithoutMenusInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  price: z.number().optional().nullable(),
  drink_variant_name: z.string().optional().nullable(),
  locations: z.lazy(() => locationsCreateNestedOneWithoutPricesInputSchema),
  drinks: z.lazy(() => drinksCreateNestedOneWithoutPricesInputSchema)
}).strict();

export const pricesUncheckedCreateWithoutMenusInputSchema: z.ZodType<Prisma.pricesUncheckedCreateWithoutMenusInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  locationsId: z.string(),
  price: z.number().optional().nullable(),
  drink_id: z.string(),
  drink_variant_name: z.string().optional().nullable()
}).strict();

export const pricesCreateOrConnectWithoutMenusInputSchema: z.ZodType<Prisma.pricesCreateOrConnectWithoutMenusInput> = z.object({
  where: z.lazy(() => pricesWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => pricesCreateWithoutMenusInputSchema),z.lazy(() => pricesUncheckedCreateWithoutMenusInputSchema) ]),
}).strict();

export const pricesCreateManyMenusInputEnvelopeSchema: z.ZodType<Prisma.pricesCreateManyMenusInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => pricesCreateManyMenusInputSchema),z.lazy(() => pricesCreateManyMenusInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const locationsUpsertWithoutMenusInputSchema: z.ZodType<Prisma.locationsUpsertWithoutMenusInput> = z.object({
  update: z.union([ z.lazy(() => locationsUpdateWithoutMenusInputSchema),z.lazy(() => locationsUncheckedUpdateWithoutMenusInputSchema) ]),
  create: z.union([ z.lazy(() => locationsCreateWithoutMenusInputSchema),z.lazy(() => locationsUncheckedCreateWithoutMenusInputSchema) ]),
}).strict();

export const locationsUpdateWithoutMenusInputSchema: z.ZodType<Prisma.locationsUpdateWithoutMenusInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id_1: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id_2: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  latitude: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  longitude: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  date_created: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_place_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currency: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locale: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_menu_url: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  last_updated: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_operational: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  validityScore: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hidden: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  prices: z.lazy(() => pricesUpdateManyWithoutLocationsNestedInputSchema).optional(),
  drinks: z.lazy(() => drinksUpdateManyWithoutLocationsNestedInputSchema).optional(),
  drink_categories: z.lazy(() => drink_categoriesUpdateManyWithoutLocationsNestedInputSchema).optional(),
  contact: z.lazy(() => contactUpdateManyWithoutLocationsNestedInputSchema).optional()
}).strict();

export const locationsUncheckedUpdateWithoutMenusInputSchema: z.ZodType<Prisma.locationsUncheckedUpdateWithoutMenusInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id_1: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id_2: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  latitude: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  longitude: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  date_created: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_place_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currency: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locale: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_menu_url: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  last_updated: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_operational: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  validityScore: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hidden: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  prices: z.lazy(() => pricesUncheckedUpdateManyWithoutLocationsNestedInputSchema).optional(),
  drinks: z.lazy(() => drinksUncheckedUpdateManyWithoutLocationsNestedInputSchema).optional(),
  drink_categories: z.lazy(() => drink_categoriesUncheckedUpdateManyWithoutLocationsNestedInputSchema).optional(),
  contact: z.lazy(() => contactUncheckedUpdateManyWithoutLocationsNestedInputSchema).optional()
}).strict();

export const pricesUpsertWithWhereUniqueWithoutMenusInputSchema: z.ZodType<Prisma.pricesUpsertWithWhereUniqueWithoutMenusInput> = z.object({
  where: z.lazy(() => pricesWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => pricesUpdateWithoutMenusInputSchema),z.lazy(() => pricesUncheckedUpdateWithoutMenusInputSchema) ]),
  create: z.union([ z.lazy(() => pricesCreateWithoutMenusInputSchema),z.lazy(() => pricesUncheckedCreateWithoutMenusInputSchema) ]),
}).strict();

export const pricesUpdateWithWhereUniqueWithoutMenusInputSchema: z.ZodType<Prisma.pricesUpdateWithWhereUniqueWithoutMenusInput> = z.object({
  where: z.lazy(() => pricesWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => pricesUpdateWithoutMenusInputSchema),z.lazy(() => pricesUncheckedUpdateWithoutMenusInputSchema) ]),
}).strict();

export const pricesUpdateManyWithWhereWithoutMenusInputSchema: z.ZodType<Prisma.pricesUpdateManyWithWhereWithoutMenusInput> = z.object({
  where: z.lazy(() => pricesScalarWhereInputSchema),
  data: z.union([ z.lazy(() => pricesUpdateManyMutationInputSchema),z.lazy(() => pricesUncheckedUpdateManyWithoutPricesInputSchema) ]),
}).strict();

export const UserCreateWithoutAccountsInputSchema: z.ZodType<Prisma.UserCreateWithoutAccountsInput> = z.object({
  id: z.string().cuid().optional(),
  name: z.string().optional().nullable(),
  email: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  apiKey: z.string().uuid().optional().nullable(),
  sessions: z.lazy(() => SessionCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutAccountsInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutAccountsInput> = z.object({
  id: z.string().cuid().optional(),
  name: z.string().optional().nullable(),
  email: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  apiKey: z.string().uuid().optional().nullable(),
  sessions: z.lazy(() => SessionUncheckedCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutAccountsInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutAccountsInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutAccountsInputSchema),z.lazy(() => UserUncheckedCreateWithoutAccountsInputSchema) ]),
}).strict();

export const UserUpsertWithoutAccountsInputSchema: z.ZodType<Prisma.UserUpsertWithoutAccountsInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutAccountsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutAccountsInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutAccountsInputSchema),z.lazy(() => UserUncheckedCreateWithoutAccountsInputSchema) ]),
}).strict();

export const UserUpdateWithoutAccountsInputSchema: z.ZodType<Prisma.UserUpdateWithoutAccountsInput> = z.object({
  id: z.union([ z.string().cuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  apiKey: z.union([ z.string().uuid(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sessions: z.lazy(() => SessionUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutAccountsInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutAccountsInput> = z.object({
  id: z.union([ z.string().cuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  apiKey: z.union([ z.string().uuid(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sessions: z.lazy(() => SessionUncheckedUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const UserCreateWithoutSessionsInputSchema: z.ZodType<Prisma.UserCreateWithoutSessionsInput> = z.object({
  id: z.string().cuid().optional(),
  name: z.string().optional().nullable(),
  email: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  apiKey: z.string().uuid().optional().nullable(),
  accounts: z.lazy(() => AccountCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutSessionsInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutSessionsInput> = z.object({
  id: z.string().cuid().optional(),
  name: z.string().optional().nullable(),
  email: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  apiKey: z.string().uuid().optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutSessionsInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutSessionsInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutSessionsInputSchema),z.lazy(() => UserUncheckedCreateWithoutSessionsInputSchema) ]),
}).strict();

export const UserUpsertWithoutSessionsInputSchema: z.ZodType<Prisma.UserUpsertWithoutSessionsInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutSessionsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutSessionsInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutSessionsInputSchema),z.lazy(() => UserUncheckedCreateWithoutSessionsInputSchema) ]),
}).strict();

export const UserUpdateWithoutSessionsInputSchema: z.ZodType<Prisma.UserUpdateWithoutSessionsInput> = z.object({
  id: z.union([ z.string().cuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  apiKey: z.union([ z.string().uuid(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutSessionsInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutSessionsInput> = z.object({
  id: z.union([ z.string().cuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  apiKey: z.union([ z.string().uuid(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const AccountCreateWithoutUserInputSchema: z.ZodType<Prisma.AccountCreateWithoutUserInput> = z.object({
  id: z.string().cuid().optional(),
  type: z.string(),
  provider: z.string(),
  providerAccountId: z.string(),
  refresh_token: z.string().optional().nullable(),
  access_token: z.string().optional().nullable(),
  expires_at: z.number().int().optional().nullable(),
  token_type: z.string().optional().nullable(),
  scope: z.string().optional().nullable(),
  id_token: z.string().optional().nullable(),
  session_state: z.string().optional().nullable()
}).strict();

export const AccountUncheckedCreateWithoutUserInputSchema: z.ZodType<Prisma.AccountUncheckedCreateWithoutUserInput> = z.object({
  id: z.string().cuid().optional(),
  type: z.string(),
  provider: z.string(),
  providerAccountId: z.string(),
  refresh_token: z.string().optional().nullable(),
  access_token: z.string().optional().nullable(),
  expires_at: z.number().int().optional().nullable(),
  token_type: z.string().optional().nullable(),
  scope: z.string().optional().nullable(),
  id_token: z.string().optional().nullable(),
  session_state: z.string().optional().nullable()
}).strict();

export const AccountCreateOrConnectWithoutUserInputSchema: z.ZodType<Prisma.AccountCreateOrConnectWithoutUserInput> = z.object({
  where: z.lazy(() => AccountWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => AccountCreateWithoutUserInputSchema),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const AccountCreateManyUserInputEnvelopeSchema: z.ZodType<Prisma.AccountCreateManyUserInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => AccountCreateManyUserInputSchema),z.lazy(() => AccountCreateManyUserInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const SessionCreateWithoutUserInputSchema: z.ZodType<Prisma.SessionCreateWithoutUserInput> = z.object({
  id: z.string().cuid().optional(),
  sessionToken: z.string(),
  expires: z.coerce.date()
}).strict();

export const SessionUncheckedCreateWithoutUserInputSchema: z.ZodType<Prisma.SessionUncheckedCreateWithoutUserInput> = z.object({
  id: z.string().cuid().optional(),
  sessionToken: z.string(),
  expires: z.coerce.date()
}).strict();

export const SessionCreateOrConnectWithoutUserInputSchema: z.ZodType<Prisma.SessionCreateOrConnectWithoutUserInput> = z.object({
  where: z.lazy(() => SessionWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => SessionCreateWithoutUserInputSchema),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const SessionCreateManyUserInputEnvelopeSchema: z.ZodType<Prisma.SessionCreateManyUserInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => SessionCreateManyUserInputSchema),z.lazy(() => SessionCreateManyUserInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const AccountUpsertWithWhereUniqueWithoutUserInputSchema: z.ZodType<Prisma.AccountUpsertWithWhereUniqueWithoutUserInput> = z.object({
  where: z.lazy(() => AccountWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => AccountUpdateWithoutUserInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutUserInputSchema) ]),
  create: z.union([ z.lazy(() => AccountCreateWithoutUserInputSchema),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const AccountUpdateWithWhereUniqueWithoutUserInputSchema: z.ZodType<Prisma.AccountUpdateWithWhereUniqueWithoutUserInput> = z.object({
  where: z.lazy(() => AccountWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => AccountUpdateWithoutUserInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutUserInputSchema) ]),
}).strict();

export const AccountUpdateManyWithWhereWithoutUserInputSchema: z.ZodType<Prisma.AccountUpdateManyWithWhereWithoutUserInput> = z.object({
  where: z.lazy(() => AccountScalarWhereInputSchema),
  data: z.union([ z.lazy(() => AccountUpdateManyMutationInputSchema),z.lazy(() => AccountUncheckedUpdateManyWithoutAccountsInputSchema) ]),
}).strict();

export const AccountScalarWhereInputSchema: z.ZodType<Prisma.AccountScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => AccountScalarWhereInputSchema),z.lazy(() => AccountScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => AccountScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AccountScalarWhereInputSchema),z.lazy(() => AccountScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  userId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  provider: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  providerAccountId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  refresh_token: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  access_token: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  expires_at: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  token_type: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  scope: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  id_token: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  session_state: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const SessionUpsertWithWhereUniqueWithoutUserInputSchema: z.ZodType<Prisma.SessionUpsertWithWhereUniqueWithoutUserInput> = z.object({
  where: z.lazy(() => SessionWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => SessionUpdateWithoutUserInputSchema),z.lazy(() => SessionUncheckedUpdateWithoutUserInputSchema) ]),
  create: z.union([ z.lazy(() => SessionCreateWithoutUserInputSchema),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const SessionUpdateWithWhereUniqueWithoutUserInputSchema: z.ZodType<Prisma.SessionUpdateWithWhereUniqueWithoutUserInput> = z.object({
  where: z.lazy(() => SessionWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => SessionUpdateWithoutUserInputSchema),z.lazy(() => SessionUncheckedUpdateWithoutUserInputSchema) ]),
}).strict();

export const SessionUpdateManyWithWhereWithoutUserInputSchema: z.ZodType<Prisma.SessionUpdateManyWithWhereWithoutUserInput> = z.object({
  where: z.lazy(() => SessionScalarWhereInputSchema),
  data: z.union([ z.lazy(() => SessionUpdateManyMutationInputSchema),z.lazy(() => SessionUncheckedUpdateManyWithoutSessionsInputSchema) ]),
}).strict();

export const SessionScalarWhereInputSchema: z.ZodType<Prisma.SessionScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => SessionScalarWhereInputSchema),z.lazy(() => SessionScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SessionScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SessionScalarWhereInputSchema),z.lazy(() => SessionScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  sessionToken: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  userId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  expires: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const locationsCreateWithoutContactInputSchema: z.ZodType<Prisma.locationsCreateWithoutContactInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  meandu_id: z.string().optional().nullable(),
  meandu_id_1: z.string().optional().nullable(),
  meandu_id_2: z.string().optional().nullable(),
  name: z.string().optional().nullable(),
  address: z.string().optional().nullable(),
  latitude: z.number().optional().nullable(),
  longitude: z.number().optional().nullable(),
  date_created: z.coerce.date().optional().nullable(),
  google_name: z.string().optional().nullable(),
  google_place_id: z.string().optional().nullable(),
  currency: z.string().optional().nullable(),
  locale: z.string().optional().nullable(),
  meandu_menu_url: z.string().optional().nullable(),
  last_updated: z.coerce.date().optional().nullable(),
  google_operational: z.string().optional().nullable(),
  validityScore: z.number().int().optional().nullable(),
  hidden: z.boolean().optional().nullable(),
  menus: z.lazy(() => menusCreateNestedManyWithoutLocationsInputSchema).optional(),
  prices: z.lazy(() => pricesCreateNestedManyWithoutLocationsInputSchema).optional(),
  drinks: z.lazy(() => drinksCreateNestedManyWithoutLocationsInputSchema).optional(),
  drink_categories: z.lazy(() => drink_categoriesCreateNestedManyWithoutLocationsInputSchema).optional()
}).strict();

export const locationsUncheckedCreateWithoutContactInputSchema: z.ZodType<Prisma.locationsUncheckedCreateWithoutContactInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  meandu_id: z.string().optional().nullable(),
  meandu_id_1: z.string().optional().nullable(),
  meandu_id_2: z.string().optional().nullable(),
  name: z.string().optional().nullable(),
  address: z.string().optional().nullable(),
  latitude: z.number().optional().nullable(),
  longitude: z.number().optional().nullable(),
  date_created: z.coerce.date().optional().nullable(),
  google_name: z.string().optional().nullable(),
  google_place_id: z.string().optional().nullable(),
  currency: z.string().optional().nullable(),
  locale: z.string().optional().nullable(),
  meandu_menu_url: z.string().optional().nullable(),
  last_updated: z.coerce.date().optional().nullable(),
  google_operational: z.string().optional().nullable(),
  validityScore: z.number().int().optional().nullable(),
  hidden: z.boolean().optional().nullable(),
  menus: z.lazy(() => menusUncheckedCreateNestedManyWithoutLocationsInputSchema).optional(),
  prices: z.lazy(() => pricesUncheckedCreateNestedManyWithoutLocationsInputSchema).optional(),
  drinks: z.lazy(() => drinksUncheckedCreateNestedManyWithoutLocationsInputSchema).optional(),
  drink_categories: z.lazy(() => drink_categoriesUncheckedCreateNestedManyWithoutLocationsInputSchema).optional()
}).strict();

export const locationsCreateOrConnectWithoutContactInputSchema: z.ZodType<Prisma.locationsCreateOrConnectWithoutContactInput> = z.object({
  where: z.lazy(() => locationsWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => locationsCreateWithoutContactInputSchema),z.lazy(() => locationsUncheckedCreateWithoutContactInputSchema) ]),
}).strict();

export const locationsUpsertWithoutContactInputSchema: z.ZodType<Prisma.locationsUpsertWithoutContactInput> = z.object({
  update: z.union([ z.lazy(() => locationsUpdateWithoutContactInputSchema),z.lazy(() => locationsUncheckedUpdateWithoutContactInputSchema) ]),
  create: z.union([ z.lazy(() => locationsCreateWithoutContactInputSchema),z.lazy(() => locationsUncheckedCreateWithoutContactInputSchema) ]),
}).strict();

export const locationsUpdateWithoutContactInputSchema: z.ZodType<Prisma.locationsUpdateWithoutContactInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id_1: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id_2: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  latitude: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  longitude: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  date_created: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_place_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currency: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locale: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_menu_url: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  last_updated: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_operational: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  validityScore: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hidden: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  menus: z.lazy(() => menusUpdateManyWithoutLocationsNestedInputSchema).optional(),
  prices: z.lazy(() => pricesUpdateManyWithoutLocationsNestedInputSchema).optional(),
  drinks: z.lazy(() => drinksUpdateManyWithoutLocationsNestedInputSchema).optional(),
  drink_categories: z.lazy(() => drink_categoriesUpdateManyWithoutLocationsNestedInputSchema).optional()
}).strict();

export const locationsUncheckedUpdateWithoutContactInputSchema: z.ZodType<Prisma.locationsUncheckedUpdateWithoutContactInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id_1: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id_2: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  latitude: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  longitude: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  date_created: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_place_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currency: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locale: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_menu_url: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  last_updated: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  google_operational: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  validityScore: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hidden: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  menus: z.lazy(() => menusUncheckedUpdateManyWithoutLocationsNestedInputSchema).optional(),
  prices: z.lazy(() => pricesUncheckedUpdateManyWithoutLocationsNestedInputSchema).optional(),
  drinks: z.lazy(() => drinksUncheckedUpdateManyWithoutLocationsNestedInputSchema).optional(),
  drink_categories: z.lazy(() => drink_categoriesUncheckedUpdateManyWithoutLocationsNestedInputSchema).optional()
}).strict();

export const menusCreateManyLocationsInputSchema: z.ZodType<Prisma.menusCreateManyLocationsInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  name: z.string(),
  isDiscounted: z.boolean().optional().nullable(),
  daysOfWeek: z.number().int().optional().nullable(),
  timeStart: z.number().int().optional().nullable(),
  timeEnd: z.number().int().optional().nullable(),
  meandu_id: z.string().optional().nullable()
}).strict();

export const pricesCreateManyLocationsInputSchema: z.ZodType<Prisma.pricesCreateManyLocationsInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  menusId: z.string().optional().nullable(),
  price: z.number().optional().nullable(),
  drink_id: z.string(),
  drink_variant_name: z.string().optional().nullable()
}).strict();

export const drinksCreateManyLocationsInputSchema: z.ZodType<Prisma.drinksCreateManyLocationsInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  name: z.string(),
  meandu_id: z.string().optional().nullable()
}).strict();

export const drink_categoriesCreateManyLocationsInputSchema: z.ZodType<Prisma.drink_categoriesCreateManyLocationsInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  name: z.string(),
  meandu_id: z.string().optional().nullable()
}).strict();

export const contactCreateManyLocationsInputSchema: z.ZodType<Prisma.contactCreateManyLocationsInput> = z.object({
  id: z.string().uuid().optional(),
  createdAt: z.coerce.date().optional(),
  name: z.string(),
  email: z.string().optional().nullable(),
  message: z.string().optional().nullable(),
  x: z.string().optional().nullable(),
  linkedin: z.string().optional().nullable(),
  instagram: z.string().optional().nullable(),
  facebook: z.string().optional().nullable(),
  website: z.string().optional().nullable(),
  phone: z.string().optional().nullable()
}).strict();

export const menusUpdateWithoutLocationsInputSchema: z.ZodType<Prisma.menusUpdateWithoutLocationsInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  isDiscounted: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  daysOfWeek: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  timeStart: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  timeEnd: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  prices: z.lazy(() => pricesUpdateManyWithoutMenusNestedInputSchema).optional()
}).strict();

export const menusUncheckedUpdateWithoutLocationsInputSchema: z.ZodType<Prisma.menusUncheckedUpdateWithoutLocationsInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  isDiscounted: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  daysOfWeek: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  timeStart: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  timeEnd: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  prices: z.lazy(() => pricesUncheckedUpdateManyWithoutMenusNestedInputSchema).optional()
}).strict();

export const menusUncheckedUpdateManyWithoutMenusInputSchema: z.ZodType<Prisma.menusUncheckedUpdateManyWithoutMenusInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  isDiscounted: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  daysOfWeek: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  timeStart: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  timeEnd: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const pricesUpdateWithoutLocationsInputSchema: z.ZodType<Prisma.pricesUpdateWithoutLocationsInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  price: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  drink_variant_name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  menus: z.lazy(() => menusUpdateOneWithoutPricesNestedInputSchema).optional(),
  drinks: z.lazy(() => drinksUpdateOneRequiredWithoutPricesNestedInputSchema).optional()
}).strict();

export const pricesUncheckedUpdateWithoutLocationsInputSchema: z.ZodType<Prisma.pricesUncheckedUpdateWithoutLocationsInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  menusId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  price: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  drink_id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  drink_variant_name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const pricesUncheckedUpdateManyWithoutPricesInputSchema: z.ZodType<Prisma.pricesUncheckedUpdateManyWithoutPricesInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  menusId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  price: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  drink_id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  drink_variant_name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const drinksUpdateWithoutLocationsInputSchema: z.ZodType<Prisma.drinksUpdateWithoutLocationsInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  prices: z.lazy(() => pricesUpdateManyWithoutDrinksNestedInputSchema).optional(),
  drink_categories: z.lazy(() => drinks_to_drink_categoriesUpdateManyWithoutDrinksNestedInputSchema).optional()
}).strict();

export const drinksUncheckedUpdateWithoutLocationsInputSchema: z.ZodType<Prisma.drinksUncheckedUpdateWithoutLocationsInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  prices: z.lazy(() => pricesUncheckedUpdateManyWithoutDrinksNestedInputSchema).optional(),
  drink_categories: z.lazy(() => drinks_to_drink_categoriesUncheckedUpdateManyWithoutDrinksNestedInputSchema).optional()
}).strict();

export const drinksUncheckedUpdateManyWithoutDrinksInputSchema: z.ZodType<Prisma.drinksUncheckedUpdateManyWithoutDrinksInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const drink_categoriesUpdateWithoutLocationsInputSchema: z.ZodType<Prisma.drink_categoriesUpdateWithoutLocationsInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  drinks: z.lazy(() => drinks_to_drink_categoriesUpdateManyWithoutDrink_categoriesNestedInputSchema).optional()
}).strict();

export const drink_categoriesUncheckedUpdateWithoutLocationsInputSchema: z.ZodType<Prisma.drink_categoriesUncheckedUpdateWithoutLocationsInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  drinks: z.lazy(() => drinks_to_drink_categoriesUncheckedUpdateManyWithoutDrink_categoriesNestedInputSchema).optional()
}).strict();

export const drink_categoriesUncheckedUpdateManyWithoutDrink_categoriesInputSchema: z.ZodType<Prisma.drink_categoriesUncheckedUpdateManyWithoutDrink_categoriesInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  meandu_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const contactUpdateWithoutLocationsInputSchema: z.ZodType<Prisma.contactUpdateWithoutLocationsInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  message: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  x: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  linkedin: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instagram: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  facebook: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  website: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const contactUncheckedUpdateWithoutLocationsInputSchema: z.ZodType<Prisma.contactUncheckedUpdateWithoutLocationsInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  message: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  x: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  linkedin: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instagram: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  facebook: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  website: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const contactUncheckedUpdateManyWithoutContactInputSchema: z.ZodType<Prisma.contactUncheckedUpdateManyWithoutContactInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  message: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  x: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  linkedin: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instagram: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  facebook: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  website: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const drinks_to_drink_categoriesCreateManyDrink_categoriesInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesCreateManyDrink_categoriesInput> = z.object({
  drink_id: z.string()
}).strict();

export const drinks_to_drink_categoriesUpdateWithoutDrink_categoriesInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesUpdateWithoutDrink_categoriesInput> = z.object({
  drinks: z.lazy(() => drinksUpdateOneRequiredWithoutDrink_categoriesNestedInputSchema).optional()
}).strict();

export const drinks_to_drink_categoriesUncheckedUpdateWithoutDrink_categoriesInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesUncheckedUpdateWithoutDrink_categoriesInput> = z.object({
  drink_id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const drinks_to_drink_categoriesUncheckedUpdateManyWithoutDrinksInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesUncheckedUpdateManyWithoutDrinksInput> = z.object({
  drink_id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const pricesCreateManyDrinksInputSchema: z.ZodType<Prisma.pricesCreateManyDrinksInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  menusId: z.string().optional().nullable(),
  locationsId: z.string(),
  price: z.number().optional().nullable(),
  drink_variant_name: z.string().optional().nullable()
}).strict();

export const drinks_to_drink_categoriesCreateManyDrinksInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesCreateManyDrinksInput> = z.object({
  drink_category_id: z.string()
}).strict();

export const pricesUpdateWithoutDrinksInputSchema: z.ZodType<Prisma.pricesUpdateWithoutDrinksInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  price: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  drink_variant_name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  menus: z.lazy(() => menusUpdateOneWithoutPricesNestedInputSchema).optional(),
  locations: z.lazy(() => locationsUpdateOneRequiredWithoutPricesNestedInputSchema).optional()
}).strict();

export const pricesUncheckedUpdateWithoutDrinksInputSchema: z.ZodType<Prisma.pricesUncheckedUpdateWithoutDrinksInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  menusId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationsId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  price: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  drink_variant_name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const drinks_to_drink_categoriesUpdateWithoutDrinksInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesUpdateWithoutDrinksInput> = z.object({
  drink_categories: z.lazy(() => drink_categoriesUpdateOneRequiredWithoutDrinksNestedInputSchema).optional()
}).strict();

export const drinks_to_drink_categoriesUncheckedUpdateWithoutDrinksInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesUncheckedUpdateWithoutDrinksInput> = z.object({
  drink_category_id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const drinks_to_drink_categoriesUncheckedUpdateManyWithoutDrink_categoriesInputSchema: z.ZodType<Prisma.drinks_to_drink_categoriesUncheckedUpdateManyWithoutDrink_categoriesInput> = z.object({
  drink_category_id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const pricesCreateManyMenusInputSchema: z.ZodType<Prisma.pricesCreateManyMenusInput> = z.object({
  id: z.string().uuid().optional(),
  timestamp: z.coerce.date().optional(),
  locationsId: z.string(),
  price: z.number().optional().nullable(),
  drink_id: z.string(),
  drink_variant_name: z.string().optional().nullable()
}).strict();

export const pricesUpdateWithoutMenusInputSchema: z.ZodType<Prisma.pricesUpdateWithoutMenusInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  price: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  drink_variant_name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locations: z.lazy(() => locationsUpdateOneRequiredWithoutPricesNestedInputSchema).optional(),
  drinks: z.lazy(() => drinksUpdateOneRequiredWithoutPricesNestedInputSchema).optional()
}).strict();

export const pricesUncheckedUpdateWithoutMenusInputSchema: z.ZodType<Prisma.pricesUncheckedUpdateWithoutMenusInput> = z.object({
  id: z.union([ z.string().uuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  locationsId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  price: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  drink_id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  drink_variant_name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AccountCreateManyUserInputSchema: z.ZodType<Prisma.AccountCreateManyUserInput> = z.object({
  id: z.string().cuid().optional(),
  type: z.string(),
  provider: z.string(),
  providerAccountId: z.string(),
  refresh_token: z.string().optional().nullable(),
  access_token: z.string().optional().nullable(),
  expires_at: z.number().int().optional().nullable(),
  token_type: z.string().optional().nullable(),
  scope: z.string().optional().nullable(),
  id_token: z.string().optional().nullable(),
  session_state: z.string().optional().nullable()
}).strict();

export const SessionCreateManyUserInputSchema: z.ZodType<Prisma.SessionCreateManyUserInput> = z.object({
  id: z.string().cuid().optional(),
  sessionToken: z.string(),
  expires: z.coerce.date()
}).strict();

export const AccountUpdateWithoutUserInputSchema: z.ZodType<Prisma.AccountUpdateWithoutUserInput> = z.object({
  id: z.union([ z.string().cuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  provider: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  providerAccountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  refresh_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  access_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  expires_at: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  token_type: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  scope: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  id_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  session_state: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AccountUncheckedUpdateWithoutUserInputSchema: z.ZodType<Prisma.AccountUncheckedUpdateWithoutUserInput> = z.object({
  id: z.union([ z.string().cuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  provider: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  providerAccountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  refresh_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  access_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  expires_at: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  token_type: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  scope: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  id_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  session_state: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AccountUncheckedUpdateManyWithoutAccountsInputSchema: z.ZodType<Prisma.AccountUncheckedUpdateManyWithoutAccountsInput> = z.object({
  id: z.union([ z.string().cuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  provider: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  providerAccountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  refresh_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  access_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  expires_at: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  token_type: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  scope: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  id_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  session_state: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const SessionUpdateWithoutUserInputSchema: z.ZodType<Prisma.SessionUpdateWithoutUserInput> = z.object({
  id: z.union([ z.string().cuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sessionToken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SessionUncheckedUpdateWithoutUserInputSchema: z.ZodType<Prisma.SessionUncheckedUpdateWithoutUserInput> = z.object({
  id: z.union([ z.string().cuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sessionToken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SessionUncheckedUpdateManyWithoutSessionsInputSchema: z.ZodType<Prisma.SessionUncheckedUpdateManyWithoutSessionsInput> = z.object({
  id: z.union([ z.string().cuid(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sessionToken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

/////////////////////////////////////////
// ARGS
/////////////////////////////////////////

export const locationsFindFirstArgsSchema: z.ZodType<Prisma.locationsFindFirstArgs> = z.object({
  select: locationsSelectSchema.optional(),
  include: locationsIncludeSchema.optional(),
  where: locationsWhereInputSchema.optional(),
  orderBy: z.union([ locationsOrderByWithRelationAndSearchRelevanceInputSchema.array(),locationsOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: locationsWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ LocationsScalarFieldEnumSchema,LocationsScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const locationsFindFirstOrThrowArgsSchema: z.ZodType<Prisma.locationsFindFirstOrThrowArgs> = z.object({
  select: locationsSelectSchema.optional(),
  include: locationsIncludeSchema.optional(),
  where: locationsWhereInputSchema.optional(),
  orderBy: z.union([ locationsOrderByWithRelationAndSearchRelevanceInputSchema.array(),locationsOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: locationsWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ LocationsScalarFieldEnumSchema,LocationsScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const locationsFindManyArgsSchema: z.ZodType<Prisma.locationsFindManyArgs> = z.object({
  select: locationsSelectSchema.optional(),
  include: locationsIncludeSchema.optional(),
  where: locationsWhereInputSchema.optional(),
  orderBy: z.union([ locationsOrderByWithRelationAndSearchRelevanceInputSchema.array(),locationsOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: locationsWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ LocationsScalarFieldEnumSchema,LocationsScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const locationsAggregateArgsSchema: z.ZodType<Prisma.locationsAggregateArgs> = z.object({
  where: locationsWhereInputSchema.optional(),
  orderBy: z.union([ locationsOrderByWithRelationAndSearchRelevanceInputSchema.array(),locationsOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: locationsWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const locationsGroupByArgsSchema: z.ZodType<Prisma.locationsGroupByArgs> = z.object({
  where: locationsWhereInputSchema.optional(),
  orderBy: z.union([ locationsOrderByWithAggregationInputSchema.array(),locationsOrderByWithAggregationInputSchema ]).optional(),
  by: LocationsScalarFieldEnumSchema.array(),
  having: locationsScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const locationsFindUniqueArgsSchema: z.ZodType<Prisma.locationsFindUniqueArgs> = z.object({
  select: locationsSelectSchema.optional(),
  include: locationsIncludeSchema.optional(),
  where: locationsWhereUniqueInputSchema,
}).strict()

export const locationsFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.locationsFindUniqueOrThrowArgs> = z.object({
  select: locationsSelectSchema.optional(),
  include: locationsIncludeSchema.optional(),
  where: locationsWhereUniqueInputSchema,
}).strict()

export const drink_categoriesFindFirstArgsSchema: z.ZodType<Prisma.drink_categoriesFindFirstArgs> = z.object({
  select: drink_categoriesSelectSchema.optional(),
  include: drink_categoriesIncludeSchema.optional(),
  where: drink_categoriesWhereInputSchema.optional(),
  orderBy: z.union([ drink_categoriesOrderByWithRelationAndSearchRelevanceInputSchema.array(),drink_categoriesOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: drink_categoriesWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ Drink_categoriesScalarFieldEnumSchema,Drink_categoriesScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const drink_categoriesFindFirstOrThrowArgsSchema: z.ZodType<Prisma.drink_categoriesFindFirstOrThrowArgs> = z.object({
  select: drink_categoriesSelectSchema.optional(),
  include: drink_categoriesIncludeSchema.optional(),
  where: drink_categoriesWhereInputSchema.optional(),
  orderBy: z.union([ drink_categoriesOrderByWithRelationAndSearchRelevanceInputSchema.array(),drink_categoriesOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: drink_categoriesWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ Drink_categoriesScalarFieldEnumSchema,Drink_categoriesScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const drink_categoriesFindManyArgsSchema: z.ZodType<Prisma.drink_categoriesFindManyArgs> = z.object({
  select: drink_categoriesSelectSchema.optional(),
  include: drink_categoriesIncludeSchema.optional(),
  where: drink_categoriesWhereInputSchema.optional(),
  orderBy: z.union([ drink_categoriesOrderByWithRelationAndSearchRelevanceInputSchema.array(),drink_categoriesOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: drink_categoriesWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ Drink_categoriesScalarFieldEnumSchema,Drink_categoriesScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const drink_categoriesAggregateArgsSchema: z.ZodType<Prisma.drink_categoriesAggregateArgs> = z.object({
  where: drink_categoriesWhereInputSchema.optional(),
  orderBy: z.union([ drink_categoriesOrderByWithRelationAndSearchRelevanceInputSchema.array(),drink_categoriesOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: drink_categoriesWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const drink_categoriesGroupByArgsSchema: z.ZodType<Prisma.drink_categoriesGroupByArgs> = z.object({
  where: drink_categoriesWhereInputSchema.optional(),
  orderBy: z.union([ drink_categoriesOrderByWithAggregationInputSchema.array(),drink_categoriesOrderByWithAggregationInputSchema ]).optional(),
  by: Drink_categoriesScalarFieldEnumSchema.array(),
  having: drink_categoriesScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const drink_categoriesFindUniqueArgsSchema: z.ZodType<Prisma.drink_categoriesFindUniqueArgs> = z.object({
  select: drink_categoriesSelectSchema.optional(),
  include: drink_categoriesIncludeSchema.optional(),
  where: drink_categoriesWhereUniqueInputSchema,
}).strict()

export const drink_categoriesFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.drink_categoriesFindUniqueOrThrowArgs> = z.object({
  select: drink_categoriesSelectSchema.optional(),
  include: drink_categoriesIncludeSchema.optional(),
  where: drink_categoriesWhereUniqueInputSchema,
}).strict()

export const drinksFindFirstArgsSchema: z.ZodType<Prisma.drinksFindFirstArgs> = z.object({
  select: drinksSelectSchema.optional(),
  include: drinksIncludeSchema.optional(),
  where: drinksWhereInputSchema.optional(),
  orderBy: z.union([ drinksOrderByWithRelationAndSearchRelevanceInputSchema.array(),drinksOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: drinksWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ DrinksScalarFieldEnumSchema,DrinksScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const drinksFindFirstOrThrowArgsSchema: z.ZodType<Prisma.drinksFindFirstOrThrowArgs> = z.object({
  select: drinksSelectSchema.optional(),
  include: drinksIncludeSchema.optional(),
  where: drinksWhereInputSchema.optional(),
  orderBy: z.union([ drinksOrderByWithRelationAndSearchRelevanceInputSchema.array(),drinksOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: drinksWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ DrinksScalarFieldEnumSchema,DrinksScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const drinksFindManyArgsSchema: z.ZodType<Prisma.drinksFindManyArgs> = z.object({
  select: drinksSelectSchema.optional(),
  include: drinksIncludeSchema.optional(),
  where: drinksWhereInputSchema.optional(),
  orderBy: z.union([ drinksOrderByWithRelationAndSearchRelevanceInputSchema.array(),drinksOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: drinksWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ DrinksScalarFieldEnumSchema,DrinksScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const drinksAggregateArgsSchema: z.ZodType<Prisma.drinksAggregateArgs> = z.object({
  where: drinksWhereInputSchema.optional(),
  orderBy: z.union([ drinksOrderByWithRelationAndSearchRelevanceInputSchema.array(),drinksOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: drinksWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const drinksGroupByArgsSchema: z.ZodType<Prisma.drinksGroupByArgs> = z.object({
  where: drinksWhereInputSchema.optional(),
  orderBy: z.union([ drinksOrderByWithAggregationInputSchema.array(),drinksOrderByWithAggregationInputSchema ]).optional(),
  by: DrinksScalarFieldEnumSchema.array(),
  having: drinksScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const drinksFindUniqueArgsSchema: z.ZodType<Prisma.drinksFindUniqueArgs> = z.object({
  select: drinksSelectSchema.optional(),
  include: drinksIncludeSchema.optional(),
  where: drinksWhereUniqueInputSchema,
}).strict()

export const drinksFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.drinksFindUniqueOrThrowArgs> = z.object({
  select: drinksSelectSchema.optional(),
  include: drinksIncludeSchema.optional(),
  where: drinksWhereUniqueInputSchema,
}).strict()

export const drinks_to_drink_categoriesFindFirstArgsSchema: z.ZodType<Prisma.drinks_to_drink_categoriesFindFirstArgs> = z.object({
  select: drinks_to_drink_categoriesSelectSchema.optional(),
  include: drinks_to_drink_categoriesIncludeSchema.optional(),
  where: drinks_to_drink_categoriesWhereInputSchema.optional(),
  orderBy: z.union([ drinks_to_drink_categoriesOrderByWithRelationAndSearchRelevanceInputSchema.array(),drinks_to_drink_categoriesOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: drinks_to_drink_categoriesWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ Drinks_to_drink_categoriesScalarFieldEnumSchema,Drinks_to_drink_categoriesScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const drinks_to_drink_categoriesFindFirstOrThrowArgsSchema: z.ZodType<Prisma.drinks_to_drink_categoriesFindFirstOrThrowArgs> = z.object({
  select: drinks_to_drink_categoriesSelectSchema.optional(),
  include: drinks_to_drink_categoriesIncludeSchema.optional(),
  where: drinks_to_drink_categoriesWhereInputSchema.optional(),
  orderBy: z.union([ drinks_to_drink_categoriesOrderByWithRelationAndSearchRelevanceInputSchema.array(),drinks_to_drink_categoriesOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: drinks_to_drink_categoriesWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ Drinks_to_drink_categoriesScalarFieldEnumSchema,Drinks_to_drink_categoriesScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const drinks_to_drink_categoriesFindManyArgsSchema: z.ZodType<Prisma.drinks_to_drink_categoriesFindManyArgs> = z.object({
  select: drinks_to_drink_categoriesSelectSchema.optional(),
  include: drinks_to_drink_categoriesIncludeSchema.optional(),
  where: drinks_to_drink_categoriesWhereInputSchema.optional(),
  orderBy: z.union([ drinks_to_drink_categoriesOrderByWithRelationAndSearchRelevanceInputSchema.array(),drinks_to_drink_categoriesOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: drinks_to_drink_categoriesWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ Drinks_to_drink_categoriesScalarFieldEnumSchema,Drinks_to_drink_categoriesScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const drinks_to_drink_categoriesAggregateArgsSchema: z.ZodType<Prisma.drinks_to_drink_categoriesAggregateArgs> = z.object({
  where: drinks_to_drink_categoriesWhereInputSchema.optional(),
  orderBy: z.union([ drinks_to_drink_categoriesOrderByWithRelationAndSearchRelevanceInputSchema.array(),drinks_to_drink_categoriesOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: drinks_to_drink_categoriesWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const drinks_to_drink_categoriesGroupByArgsSchema: z.ZodType<Prisma.drinks_to_drink_categoriesGroupByArgs> = z.object({
  where: drinks_to_drink_categoriesWhereInputSchema.optional(),
  orderBy: z.union([ drinks_to_drink_categoriesOrderByWithAggregationInputSchema.array(),drinks_to_drink_categoriesOrderByWithAggregationInputSchema ]).optional(),
  by: Drinks_to_drink_categoriesScalarFieldEnumSchema.array(),
  having: drinks_to_drink_categoriesScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const drinks_to_drink_categoriesFindUniqueArgsSchema: z.ZodType<Prisma.drinks_to_drink_categoriesFindUniqueArgs> = z.object({
  select: drinks_to_drink_categoriesSelectSchema.optional(),
  include: drinks_to_drink_categoriesIncludeSchema.optional(),
  where: drinks_to_drink_categoriesWhereUniqueInputSchema,
}).strict()

export const drinks_to_drink_categoriesFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.drinks_to_drink_categoriesFindUniqueOrThrowArgs> = z.object({
  select: drinks_to_drink_categoriesSelectSchema.optional(),
  include: drinks_to_drink_categoriesIncludeSchema.optional(),
  where: drinks_to_drink_categoriesWhereUniqueInputSchema,
}).strict()

export const pricesFindFirstArgsSchema: z.ZodType<Prisma.pricesFindFirstArgs> = z.object({
  select: pricesSelectSchema.optional(),
  include: pricesIncludeSchema.optional(),
  where: pricesWhereInputSchema.optional(),
  orderBy: z.union([ pricesOrderByWithRelationAndSearchRelevanceInputSchema.array(),pricesOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: pricesWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PricesScalarFieldEnumSchema,PricesScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const pricesFindFirstOrThrowArgsSchema: z.ZodType<Prisma.pricesFindFirstOrThrowArgs> = z.object({
  select: pricesSelectSchema.optional(),
  include: pricesIncludeSchema.optional(),
  where: pricesWhereInputSchema.optional(),
  orderBy: z.union([ pricesOrderByWithRelationAndSearchRelevanceInputSchema.array(),pricesOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: pricesWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PricesScalarFieldEnumSchema,PricesScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const pricesFindManyArgsSchema: z.ZodType<Prisma.pricesFindManyArgs> = z.object({
  select: pricesSelectSchema.optional(),
  include: pricesIncludeSchema.optional(),
  where: pricesWhereInputSchema.optional(),
  orderBy: z.union([ pricesOrderByWithRelationAndSearchRelevanceInputSchema.array(),pricesOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: pricesWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PricesScalarFieldEnumSchema,PricesScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const pricesAggregateArgsSchema: z.ZodType<Prisma.pricesAggregateArgs> = z.object({
  where: pricesWhereInputSchema.optional(),
  orderBy: z.union([ pricesOrderByWithRelationAndSearchRelevanceInputSchema.array(),pricesOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: pricesWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const pricesGroupByArgsSchema: z.ZodType<Prisma.pricesGroupByArgs> = z.object({
  where: pricesWhereInputSchema.optional(),
  orderBy: z.union([ pricesOrderByWithAggregationInputSchema.array(),pricesOrderByWithAggregationInputSchema ]).optional(),
  by: PricesScalarFieldEnumSchema.array(),
  having: pricesScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const pricesFindUniqueArgsSchema: z.ZodType<Prisma.pricesFindUniqueArgs> = z.object({
  select: pricesSelectSchema.optional(),
  include: pricesIncludeSchema.optional(),
  where: pricesWhereUniqueInputSchema,
}).strict()

export const pricesFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.pricesFindUniqueOrThrowArgs> = z.object({
  select: pricesSelectSchema.optional(),
  include: pricesIncludeSchema.optional(),
  where: pricesWhereUniqueInputSchema,
}).strict()

export const menusFindFirstArgsSchema: z.ZodType<Prisma.menusFindFirstArgs> = z.object({
  select: menusSelectSchema.optional(),
  include: menusIncludeSchema.optional(),
  where: menusWhereInputSchema.optional(),
  orderBy: z.union([ menusOrderByWithRelationAndSearchRelevanceInputSchema.array(),menusOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: menusWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ MenusScalarFieldEnumSchema,MenusScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const menusFindFirstOrThrowArgsSchema: z.ZodType<Prisma.menusFindFirstOrThrowArgs> = z.object({
  select: menusSelectSchema.optional(),
  include: menusIncludeSchema.optional(),
  where: menusWhereInputSchema.optional(),
  orderBy: z.union([ menusOrderByWithRelationAndSearchRelevanceInputSchema.array(),menusOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: menusWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ MenusScalarFieldEnumSchema,MenusScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const menusFindManyArgsSchema: z.ZodType<Prisma.menusFindManyArgs> = z.object({
  select: menusSelectSchema.optional(),
  include: menusIncludeSchema.optional(),
  where: menusWhereInputSchema.optional(),
  orderBy: z.union([ menusOrderByWithRelationAndSearchRelevanceInputSchema.array(),menusOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: menusWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ MenusScalarFieldEnumSchema,MenusScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const menusAggregateArgsSchema: z.ZodType<Prisma.menusAggregateArgs> = z.object({
  where: menusWhereInputSchema.optional(),
  orderBy: z.union([ menusOrderByWithRelationAndSearchRelevanceInputSchema.array(),menusOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: menusWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const menusGroupByArgsSchema: z.ZodType<Prisma.menusGroupByArgs> = z.object({
  where: menusWhereInputSchema.optional(),
  orderBy: z.union([ menusOrderByWithAggregationInputSchema.array(),menusOrderByWithAggregationInputSchema ]).optional(),
  by: MenusScalarFieldEnumSchema.array(),
  having: menusScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const menusFindUniqueArgsSchema: z.ZodType<Prisma.menusFindUniqueArgs> = z.object({
  select: menusSelectSchema.optional(),
  include: menusIncludeSchema.optional(),
  where: menusWhereUniqueInputSchema,
}).strict()

export const menusFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.menusFindUniqueOrThrowArgs> = z.object({
  select: menusSelectSchema.optional(),
  include: menusIncludeSchema.optional(),
  where: menusWhereUniqueInputSchema,
}).strict()

export const AccountFindFirstArgsSchema: z.ZodType<Prisma.AccountFindFirstArgs> = z.object({
  select: AccountSelectSchema.optional(),
  include: AccountIncludeSchema.optional(),
  where: AccountWhereInputSchema.optional(),
  orderBy: z.union([ AccountOrderByWithRelationAndSearchRelevanceInputSchema.array(),AccountOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: AccountWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ AccountScalarFieldEnumSchema,AccountScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const AccountFindFirstOrThrowArgsSchema: z.ZodType<Prisma.AccountFindFirstOrThrowArgs> = z.object({
  select: AccountSelectSchema.optional(),
  include: AccountIncludeSchema.optional(),
  where: AccountWhereInputSchema.optional(),
  orderBy: z.union([ AccountOrderByWithRelationAndSearchRelevanceInputSchema.array(),AccountOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: AccountWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ AccountScalarFieldEnumSchema,AccountScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const AccountFindManyArgsSchema: z.ZodType<Prisma.AccountFindManyArgs> = z.object({
  select: AccountSelectSchema.optional(),
  include: AccountIncludeSchema.optional(),
  where: AccountWhereInputSchema.optional(),
  orderBy: z.union([ AccountOrderByWithRelationAndSearchRelevanceInputSchema.array(),AccountOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: AccountWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ AccountScalarFieldEnumSchema,AccountScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const AccountAggregateArgsSchema: z.ZodType<Prisma.AccountAggregateArgs> = z.object({
  where: AccountWhereInputSchema.optional(),
  orderBy: z.union([ AccountOrderByWithRelationAndSearchRelevanceInputSchema.array(),AccountOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: AccountWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const AccountGroupByArgsSchema: z.ZodType<Prisma.AccountGroupByArgs> = z.object({
  where: AccountWhereInputSchema.optional(),
  orderBy: z.union([ AccountOrderByWithAggregationInputSchema.array(),AccountOrderByWithAggregationInputSchema ]).optional(),
  by: AccountScalarFieldEnumSchema.array(),
  having: AccountScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const AccountFindUniqueArgsSchema: z.ZodType<Prisma.AccountFindUniqueArgs> = z.object({
  select: AccountSelectSchema.optional(),
  include: AccountIncludeSchema.optional(),
  where: AccountWhereUniqueInputSchema,
}).strict()

export const AccountFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.AccountFindUniqueOrThrowArgs> = z.object({
  select: AccountSelectSchema.optional(),
  include: AccountIncludeSchema.optional(),
  where: AccountWhereUniqueInputSchema,
}).strict()

export const SessionFindFirstArgsSchema: z.ZodType<Prisma.SessionFindFirstArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  where: SessionWhereInputSchema.optional(),
  orderBy: z.union([ SessionOrderByWithRelationAndSearchRelevanceInputSchema.array(),SessionOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: SessionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SessionScalarFieldEnumSchema,SessionScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const SessionFindFirstOrThrowArgsSchema: z.ZodType<Prisma.SessionFindFirstOrThrowArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  where: SessionWhereInputSchema.optional(),
  orderBy: z.union([ SessionOrderByWithRelationAndSearchRelevanceInputSchema.array(),SessionOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: SessionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SessionScalarFieldEnumSchema,SessionScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const SessionFindManyArgsSchema: z.ZodType<Prisma.SessionFindManyArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  where: SessionWhereInputSchema.optional(),
  orderBy: z.union([ SessionOrderByWithRelationAndSearchRelevanceInputSchema.array(),SessionOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: SessionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SessionScalarFieldEnumSchema,SessionScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const SessionAggregateArgsSchema: z.ZodType<Prisma.SessionAggregateArgs> = z.object({
  where: SessionWhereInputSchema.optional(),
  orderBy: z.union([ SessionOrderByWithRelationAndSearchRelevanceInputSchema.array(),SessionOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: SessionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const SessionGroupByArgsSchema: z.ZodType<Prisma.SessionGroupByArgs> = z.object({
  where: SessionWhereInputSchema.optional(),
  orderBy: z.union([ SessionOrderByWithAggregationInputSchema.array(),SessionOrderByWithAggregationInputSchema ]).optional(),
  by: SessionScalarFieldEnumSchema.array(),
  having: SessionScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const SessionFindUniqueArgsSchema: z.ZodType<Prisma.SessionFindUniqueArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  where: SessionWhereUniqueInputSchema,
}).strict()

export const SessionFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.SessionFindUniqueOrThrowArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  where: SessionWhereUniqueInputSchema,
}).strict()

export const UserFindFirstArgsSchema: z.ZodType<Prisma.UserFindFirstArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereInputSchema.optional(),
  orderBy: z.union([ UserOrderByWithRelationAndSearchRelevanceInputSchema.array(),UserOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: UserWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ UserScalarFieldEnumSchema,UserScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const UserFindFirstOrThrowArgsSchema: z.ZodType<Prisma.UserFindFirstOrThrowArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereInputSchema.optional(),
  orderBy: z.union([ UserOrderByWithRelationAndSearchRelevanceInputSchema.array(),UserOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: UserWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ UserScalarFieldEnumSchema,UserScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const UserFindManyArgsSchema: z.ZodType<Prisma.UserFindManyArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereInputSchema.optional(),
  orderBy: z.union([ UserOrderByWithRelationAndSearchRelevanceInputSchema.array(),UserOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: UserWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ UserScalarFieldEnumSchema,UserScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const UserAggregateArgsSchema: z.ZodType<Prisma.UserAggregateArgs> = z.object({
  where: UserWhereInputSchema.optional(),
  orderBy: z.union([ UserOrderByWithRelationAndSearchRelevanceInputSchema.array(),UserOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: UserWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const UserGroupByArgsSchema: z.ZodType<Prisma.UserGroupByArgs> = z.object({
  where: UserWhereInputSchema.optional(),
  orderBy: z.union([ UserOrderByWithAggregationInputSchema.array(),UserOrderByWithAggregationInputSchema ]).optional(),
  by: UserScalarFieldEnumSchema.array(),
  having: UserScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const UserFindUniqueArgsSchema: z.ZodType<Prisma.UserFindUniqueArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereUniqueInputSchema,
}).strict()

export const UserFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.UserFindUniqueOrThrowArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereUniqueInputSchema,
}).strict()

export const VerificationTokenFindFirstArgsSchema: z.ZodType<Prisma.VerificationTokenFindFirstArgs> = z.object({
  select: VerificationTokenSelectSchema.optional(),
  where: VerificationTokenWhereInputSchema.optional(),
  orderBy: z.union([ VerificationTokenOrderByWithRelationAndSearchRelevanceInputSchema.array(),VerificationTokenOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: VerificationTokenWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ VerificationTokenScalarFieldEnumSchema,VerificationTokenScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const VerificationTokenFindFirstOrThrowArgsSchema: z.ZodType<Prisma.VerificationTokenFindFirstOrThrowArgs> = z.object({
  select: VerificationTokenSelectSchema.optional(),
  where: VerificationTokenWhereInputSchema.optional(),
  orderBy: z.union([ VerificationTokenOrderByWithRelationAndSearchRelevanceInputSchema.array(),VerificationTokenOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: VerificationTokenWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ VerificationTokenScalarFieldEnumSchema,VerificationTokenScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const VerificationTokenFindManyArgsSchema: z.ZodType<Prisma.VerificationTokenFindManyArgs> = z.object({
  select: VerificationTokenSelectSchema.optional(),
  where: VerificationTokenWhereInputSchema.optional(),
  orderBy: z.union([ VerificationTokenOrderByWithRelationAndSearchRelevanceInputSchema.array(),VerificationTokenOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: VerificationTokenWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ VerificationTokenScalarFieldEnumSchema,VerificationTokenScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const VerificationTokenAggregateArgsSchema: z.ZodType<Prisma.VerificationTokenAggregateArgs> = z.object({
  where: VerificationTokenWhereInputSchema.optional(),
  orderBy: z.union([ VerificationTokenOrderByWithRelationAndSearchRelevanceInputSchema.array(),VerificationTokenOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: VerificationTokenWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const VerificationTokenGroupByArgsSchema: z.ZodType<Prisma.VerificationTokenGroupByArgs> = z.object({
  where: VerificationTokenWhereInputSchema.optional(),
  orderBy: z.union([ VerificationTokenOrderByWithAggregationInputSchema.array(),VerificationTokenOrderByWithAggregationInputSchema ]).optional(),
  by: VerificationTokenScalarFieldEnumSchema.array(),
  having: VerificationTokenScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const VerificationTokenFindUniqueArgsSchema: z.ZodType<Prisma.VerificationTokenFindUniqueArgs> = z.object({
  select: VerificationTokenSelectSchema.optional(),
  where: VerificationTokenWhereUniqueInputSchema,
}).strict()

export const VerificationTokenFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.VerificationTokenFindUniqueOrThrowArgs> = z.object({
  select: VerificationTokenSelectSchema.optional(),
  where: VerificationTokenWhereUniqueInputSchema,
}).strict()

export const contactFindFirstArgsSchema: z.ZodType<Prisma.contactFindFirstArgs> = z.object({
  select: contactSelectSchema.optional(),
  include: contactIncludeSchema.optional(),
  where: contactWhereInputSchema.optional(),
  orderBy: z.union([ contactOrderByWithRelationAndSearchRelevanceInputSchema.array(),contactOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: contactWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ ContactScalarFieldEnumSchema,ContactScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const contactFindFirstOrThrowArgsSchema: z.ZodType<Prisma.contactFindFirstOrThrowArgs> = z.object({
  select: contactSelectSchema.optional(),
  include: contactIncludeSchema.optional(),
  where: contactWhereInputSchema.optional(),
  orderBy: z.union([ contactOrderByWithRelationAndSearchRelevanceInputSchema.array(),contactOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: contactWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ ContactScalarFieldEnumSchema,ContactScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const contactFindManyArgsSchema: z.ZodType<Prisma.contactFindManyArgs> = z.object({
  select: contactSelectSchema.optional(),
  include: contactIncludeSchema.optional(),
  where: contactWhereInputSchema.optional(),
  orderBy: z.union([ contactOrderByWithRelationAndSearchRelevanceInputSchema.array(),contactOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: contactWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ ContactScalarFieldEnumSchema,ContactScalarFieldEnumSchema.array() ]).optional(),
}).strict()

export const contactAggregateArgsSchema: z.ZodType<Prisma.contactAggregateArgs> = z.object({
  where: contactWhereInputSchema.optional(),
  orderBy: z.union([ contactOrderByWithRelationAndSearchRelevanceInputSchema.array(),contactOrderByWithRelationAndSearchRelevanceInputSchema ]).optional(),
  cursor: contactWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const contactGroupByArgsSchema: z.ZodType<Prisma.contactGroupByArgs> = z.object({
  where: contactWhereInputSchema.optional(),
  orderBy: z.union([ contactOrderByWithAggregationInputSchema.array(),contactOrderByWithAggregationInputSchema ]).optional(),
  by: ContactScalarFieldEnumSchema.array(),
  having: contactScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const contactFindUniqueArgsSchema: z.ZodType<Prisma.contactFindUniqueArgs> = z.object({
  select: contactSelectSchema.optional(),
  include: contactIncludeSchema.optional(),
  where: contactWhereUniqueInputSchema,
}).strict()

export const contactFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.contactFindUniqueOrThrowArgs> = z.object({
  select: contactSelectSchema.optional(),
  include: contactIncludeSchema.optional(),
  where: contactWhereUniqueInputSchema,
}).strict()

export const locationsCreateArgsSchema: z.ZodType<Prisma.locationsCreateArgs> = z.object({
  select: locationsSelectSchema.optional(),
  include: locationsIncludeSchema.optional(),
  data: z.union([ locationsCreateInputSchema,locationsUncheckedCreateInputSchema ]).optional(),
}).strict()

export const locationsUpsertArgsSchema: z.ZodType<Prisma.locationsUpsertArgs> = z.object({
  select: locationsSelectSchema.optional(),
  include: locationsIncludeSchema.optional(),
  where: locationsWhereUniqueInputSchema,
  create: z.union([ locationsCreateInputSchema,locationsUncheckedCreateInputSchema ]),
  update: z.union([ locationsUpdateInputSchema,locationsUncheckedUpdateInputSchema ]),
}).strict()

export const locationsCreateManyArgsSchema: z.ZodType<Prisma.locationsCreateManyArgs> = z.object({
  data: z.union([ locationsCreateManyInputSchema,locationsCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const locationsDeleteArgsSchema: z.ZodType<Prisma.locationsDeleteArgs> = z.object({
  select: locationsSelectSchema.optional(),
  include: locationsIncludeSchema.optional(),
  where: locationsWhereUniqueInputSchema,
}).strict()

export const locationsUpdateArgsSchema: z.ZodType<Prisma.locationsUpdateArgs> = z.object({
  select: locationsSelectSchema.optional(),
  include: locationsIncludeSchema.optional(),
  data: z.union([ locationsUpdateInputSchema,locationsUncheckedUpdateInputSchema ]),
  where: locationsWhereUniqueInputSchema,
}).strict()

export const locationsUpdateManyArgsSchema: z.ZodType<Prisma.locationsUpdateManyArgs> = z.object({
  data: z.union([ locationsUpdateManyMutationInputSchema,locationsUncheckedUpdateManyInputSchema ]),
  where: locationsWhereInputSchema.optional(),
}).strict()

export const locationsDeleteManyArgsSchema: z.ZodType<Prisma.locationsDeleteManyArgs> = z.object({
  where: locationsWhereInputSchema.optional(),
}).strict()

export const drink_categoriesCreateArgsSchema: z.ZodType<Prisma.drink_categoriesCreateArgs> = z.object({
  select: drink_categoriesSelectSchema.optional(),
  include: drink_categoriesIncludeSchema.optional(),
  data: z.union([ drink_categoriesCreateInputSchema,drink_categoriesUncheckedCreateInputSchema ]),
}).strict()

export const drink_categoriesUpsertArgsSchema: z.ZodType<Prisma.drink_categoriesUpsertArgs> = z.object({
  select: drink_categoriesSelectSchema.optional(),
  include: drink_categoriesIncludeSchema.optional(),
  where: drink_categoriesWhereUniqueInputSchema,
  create: z.union([ drink_categoriesCreateInputSchema,drink_categoriesUncheckedCreateInputSchema ]),
  update: z.union([ drink_categoriesUpdateInputSchema,drink_categoriesUncheckedUpdateInputSchema ]),
}).strict()

export const drink_categoriesCreateManyArgsSchema: z.ZodType<Prisma.drink_categoriesCreateManyArgs> = z.object({
  data: z.union([ drink_categoriesCreateManyInputSchema,drink_categoriesCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const drink_categoriesDeleteArgsSchema: z.ZodType<Prisma.drink_categoriesDeleteArgs> = z.object({
  select: drink_categoriesSelectSchema.optional(),
  include: drink_categoriesIncludeSchema.optional(),
  where: drink_categoriesWhereUniqueInputSchema,
}).strict()

export const drink_categoriesUpdateArgsSchema: z.ZodType<Prisma.drink_categoriesUpdateArgs> = z.object({
  select: drink_categoriesSelectSchema.optional(),
  include: drink_categoriesIncludeSchema.optional(),
  data: z.union([ drink_categoriesUpdateInputSchema,drink_categoriesUncheckedUpdateInputSchema ]),
  where: drink_categoriesWhereUniqueInputSchema,
}).strict()

export const drink_categoriesUpdateManyArgsSchema: z.ZodType<Prisma.drink_categoriesUpdateManyArgs> = z.object({
  data: z.union([ drink_categoriesUpdateManyMutationInputSchema,drink_categoriesUncheckedUpdateManyInputSchema ]),
  where: drink_categoriesWhereInputSchema.optional(),
}).strict()

export const drink_categoriesDeleteManyArgsSchema: z.ZodType<Prisma.drink_categoriesDeleteManyArgs> = z.object({
  where: drink_categoriesWhereInputSchema.optional(),
}).strict()

export const drinksCreateArgsSchema: z.ZodType<Prisma.drinksCreateArgs> = z.object({
  select: drinksSelectSchema.optional(),
  include: drinksIncludeSchema.optional(),
  data: z.union([ drinksCreateInputSchema,drinksUncheckedCreateInputSchema ]),
}).strict()

export const drinksUpsertArgsSchema: z.ZodType<Prisma.drinksUpsertArgs> = z.object({
  select: drinksSelectSchema.optional(),
  include: drinksIncludeSchema.optional(),
  where: drinksWhereUniqueInputSchema,
  create: z.union([ drinksCreateInputSchema,drinksUncheckedCreateInputSchema ]),
  update: z.union([ drinksUpdateInputSchema,drinksUncheckedUpdateInputSchema ]),
}).strict()

export const drinksCreateManyArgsSchema: z.ZodType<Prisma.drinksCreateManyArgs> = z.object({
  data: z.union([ drinksCreateManyInputSchema,drinksCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const drinksDeleteArgsSchema: z.ZodType<Prisma.drinksDeleteArgs> = z.object({
  select: drinksSelectSchema.optional(),
  include: drinksIncludeSchema.optional(),
  where: drinksWhereUniqueInputSchema,
}).strict()

export const drinksUpdateArgsSchema: z.ZodType<Prisma.drinksUpdateArgs> = z.object({
  select: drinksSelectSchema.optional(),
  include: drinksIncludeSchema.optional(),
  data: z.union([ drinksUpdateInputSchema,drinksUncheckedUpdateInputSchema ]),
  where: drinksWhereUniqueInputSchema,
}).strict()

export const drinksUpdateManyArgsSchema: z.ZodType<Prisma.drinksUpdateManyArgs> = z.object({
  data: z.union([ drinksUpdateManyMutationInputSchema,drinksUncheckedUpdateManyInputSchema ]),
  where: drinksWhereInputSchema.optional(),
}).strict()

export const drinksDeleteManyArgsSchema: z.ZodType<Prisma.drinksDeleteManyArgs> = z.object({
  where: drinksWhereInputSchema.optional(),
}).strict()

export const drinks_to_drink_categoriesCreateArgsSchema: z.ZodType<Prisma.drinks_to_drink_categoriesCreateArgs> = z.object({
  select: drinks_to_drink_categoriesSelectSchema.optional(),
  include: drinks_to_drink_categoriesIncludeSchema.optional(),
  data: z.union([ drinks_to_drink_categoriesCreateInputSchema,drinks_to_drink_categoriesUncheckedCreateInputSchema ]),
}).strict()

export const drinks_to_drink_categoriesUpsertArgsSchema: z.ZodType<Prisma.drinks_to_drink_categoriesUpsertArgs> = z.object({
  select: drinks_to_drink_categoriesSelectSchema.optional(),
  include: drinks_to_drink_categoriesIncludeSchema.optional(),
  where: drinks_to_drink_categoriesWhereUniqueInputSchema,
  create: z.union([ drinks_to_drink_categoriesCreateInputSchema,drinks_to_drink_categoriesUncheckedCreateInputSchema ]),
  update: z.union([ drinks_to_drink_categoriesUpdateInputSchema,drinks_to_drink_categoriesUncheckedUpdateInputSchema ]),
}).strict()

export const drinks_to_drink_categoriesCreateManyArgsSchema: z.ZodType<Prisma.drinks_to_drink_categoriesCreateManyArgs> = z.object({
  data: z.union([ drinks_to_drink_categoriesCreateManyInputSchema,drinks_to_drink_categoriesCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const drinks_to_drink_categoriesDeleteArgsSchema: z.ZodType<Prisma.drinks_to_drink_categoriesDeleteArgs> = z.object({
  select: drinks_to_drink_categoriesSelectSchema.optional(),
  include: drinks_to_drink_categoriesIncludeSchema.optional(),
  where: drinks_to_drink_categoriesWhereUniqueInputSchema,
}).strict()

export const drinks_to_drink_categoriesUpdateArgsSchema: z.ZodType<Prisma.drinks_to_drink_categoriesUpdateArgs> = z.object({
  select: drinks_to_drink_categoriesSelectSchema.optional(),
  include: drinks_to_drink_categoriesIncludeSchema.optional(),
  data: z.union([ drinks_to_drink_categoriesUpdateInputSchema,drinks_to_drink_categoriesUncheckedUpdateInputSchema ]),
  where: drinks_to_drink_categoriesWhereUniqueInputSchema,
}).strict()

export const drinks_to_drink_categoriesUpdateManyArgsSchema: z.ZodType<Prisma.drinks_to_drink_categoriesUpdateManyArgs> = z.object({
  data: z.union([ drinks_to_drink_categoriesUpdateManyMutationInputSchema,drinks_to_drink_categoriesUncheckedUpdateManyInputSchema ]),
  where: drinks_to_drink_categoriesWhereInputSchema.optional(),
}).strict()

export const drinks_to_drink_categoriesDeleteManyArgsSchema: z.ZodType<Prisma.drinks_to_drink_categoriesDeleteManyArgs> = z.object({
  where: drinks_to_drink_categoriesWhereInputSchema.optional(),
}).strict()

export const pricesCreateArgsSchema: z.ZodType<Prisma.pricesCreateArgs> = z.object({
  select: pricesSelectSchema.optional(),
  include: pricesIncludeSchema.optional(),
  data: z.union([ pricesCreateInputSchema,pricesUncheckedCreateInputSchema ]),
}).strict()

export const pricesUpsertArgsSchema: z.ZodType<Prisma.pricesUpsertArgs> = z.object({
  select: pricesSelectSchema.optional(),
  include: pricesIncludeSchema.optional(),
  where: pricesWhereUniqueInputSchema,
  create: z.union([ pricesCreateInputSchema,pricesUncheckedCreateInputSchema ]),
  update: z.union([ pricesUpdateInputSchema,pricesUncheckedUpdateInputSchema ]),
}).strict()

export const pricesCreateManyArgsSchema: z.ZodType<Prisma.pricesCreateManyArgs> = z.object({
  data: z.union([ pricesCreateManyInputSchema,pricesCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const pricesDeleteArgsSchema: z.ZodType<Prisma.pricesDeleteArgs> = z.object({
  select: pricesSelectSchema.optional(),
  include: pricesIncludeSchema.optional(),
  where: pricesWhereUniqueInputSchema,
}).strict()

export const pricesUpdateArgsSchema: z.ZodType<Prisma.pricesUpdateArgs> = z.object({
  select: pricesSelectSchema.optional(),
  include: pricesIncludeSchema.optional(),
  data: z.union([ pricesUpdateInputSchema,pricesUncheckedUpdateInputSchema ]),
  where: pricesWhereUniqueInputSchema,
}).strict()

export const pricesUpdateManyArgsSchema: z.ZodType<Prisma.pricesUpdateManyArgs> = z.object({
  data: z.union([ pricesUpdateManyMutationInputSchema,pricesUncheckedUpdateManyInputSchema ]),
  where: pricesWhereInputSchema.optional(),
}).strict()

export const pricesDeleteManyArgsSchema: z.ZodType<Prisma.pricesDeleteManyArgs> = z.object({
  where: pricesWhereInputSchema.optional(),
}).strict()

export const menusCreateArgsSchema: z.ZodType<Prisma.menusCreateArgs> = z.object({
  select: menusSelectSchema.optional(),
  include: menusIncludeSchema.optional(),
  data: z.union([ menusCreateInputSchema,menusUncheckedCreateInputSchema ]),
}).strict()

export const menusUpsertArgsSchema: z.ZodType<Prisma.menusUpsertArgs> = z.object({
  select: menusSelectSchema.optional(),
  include: menusIncludeSchema.optional(),
  where: menusWhereUniqueInputSchema,
  create: z.union([ menusCreateInputSchema,menusUncheckedCreateInputSchema ]),
  update: z.union([ menusUpdateInputSchema,menusUncheckedUpdateInputSchema ]),
}).strict()

export const menusCreateManyArgsSchema: z.ZodType<Prisma.menusCreateManyArgs> = z.object({
  data: z.union([ menusCreateManyInputSchema,menusCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const menusDeleteArgsSchema: z.ZodType<Prisma.menusDeleteArgs> = z.object({
  select: menusSelectSchema.optional(),
  include: menusIncludeSchema.optional(),
  where: menusWhereUniqueInputSchema,
}).strict()

export const menusUpdateArgsSchema: z.ZodType<Prisma.menusUpdateArgs> = z.object({
  select: menusSelectSchema.optional(),
  include: menusIncludeSchema.optional(),
  data: z.union([ menusUpdateInputSchema,menusUncheckedUpdateInputSchema ]),
  where: menusWhereUniqueInputSchema,
}).strict()

export const menusUpdateManyArgsSchema: z.ZodType<Prisma.menusUpdateManyArgs> = z.object({
  data: z.union([ menusUpdateManyMutationInputSchema,menusUncheckedUpdateManyInputSchema ]),
  where: menusWhereInputSchema.optional(),
}).strict()

export const menusDeleteManyArgsSchema: z.ZodType<Prisma.menusDeleteManyArgs> = z.object({
  where: menusWhereInputSchema.optional(),
}).strict()

export const AccountCreateArgsSchema: z.ZodType<Prisma.AccountCreateArgs> = z.object({
  select: AccountSelectSchema.optional(),
  include: AccountIncludeSchema.optional(),
  data: z.union([ AccountCreateInputSchema,AccountUncheckedCreateInputSchema ]),
}).strict()

export const AccountUpsertArgsSchema: z.ZodType<Prisma.AccountUpsertArgs> = z.object({
  select: AccountSelectSchema.optional(),
  include: AccountIncludeSchema.optional(),
  where: AccountWhereUniqueInputSchema,
  create: z.union([ AccountCreateInputSchema,AccountUncheckedCreateInputSchema ]),
  update: z.union([ AccountUpdateInputSchema,AccountUncheckedUpdateInputSchema ]),
}).strict()

export const AccountCreateManyArgsSchema: z.ZodType<Prisma.AccountCreateManyArgs> = z.object({
  data: z.union([ AccountCreateManyInputSchema,AccountCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const AccountDeleteArgsSchema: z.ZodType<Prisma.AccountDeleteArgs> = z.object({
  select: AccountSelectSchema.optional(),
  include: AccountIncludeSchema.optional(),
  where: AccountWhereUniqueInputSchema,
}).strict()

export const AccountUpdateArgsSchema: z.ZodType<Prisma.AccountUpdateArgs> = z.object({
  select: AccountSelectSchema.optional(),
  include: AccountIncludeSchema.optional(),
  data: z.union([ AccountUpdateInputSchema,AccountUncheckedUpdateInputSchema ]),
  where: AccountWhereUniqueInputSchema,
}).strict()

export const AccountUpdateManyArgsSchema: z.ZodType<Prisma.AccountUpdateManyArgs> = z.object({
  data: z.union([ AccountUpdateManyMutationInputSchema,AccountUncheckedUpdateManyInputSchema ]),
  where: AccountWhereInputSchema.optional(),
}).strict()

export const AccountDeleteManyArgsSchema: z.ZodType<Prisma.AccountDeleteManyArgs> = z.object({
  where: AccountWhereInputSchema.optional(),
}).strict()

export const SessionCreateArgsSchema: z.ZodType<Prisma.SessionCreateArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  data: z.union([ SessionCreateInputSchema,SessionUncheckedCreateInputSchema ]),
}).strict()

export const SessionUpsertArgsSchema: z.ZodType<Prisma.SessionUpsertArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  where: SessionWhereUniqueInputSchema,
  create: z.union([ SessionCreateInputSchema,SessionUncheckedCreateInputSchema ]),
  update: z.union([ SessionUpdateInputSchema,SessionUncheckedUpdateInputSchema ]),
}).strict()

export const SessionCreateManyArgsSchema: z.ZodType<Prisma.SessionCreateManyArgs> = z.object({
  data: z.union([ SessionCreateManyInputSchema,SessionCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const SessionDeleteArgsSchema: z.ZodType<Prisma.SessionDeleteArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  where: SessionWhereUniqueInputSchema,
}).strict()

export const SessionUpdateArgsSchema: z.ZodType<Prisma.SessionUpdateArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  data: z.union([ SessionUpdateInputSchema,SessionUncheckedUpdateInputSchema ]),
  where: SessionWhereUniqueInputSchema,
}).strict()

export const SessionUpdateManyArgsSchema: z.ZodType<Prisma.SessionUpdateManyArgs> = z.object({
  data: z.union([ SessionUpdateManyMutationInputSchema,SessionUncheckedUpdateManyInputSchema ]),
  where: SessionWhereInputSchema.optional(),
}).strict()

export const SessionDeleteManyArgsSchema: z.ZodType<Prisma.SessionDeleteManyArgs> = z.object({
  where: SessionWhereInputSchema.optional(),
}).strict()

export const UserCreateArgsSchema: z.ZodType<Prisma.UserCreateArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  data: z.union([ UserCreateInputSchema,UserUncheckedCreateInputSchema ]).optional(),
}).strict()

export const UserUpsertArgsSchema: z.ZodType<Prisma.UserUpsertArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereUniqueInputSchema,
  create: z.union([ UserCreateInputSchema,UserUncheckedCreateInputSchema ]),
  update: z.union([ UserUpdateInputSchema,UserUncheckedUpdateInputSchema ]),
}).strict()

export const UserCreateManyArgsSchema: z.ZodType<Prisma.UserCreateManyArgs> = z.object({
  data: z.union([ UserCreateManyInputSchema,UserCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const UserDeleteArgsSchema: z.ZodType<Prisma.UserDeleteArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereUniqueInputSchema,
}).strict()

export const UserUpdateArgsSchema: z.ZodType<Prisma.UserUpdateArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  data: z.union([ UserUpdateInputSchema,UserUncheckedUpdateInputSchema ]),
  where: UserWhereUniqueInputSchema,
}).strict()

export const UserUpdateManyArgsSchema: z.ZodType<Prisma.UserUpdateManyArgs> = z.object({
  data: z.union([ UserUpdateManyMutationInputSchema,UserUncheckedUpdateManyInputSchema ]),
  where: UserWhereInputSchema.optional(),
}).strict()

export const UserDeleteManyArgsSchema: z.ZodType<Prisma.UserDeleteManyArgs> = z.object({
  where: UserWhereInputSchema.optional(),
}).strict()

export const VerificationTokenCreateArgsSchema: z.ZodType<Prisma.VerificationTokenCreateArgs> = z.object({
  select: VerificationTokenSelectSchema.optional(),
  data: z.union([ VerificationTokenCreateInputSchema,VerificationTokenUncheckedCreateInputSchema ]),
}).strict()

export const VerificationTokenUpsertArgsSchema: z.ZodType<Prisma.VerificationTokenUpsertArgs> = z.object({
  select: VerificationTokenSelectSchema.optional(),
  where: VerificationTokenWhereUniqueInputSchema,
  create: z.union([ VerificationTokenCreateInputSchema,VerificationTokenUncheckedCreateInputSchema ]),
  update: z.union([ VerificationTokenUpdateInputSchema,VerificationTokenUncheckedUpdateInputSchema ]),
}).strict()

export const VerificationTokenCreateManyArgsSchema: z.ZodType<Prisma.VerificationTokenCreateManyArgs> = z.object({
  data: z.union([ VerificationTokenCreateManyInputSchema,VerificationTokenCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const VerificationTokenDeleteArgsSchema: z.ZodType<Prisma.VerificationTokenDeleteArgs> = z.object({
  select: VerificationTokenSelectSchema.optional(),
  where: VerificationTokenWhereUniqueInputSchema,
}).strict()

export const VerificationTokenUpdateArgsSchema: z.ZodType<Prisma.VerificationTokenUpdateArgs> = z.object({
  select: VerificationTokenSelectSchema.optional(),
  data: z.union([ VerificationTokenUpdateInputSchema,VerificationTokenUncheckedUpdateInputSchema ]),
  where: VerificationTokenWhereUniqueInputSchema,
}).strict()

export const VerificationTokenUpdateManyArgsSchema: z.ZodType<Prisma.VerificationTokenUpdateManyArgs> = z.object({
  data: z.union([ VerificationTokenUpdateManyMutationInputSchema,VerificationTokenUncheckedUpdateManyInputSchema ]),
  where: VerificationTokenWhereInputSchema.optional(),
}).strict()

export const VerificationTokenDeleteManyArgsSchema: z.ZodType<Prisma.VerificationTokenDeleteManyArgs> = z.object({
  where: VerificationTokenWhereInputSchema.optional(),
}).strict()

export const contactCreateArgsSchema: z.ZodType<Prisma.contactCreateArgs> = z.object({
  select: contactSelectSchema.optional(),
  include: contactIncludeSchema.optional(),
  data: z.union([ contactCreateInputSchema,contactUncheckedCreateInputSchema ]),
}).strict()

export const contactUpsertArgsSchema: z.ZodType<Prisma.contactUpsertArgs> = z.object({
  select: contactSelectSchema.optional(),
  include: contactIncludeSchema.optional(),
  where: contactWhereUniqueInputSchema,
  create: z.union([ contactCreateInputSchema,contactUncheckedCreateInputSchema ]),
  update: z.union([ contactUpdateInputSchema,contactUncheckedUpdateInputSchema ]),
}).strict()

export const contactCreateManyArgsSchema: z.ZodType<Prisma.contactCreateManyArgs> = z.object({
  data: z.union([ contactCreateManyInputSchema,contactCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const contactDeleteArgsSchema: z.ZodType<Prisma.contactDeleteArgs> = z.object({
  select: contactSelectSchema.optional(),
  include: contactIncludeSchema.optional(),
  where: contactWhereUniqueInputSchema,
}).strict()

export const contactUpdateArgsSchema: z.ZodType<Prisma.contactUpdateArgs> = z.object({
  select: contactSelectSchema.optional(),
  include: contactIncludeSchema.optional(),
  data: z.union([ contactUpdateInputSchema,contactUncheckedUpdateInputSchema ]),
  where: contactWhereUniqueInputSchema,
}).strict()

export const contactUpdateManyArgsSchema: z.ZodType<Prisma.contactUpdateManyArgs> = z.object({
  data: z.union([ contactUpdateManyMutationInputSchema,contactUncheckedUpdateManyInputSchema ]),
  where: contactWhereInputSchema.optional(),
}).strict()

export const contactDeleteManyArgsSchema: z.ZodType<Prisma.contactDeleteManyArgs> = z.object({
  where: contactWhereInputSchema.optional(),
}).strict()